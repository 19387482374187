import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// mui
import { Stack, Box, Typography } from '@mui/material'
// components
import {
    PageTitle,
    RingsLoader,
    SearchBox,
    SortBox,
    AddButton,
} from '../../../components'
import { TableGrid } from '../../../Sections'
// grid cols
import { vendorRequestsCols } from '../../../utils/tableGridUtils/vendorRequestsCols'
// alerts
import { errorAlert, successAlert } from '../../../utils/alerts'
// sortin request options
const requestsSortingOptions = [
    {
        sortTitle: 'تاريخ الانشاء من الاحدث الى الاقدم',
        sortKey: '-created_at',
    },
    {
        sortTitle: 'تاريخ الانشاء من الاقدم الى الاحدث',
        sortKey: 'created_at',
    },
    {
        sortTitle: 'تاريخ التحديث من الاحدث الى الاقدم',
        sortKey: '-updated_at',
    },
    {
        sortTitle: 'تاريخ التحديث من الاقدم الى الاحدث',
        sortKey: 'updated_at',
    },
    {
        sortTitle: ' التكلفة من الاعلى الى الاقل',
        sortKey: '-cost',
    },
    {
        sortTitle: ' التكلفة من الاقل الى الاعلى',
        sortKey: 'cost',
    },
    {
        sortTitle: 'طريقة الدفع',
        sortKey: 'payment_method',
    },
    {
        sortTitle: 'اسم النشاط',
        sortKey: 'vendor_id',
    },
]

const VendorRequests = () => {
    // ------------------------------------ states ------------------------------------
    const [requests, setRequests] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [itemsPerPage, setItemsPerPage] = useState(20)
    const [currentPage, setCurrentPage] = useState(1)
    const [searchValue, setSearchValue] = useState('')
    const [sortValue, setSortValue] = useState('-created_at')
    const [totalRows, setTotalRows] = useState(5)
    const [paginationModel, setPaginationModel] = useState({
        pageSize: itemsPerPage,
        page: currentPage - 1,
    })
    const userToken = useSelector((state) => state.userToken)
    // ------------------------------------ get requests ------------------------------------
    useEffect(() => {
        setIsLoading(true)
        enhancedAxios(null, userToken)
            .get(
                `/vendor/subscription-requests?q=${searchValue}&sorts=${sortValue}&page=${currentPage}&paginate=${itemsPerPage}`
            )
            .then((response) => {
                setRequests(response.data.data)
                setTotalRows(response.data.meta.total)
                setIsLoading(false)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [searchValue, sortValue, currentPage, itemsPerPage])
    // render components
    return (
        <Stack width="100%" height="100%" spacing={4} alignItems="center">
            {/* page title  */}
            <PageTitle title="طلبات الاشتراك" />
            <Stack spacing={2} width="100%">
                <Box>
                    <AddButton to="/vendor-add-request" />
                </Box>
                {/* sorting filter sections  */}
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap="20px"
                    flexWrap="wrap"
                    sx={{
                        '& .MuiBox-root': {
                            flex: '1',
                            minWidth: '240px',
                            // maxWidth:'480px',
                        },
                    }}
                >
                    <SearchBox
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                    <SortBox
                        sortValue={sortValue}
                        setSortValue={setSortValue}
                        sortingOptions={requestsSortingOptions}
                    />
                </Box>
            </Stack>
            {/* table data grid  */}
            <>
                {isLoading ? (
                    <RingsLoader />
                ) : requests.length === 0 ? (
                    <Typography variant="h5">لا يوجد طلبات اشتراك</Typography>
                ) : (
                    <TableGrid
                        rows={requests}
                        cols={vendorRequestsCols}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        visibleRowsPerPage={itemsPerPage}
                        totalRows={totalRows}
                    />
                )}
            </>
        </Stack>
    )
}

export default VendorRequests
