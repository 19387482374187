import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// formik
import { useFormik } from 'formik'
// mui
import {
    Stack,
    Box,
    FormControlLabel,
    Checkbox,
    TextField,
} from '@mui/material'
// components
import { PreviewCustomButton } from '../../../Sections'
import { PageTitle, MainButton, ErrorMessage } from '../../../components'
// alerts
import { errorAlert, successAlert } from '../../../utils/alerts'
// schema
import { addBannerSchema } from '../../../schemes/banners/addBannerSchema'
// utils
import { imageCompressor } from '../../../utils/comporessImage/imageCompressor'
const AddBanner = () => {
    const [file, setFile] = useState(null)
    const [resetPreview, setresetPreview] = useState()
    const userToken = useSelector((state) => state.userToken)
    // ------------------------------   handle file changed toget value and for preview --------------------------
    const handleFileChange = async (e) => {
        let x = e.target.files[0]
        const final = await imageCompressor(x)
        setFile(final)
        setValues((prevValues) => ({ ...prevValues, img: final }))
    }
    // -----------------------------------------------  submit the form -----------------------------------------------
    const onSubmit = (values) => {
        const formData = new FormData()
        for (let item in values) {
            formData.append(item, values[item])
        }
        enhancedAxios('multipart/form-data', userToken)
            .post(`/admin/banners`, formData)
            .then((response) => {
                successAlert('تم اضافة الاعلان')
                setresetPreview(true)
            })
            .catch((error) => errorAlert(error))
    }
    // -----------------------------------------------  formik configraion ----------------------------------------------------------
    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
    } = useFormik({
        initialValues: {
            img: null,
            url: '',
            is_disabled: false,
        },
        validationSchema: addBannerSchema,
        onSubmit,
        handleFileChange,
    })
    return (
        <Stack width="100%" height="100%" spacing={4} alignItems="center">
            <PageTitle title="اضافة اعلان" />
            <Stack
                width="100%"
                maxWidth="700px"
                component="form"
                onSubmit={handleSubmit}
                spacing={2}
            >
                <PreviewCustomButton
                    file={file}
                    handleFileChange={handleFileChange}
                    resetPreview={resetPreview}
                    IPVariant="rounded"
                    IPObjectFit="cover"
                    IPWidth="100%"
                />
                <TextField
                    type="text"
                    name="url"
                    value={values.url}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="رابط الاعلان"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={values.is_disabled}
                            onChange={handleChange}
                            name="is_disabled"
                        />
                    }
                    label="اخفاء "
                />
                <Box width="100%" sx={{ '& button': { width: '100%' } }}>
                    <MainButton text="إضافة الاعلان" disabled={isSubmitting} />
                    {/* errors section  */}
                    {errors && (
                        <Stack spacing={1}>
                            {errors.img && touched.img && (
                                <ErrorMessage errorMsg={errors.img} />
                            )}
                            {errors.url && touched.url && (
                                <ErrorMessage errorMsg={errors.url} />
                            )}
                        </Stack>
                    )}
                </Box>
            </Stack>
        </Stack>
    )
}

export default AddBanner
