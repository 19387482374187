// redux
import { useSelector } from 'react-redux'
// formik
import { useFormik } from 'formik'
// schema
import { addPaymentMethodSchema } from '../../../schemes/paymentMethods/addPaymentMethodSchema'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// components
import { PageTitle, MainButton, ErrorMessage } from '../../../components'
// mui
import { Stack, TextField, FormControlLabel, Checkbox } from '@mui/material'
// alerts
import { successAlert, errorAlert } from '../../../utils/alerts'

const AddPaymentMethod = () => {
    const userToken = useSelector((state) => state.userToken)
    const onSubmit = (values) => {
        const formData = new FormData()
        for (let i in values) {
            formData.append(i, values[i])
        }
        enhancedAxios('multipart/form-data', userToken)
            .post(`/admin/payment-methods`, formData)
            .then((response) => {
                successAlert('تم اضافة طريقة الدفع الجديدة')
                setSubmitting(false)
                resetForm()
            })
            .catch((error) => errorAlert(error))
    }
    // formik configration
    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            name: '',
            is_disabled: false,
        },
        validationSchema: addPaymentMethodSchema,
        onSubmit, //function we write above
    })
    return (
        <Stack width="100%" height="100%" spacing={8} alignItems="center">
            <PageTitle title="اضافة طريقة دفع جديدة" />
            <Stack
                component="form"
                spacing={2}
                onSubmit={handleSubmit}
                width="100%"
                maxWidth="600px"
            >
                <TextField
                    name="name"
                    value={values.name}
                    label="الاسم"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                        width: '100%',
                    }}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={values.is_disabled}
                            onChange={handleChange}
                            name="is_disabled"
                        />
                    }
                    label="اخفاء"
                />
                {/* submit button */}
                <MainButton text="إنشاء" disabled={isSubmitting} />
                {errors && (
                    <Stack spacing={1}>
                        {errors.name && touched.name && (
                            <ErrorMessage errorMsg={errors.name} />
                        )}
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}

export default AddPaymentMethod
