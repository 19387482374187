import { HideSwitch, EditDetails, VerifySwitch } from '../../components'

// user table columns
export const usersCols = [
    {
        field: 'name',
        headerName: 'الاسم',
        width: '230',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.name ?? '!! لايوجد اسم !!',
    },
    {
        field: 'phone',
        headerName: 'التليفون',
        width: '220',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.phone ?? '!! لايوجد رقم هاتف !!',
    },
    {
        field: 'city',
        headerName: 'المدينة',
        width: '190',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.city?.name ?? '!! لاتوجد مدينة !!',
    },
    {
        field: 'show',
        headerName: 'مشاهدة',
        width: '130',
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <EditDetails to={`/edit-user/${params.row.id}`} />
        ),
    },
    {
        field: 'is_disabled',
        headerName: 'تعطيل',
        width: '130',
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <HideSwitch
                is_disabled={params.row.is_disabled}
                id={params.row.id}
                who="users"
            />
        ),
    },
    {
        field: 'is_verified',
        headerName: 'التحقق',
        width: '130',
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <VerifySwitch
                is_verified={params.row.is_verified}
                id={params.row.id}
            />
        ),
    },
]
