import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material'

// filters option
const statusFiltersOptions = [
    {
        filterTitle: 'الكل',
        filterKey: 'is_disabled:eq:false|true',
    },
    {
        filterTitle: 'غير معطل',
        filterKey: 'is_disabled:eq:false',
    },
    {
        filterTitle: 'معطل',
        filterKey: 'is_disabled:eq:true',
    },
]
//---------------------------------------- page content ------------------------------------------
const StatusFilterBox = ({ filterValues, setFilterValues }) => {
    const handleChange = (e) => {
        setFilterValues({
            ...filterValues,
            statusFilterValue: e.target.value,
        })
    }
    return (
        <Box>
            <FormControl fullWidth>
                <InputLabel>حالة الحساب</InputLabel>
                <Select
                    name="status_filter"
                    value={filterValues.statusFilterValue}
                    label="حالة الحساب"
                    onChange={handleChange}
                >
                    {statusFiltersOptions.map((option) => {
                        return (
                            <MenuItem
                                value={option.filterKey}
                                key={option.filterKey}
                            >
                                {option.filterTitle}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </Box>
    )
}

export default StatusFilterBox
