import React, { useState, useEffect } from 'react'
import { Box, Avatar } from '@mui/material'

const UploadImagePreview = ({
    file,
    IPWidth = '150px',
    IPHeight = '200px',
    IPVariant = '',
    IPObjectFit = 'cover',
    resetPreview = false,
}) => {
    const [imgSrc, setImgSrc] = useState('')

    const showFileInPreview = (file) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () => {
            setImgSrc(reader.result)
        }
    }
    useEffect(() => {
        if (file) {
            showFileInPreview(file)
        }
    }, [file])
    useEffect(() => {
        if (resetPreview) {
            setImgSrc('')
        }
    }, [resetPreview])
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="10px"
        >
            <Avatar
                src={imgSrc}
                alt="uploaded Image"
                variant={IPVariant}
                sx={{
                    width: `${IPWidth}`,
                    height: `${IPHeight}`,
                    '& .MuiAvatar-img': {
                        objectFit: IPObjectFit,
                    },
                }}
            />
        </Box>
    )
}

export default UploadImagePreview
