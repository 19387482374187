import { useState, useEffect } from 'react'
// react router
import { useSearchParams } from 'react-router-dom'
// react redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// mui
import { Stack, Box, Pagination, Typography } from '@mui/material'
// components
import {
    PageTitle,
    RingsLoader,
    SearchBox,
    SortBox,
    NotificationCard,
} from '../../../components'
// alerts
import { errorAlert } from '../../../utils/alerts'

const ViewAllNotification = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const userToken = useSelector((state) => state.userToken)
    const [notifications, setNotifications] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    let pageFromParam =
        searchParams.get('page') != null ? searchParams.get('page') : 1
    const [currentPage, setCurrentPage] = useState(pageFromParam)
    const [searchValue, setSearchValue] = useState('')
    const [sortValue, setSortValue] = useState('-created_at')
    const [totalItems, setTotalItems] = useState(5)
    const [dataChanged, setDataChanged] = useState(false)
    // ------------------------------------------- pagination page changed ----------------------------------
    const handlePageChanging = (e, newPage) => {
        searchParams.set('page', newPage)
        setSearchParams(searchParams)
        setCurrentPage(newPage)
    }
    // ------------------------------------------- get reports ----------------------------------
    useEffect(() => {
        setIsLoading(true)
        enhancedAxios(null, userToken)
            .get(
                `/admin/fcm-notifications?page=${currentPage}&paginate=${itemsPerPage}&q=${searchValue}&sorts=${sortValue}`
            )
            .then((response) => {
                setNotifications(response.data.data)
                setTotalItems(response.data.meta.total)
                setIsLoading(false)
            })
            .catch((error) => errorAlert(error))
    }, [currentPage, itemsPerPage, searchValue, sortValue, dataChanged])
    // -------------------------------------------  page component ----------------------------------
    return (
        <Stack width="100%" height="100%" spacing={4} alignItems="center">
            <PageTitle title="إدارة الاشعارات المرسلة" />
            <Box
                width="100%"
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
                sx={{ '& .MuiBox-root': { flex: '1', minWidth: '240px' } }}
            >
                <SearchBox
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
                <SortBox sortValue={sortValue} setSortValue={setSortValue} />
            </Box>
            <>
                {isLoading ? (
                    <RingsLoader />
                ) : notifications.length === 0 ? (
                    <Typography variant="body1">
                        لا يوجد اشعارات مرسلة لعرضها
                    </Typography>
                ) : (
                    <>
                        <Stack gap="20px" width="100%">
                            {notifications.map((notification) => (
                                <NotificationCard
                                    key={notification.id}
                                    notificationDetails={notification}
                                    setDataChanged={setDataChanged}
                                />
                            ))}
                        </Stack>
                        <Box
                            width="100%"
                            display="flex"
                            justifyContent="center"
                        >
                            {totalItems > itemsPerPage ? (
                                <Pagination
                                    defaultPage={1}
                                    page={parseInt(currentPage)}
                                    count={Math.ceil(totalItems / itemsPerPage)}
                                    onChange={handlePageChanging}
                                    variant="outlined"
                                    color="primary"
                                />
                            ) : (
                                <></>
                            )}
                        </Box>
                    </>
                )}
            </>
        </Stack>
    )
}
export default ViewAllNotification
