import { useState } from 'react'
import { TextField, Box } from '@mui/material'

const SearchBox = ({ searchValue, setSearchValue }) => {
    const [value, setValue] = useState(searchValue)
    const handleChange = (e) => {
        setValue(e.target.value)
        setSearchValue(e.target.value)
    }
    return (
        <Box>
            <TextField
                label="بحث"
                value={value}
                onChange={handleChange}
                sx={{
                    width: '100%',
                }}
            />
        </Box>
    )
}

export default SearchBox
