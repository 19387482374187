import { Button } from '@mui/material'
// react router
import { Link } from 'react-router-dom'

const EditDetails = ({ to }) => {
    return (
        <Button size="small" variant="outlined" component={Link} to={to}>
            تعديل
        </Button>
    )
}

export default EditDetails
