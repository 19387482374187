import React from 'react'
import { Typography } from '@mui/material'
const SideTitle = ({ title }) => {
    return (
        <Typography variant="h5" fontWeight="500" color="textColor.dark">
            {title}
        </Typography>
    )
}

export default SideTitle
