import { useState } from 'react'
// react redux
import { useSelector } from 'react-redux'
// mui
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
// axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// alerts
import { errorAlert, successAlert } from '../../utils/alerts'

// filters option
const defaultOptions = [
    {
        value: '1',
        label: '1 يوم',
    },
    {
        value: '2',
        label: ' يومين',
    },
    {
        value: '5',
        label: '5 ايام ',
    },
]

const SelectHotDealPeriod = ({
    setValues,
    setValuesKey = 'expires_at',
    title = 'انتهاء العرض بعد',
    options = defaultOptions,
}) => {
    const [value, setValue] = useState(options[0].value)
    const handleChange = (e) => {
        setValue(e.target.value)
        setValues((prevValues) => ({
            ...prevValues,
            [setValuesKey]: e.target.value ?? null,
        }))
    }
    return (
        <FormControl fullWidth>
            <InputLabel>{title}</InputLabel>
            <Select
                name="sort_box"
                value={value}
                label={title}
                onChange={handleChange}
            >
                {options.map((option) => {
                    return (
                        <MenuItem value={option.value} key={option.value}>
                            {option.label}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default SelectHotDealPeriod
