import React, { useState } from 'react'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// mui
import { TextField, Box } from '@mui/material'
// component
import { MainButton } from '../../components'
// alerts
import {
    errorAlert,
    successAlert,
    successToastAlert,
} from '../../utils/alerts/index'

const SocialLinkForm = ({
    id,
    itemKey,
    value,
    title,
    fieldType = 'text',
    helperText,
}) => {
    const [newValue, setNewValue] = useState(value)
    const usertoken = useSelector((state) => state.userToken)
    // change input
    const handleChange = (e) => {
        setNewValue(e.target.value)
    }
    // submit changing
    const handleSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('value', newValue)
        enhancedAxios('multipart/form-data', usertoken)
            .put(`/admin/settings/${id}`, formData)
            .then((res) => {
                successToastAlert('تم تعديل الرابط')
            })
            .catch((error) => errorAlert(error))
    }
    return (
        <Box
            width="100%"
            display="flex"
            justifyContent="flex-start"
            gap="20px"
            flexWrap="wrap"
            component="form"
            onSubmit={handleSubmit}
        >
            <TextField
                type={fieldType}
                name={itemKey}
                value={newValue}
                label={title}
                onChange={handleChange}
                helperText={helperText}
                sx={{ flex: '1', minWidth: '300px' }}
            />
            <Box maxHeight="56px">
                <MainButton text="تعديل الرابط" />
            </Box>
        </Box>
    )
}

export default SocialLinkForm
