import { useState, useEffect } from 'react'
// react redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// mui
import { Stack, Box, Typography } from '@mui/material'
// components
import {
    OrderStatusFilterBox,
    PageTitle,
    RingsLoader,
    SearchBox,
    SortBox,
} from '../../../components'
import { TableGrid } from '../../../Sections'
// cols
import { ordersToDeliveryWithChangeStatus } from '../../../utils/tableGridUtils/ordersToDeliveryWithChangeStatus'
// alerts
import { errorAlert } from '../../../utils/alerts'
const deliveryFilterOptions = [
    {
        sortTitle: 'الكل',
        sortKey: 'status:claimed|delivered',
    },
    {
        sortTitle: 'لم يتم التوصيل',
        sortKey: 'status:claimed',
    },
    {
        sortTitle: 'تم التوصيل',
        sortKey: 'status:delivered',
    },
]
const DelivererOrders = () => {
    const [orders, setOrders] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [itemsPerPage, setItemsPerPage] = useState(16)
    const [currentPage, setCurrentPage] = useState(1)
    const [searchValue, setSearchValue] = useState('')
    const [sortValue, setSortValue] = useState('-created_at')
    const [totalItems, setTotalItems] = useState(5)
    const [filterValues, setFilterValues] = useState({
        orderStatus: 'status:claimed|delivered',
    })
    const userToken = useSelector((state) => state.userToken)
    // get all customer requests
    useEffect(() => {
        setIsLoading(true)
        enhancedAxios(null, userToken)
            .get(
                `/delivery/delivery-orders?q=${searchValue}&sorts=${sortValue}&page=${currentPage}&paginate=${itemsPerPage}&filters=${filterValues.orderStatus}`
            )
            .then((response) => {
                setOrders(response.data.data)
                setTotalItems(response.data.meta.total)
                setIsLoading(false)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [sortValue, currentPage, searchValue, filterValues])
    return (
        <Stack width="100%" height="100%" spacing={4} alignItems="center">
            <PageTitle title="إدارة توصيل الطلبات" />
            <Box
                width="100%"
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
                sx={{
                    '& .MuiBox-root': {
                        flex: '1',
                        minWidth: '240px',
                    },
                }}
            >
                <SearchBox
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
                <SortBox sortValue={sortValue} setSortValue={setSortValue} />
                <OrderStatusFilterBox
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                    filterOptions={deliveryFilterOptions}
                />
            </Box>
            {isLoading ? (
                <RingsLoader />
            ) : orders.length === 0 && searchValue === '' ? (
                <Typography variant="body1">لا يوجد عناصر لعرضها</Typography>
            ) : orders.length === 0 && searchValue !== '' ? (
                <Typography variant="body1">
                    {' '}
                    لا يوجد عناصر لنتيجة البحث{' '}
                </Typography>
            ) : (
                <TableGrid
                    rows={orders}
                    cols={ordersToDeliveryWithChangeStatus}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    visibleRowsPerPage={itemsPerPage}
                    totalRows={totalItems}
                />
            )}
        </Stack>
    )
}

export default DelivererOrders
