import { useState, useEffect, useRef } from 'react'
// redux
import { useSelector } from 'react-redux'
// react router
import { useParams } from 'react-router'
// formik
import { useFormik } from 'formik'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// schema
import { addVendorSchema } from '../../../schemes/manageVendors/addVendorSchema'
// components
import {
    PageTitle,
    SelectCity,
    UploadImagePreview,
    MainButton,
    ErrorMessage,
    RingsLoader,
} from '../../../components'
// mui
import {
    TextField,
    Stack,
    Box,
    Avatar,
    Button,
    FormControlLabel,
    Checkbox,
} from '@mui/material'
// icons
import { FiUploadCloud } from 'react-icons/fi'
// error
import { errorAlert, successAlert } from '../../../utils/alerts'
import { editOrderDelivererSchema } from '../../../schemes/deliverer/editOrderDelivererSchema'
// TODO: CHANGE DATA TO ORDER DELIVERER DATA ROUTES
const EditOrderDeliverer = () => {
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const userToken = useSelector((state) => state.userToken)
    // --------------------------------- submit form ------------------------------------------------------------------
    const onSubmit = (values) => {
        const formData = new FormData()
        if (values.password === '') {
            delete values['password']
            delete values['password_confirmation']
        }
        for (let item in values) {
            formData.append(item, values[item])
        }
        enhancedAxios('multipart/formData', userToken)
            .put(`/admin/users/${id}`, formData)
            .then((response) => {
                successAlert('تم تعديل موصل الطلبات')
                setSubmitting(false)
            })
            .catch((error) => errorAlert(error))
    }
    // --------------------------------- formik configration ----------------------------------------------------------
    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
    } = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            is_disabled: false,
        },
        validationSchema: editOrderDelivererSchema,
        onSubmit, //function we write above
    })
    // --------------------------------- get vendor activity details -------------------------------------------------
    useEffect(() => {
        setIsLoading(false)
        enhancedAxios(null, userToken)
            .get(`/admin/users/${id}`)
            .then((res) => {
                setValues({
                    ...values,
                    name: res.data.name,
                    email: res.data.email,
                    is_disabled: res.data.is_disabled,
                })
                setIsLoading(false)
            })
    }, [id])
    // ---------------------------------- page -----------------------------------------------------------------------
    if (isLoading) {
        return <RingsLoader />
    }
    return (
        <Stack spacing={4} alignItems="center">
            <PageTitle title="تعديل موصل طلبات" />
            <Box
                width="100%"
                maxWidth="800px"
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                gap="20px"
                flexWrap="wrap"
                component="form"
                onSubmit={handleSubmit}
            >
                <Stack spacing={2} flex="1" minWidth="450px">
                    <Stack spacing={2} flex="1" minWidth="450px">
                        <TextField
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="اسم النشاط"
                        />
                    </Stack>
                    {/* admin data  */}
                    <Stack spacing={1} flex="1">
                        <TextField
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="البريد الالكترونى"
                        />
                        <TextField
                            type="password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="الرقم السري"
                        />
                        <TextField
                            type="password"
                            name="password_confirmation"
                            value={values.password_confirmation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="تأكيد الرقم السري "
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.is_disabled}
                                    onChange={handleChange}
                                    name="is_disabled"
                                />
                            }
                            label="اخفاء "
                        />
                        <MainButton text="تأكيد" disabled={isSubmitting} />
                        {/* errors section  */}
                        {errors && (
                            <Stack spacing={1}>
                                {errors.name && touched.name && (
                                    <ErrorMessage errorMsg={errors.name} />
                                )}
                                {errors.city_id && touched.city_id && (
                                    <ErrorMessage errorMsg={errors.city_id} />
                                )}
                                {errors.img && touched.img && (
                                    <ErrorMessage errorMsg={errors.img} />
                                )}
                                {errors.email && touched.email && (
                                    <ErrorMessage errorMsg={errors.email} />
                                )}
                                {errors.password && touched.password && (
                                    <ErrorMessage errorMsg={errors.password} />
                                )}
                                {errors.password_confirmation &&
                                    touched.password_confirmation && (
                                        <ErrorMessage
                                            errorMsg={
                                                errors.password_confirmation
                                            }
                                        />
                                    )}
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Box>
        </Stack>
    )
}

export default EditOrderDeliverer
