import { useState, useEffect } from 'react'
// router
import { useParams } from 'react-router'
// redux
import { useSelector } from 'react-redux'
// axios
import EnhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// mui
import { Box, Stack, Typography } from '@mui/material'
// components
import { SideTitle, RingsLoader } from '../../components'
import VendorSocialLink from './VendorSocialLink'
import AddSocialLinksForm from './AddSocialLinksForm'
// icons
import {
    AiOutlineWhatsApp,
    AiOutlineTwitter,
    AiOutlineInstagram,
    AiOutlineLink,
} from 'react-icons/ai'
import {
    FaTelegramPlane,
    FaFacebookF,
    FaTiktok,
    FaSnapchatGhost,
} from 'react-icons/fa'
// utils
const possibleLinksOptions = [
    {
        title: 'whatsapp',
        icon: <AiOutlineWhatsApp />,
        key: 'app_whatsapp_url',
    },
    {
        title: 'twitter',
        icon: <AiOutlineTwitter />,
        key: 'app_twitter_url',
    },
    {
        title: 'instagram',
        icon: <AiOutlineInstagram />,
        key: 'app_instagram_url',
    },
    {
        title: 'facebook',
        icon: <FaFacebookF />,
        key: 'app_facebook_url',
    },
    {
        title: 'website',
        icon: <AiOutlineLink />,
        key: 'app_website_url',
    },
    {
        title: 'tiktok',
        icon: <FaTiktok />,
        key: 'app_tiktok_url',
    },
    {
        title: 'snapchat',
        icon: <FaSnapchatGhost />,
        key: 'app_snapchat_url',
    },
]
const VendorSocialLinks = ({ ApiUrl }) => {
    const { id } = useParams()
    const userToken = useSelector((state) => state.userToken)
    const userData = useSelector((state) => state.userData)
    const userRole = userData.roles.includes('admin')
        ? 'admin'
        : userData.roles[0]
    const [links, setLinks] = useState([])
    const [loading, setLoading] = useState(true)
    const [dataChanged, setDataChanged] = useState(false)
    useEffect(() => {
        setLoading(true)
        EnhancedAxios(null, userToken)
            .get(ApiUrl)
            .then((response) => {
                setLinks(response.data.contacts)
                setLoading(false)
            })
            .catch((err) => false)
    }, [dataChanged])
    return (
        <Stack spacing={2} width="100%">
            <SideTitle title="روابط التواصل الاجتماعى" />
            <AddSocialLinksForm
                setDataChanged={setDataChanged}
                possibleLinksOptions={possibleLinksOptions}
            />
            <Stack spacing={1}>
                {loading ? (
                    <Box sx={{ height: '100px' }}>
                        <RingsLoader />
                    </Box>
                ) : links?.length === 0 || links === null ? (
                    <Typography variant="body1">
                        لا يوجد روابط لعرضها{' '}
                    </Typography>
                ) : (
                    links.map((link) => (
                        <VendorSocialLink
                            possibleLinksOptions={possibleLinksOptions}
                            key={link.id}
                            linkId={link.id}
                            linkKey={link.key}
                            linkValue={link.value}
                            setDataChanged={setDataChanged}
                        />
                    ))
                )}
            </Stack>
        </Stack>
    )
}

export default VendorSocialLinks
