import React, { useState } from 'react'
// r redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// mui
import { Stack, Typography, Box, Button, Switch } from '@mui/material'
// components
import { LinkButton } from '../../components'
// alerts
import {
    askForConfirmationAlert,
    successToastAlert,
    errorAlert,
} from '../../utils/alerts'
// icons
import { IoMdTime } from 'react-icons/io'
// time
import moment, { Moment } from 'moment'

const DealCard = ({ deal, setDataChanged, role = 'admin' }) => {
    const [checked, setChecked] = useState(!deal.is_disabled)
    const userToken = useSelector((state) => state.userToken)
    // change is_disbaled value
    const handleChange = (e) => {
        const formData = new FormData()
        setChecked(() => !e.target.checked)
        formData.append('is_disabled', !e.target.checked)
        enhancedAxios('multipart/form-data', userToken)
            .put(`/${role}/hot-offers/${deal.id}`, formData)
            .then((response) => {
                const alertMsg =
                    response.data.is_disabled === true
                        ? 'تم التعطيل'
                        : 'تم التفعيل'
                successToastAlert(alertMsg)
                setChecked(!response.data.is_disabled)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }
    // handle Delete
    const deleteProcess = () => {
        enhancedAxios(null, userToken)
            .delete(`/${role}/hot-offers/${deal.id}`)
            .then((response) => {
                successToastAlert('تم حذف العرض')
                setDataChanged((prev) => !prev)
            })
            .catch((error) => errorAlert(error))
    }
    const handleDelete = () => {
        askForConfirmationAlert(
            'هل انت متاكد انك تريد حذف العرض',
            deleteProcess
        )
    }
    return (
        <Stack boxShadow={1} p={1} borderRadius={1} width="290px">
            <img
                src={deal.img}
                alt={deal.title}
                width="290px"
                height="160px"
                loading="lazy"
                style={{
                    objectFit: 'cover',
                    maxWidth: '100%',
                    borderRadius: '4px',
                }}
            />
            <Typography variant="body1">
                {deal.title.length > 25
                    ? `${deal.title.slice(0, 25)}...`
                    : deal.title}
            </Typography>
            <Stack
                direction="row"
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Typography variant="body2">
                    <span>{deal.final_price} د.ل </span>
                    بدلا من
                    <span
                        style={{
                            textDecoration: 'line-through',
                            paddingRight: 6,
                        }}
                    >
                        {deal.price} د.ل
                    </span>
                </Typography>
                {deal.price && deal.final_price ? (
                    <Typography color="error">
                        {(
                            ((deal.price - deal.final_price) / deal.price) *
                            100
                        ).toFixed(2)}
                        %
                    </Typography>
                ) : (
                    <Typography color="error">عرض عام</Typography>
                )}
            </Stack>
            <Typography display={'flex'} alignItems={'center'}>
                <IoMdTime />
                {/* {moment(deal.expires_at).format('YYYY/MM/DD HH:mm')} */}
                {moment(deal.expires_at).format('YYYY/MM/DD')}
            </Typography>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
            >
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    flexWrap="wrap"
                    gap="10px"
                >
                    <LinkButton
                        to={`/${
                            role === 'vendor' ? 'vendor-' : ''
                        }edit-hot-deal/${deal.id}`}
                        text="تعديل "
                        size="small"
                    />
                    <Button color="error" onClick={handleDelete}>
                        حذف
                    </Button>
                </Box>
                <Switch
                    size="small"
                    checked={checked}
                    onChange={handleChange}
                />
            </Box>
        </Stack>
    )
}

export default DealCard
