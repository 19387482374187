import { useState, useEffect, useRef } from 'react'
// redux
import { useSelector } from 'react-redux'
// formik
import { useFormik } from 'formik'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// components
import { PageTitle, MainButton, ErrorMessage } from '../../../components'
// mui
import {
    TextField,
    Stack,
    Box,
    FormControlLabel,
    Checkbox,
} from '@mui/material'
// error
import { errorAlert, successAlert } from '../../../utils/alerts'
import { addOrderDelivererSchema } from '../../../schemes/deliverer/addOrderDelivererSchema'
// TODO: CHANGE POST REQUEST AND SCHEMA TO NEW ORDER DELIVERER
const AddOrderDeliverer = () => {
    const userToken = useSelector((state) => state.userToken)
    // --------------------------------- submit form  ----------------------------------------------------------
    const onSubmit = (values) => {
        const formData = new FormData()
        for (let item in values) {
            formData.append(item, values[item])
        }
        enhancedAxios('multipart/form-data', userToken)
            .post(`/admin/users`, formData)
            .then((response) => {
                successAlert('تم اضافة شركة توصيل جديد')
                const grandRoleFormData = new FormData()
                grandRoleFormData.append('user_id', response?.data?.id)
                grandRoleFormData.append('role_id', 6)
                enhancedAxios('multipart/form-data', userToken)
                    .post(`/admin/users/grant-role`, grandRoleFormData)
                    .then(() => {
                        resetForm()
                        setSubmitting(false)
                    })
                    .catch((error) => {
                        errorAlert(error)
                        setSubmitting(false)
                    })
            })
            .catch((error) => {
                errorAlert(error)
                setSubmitting(false)
            })
    }
    // --------------------------------- formik configration ----------------------------------------------------------
    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            is_disabled: false,
        },
        validationSchema: addOrderDelivererSchema,
        onSubmit, //function we write above
    })
    return (
        <Stack spacing={4} alignItems="center">
            <PageTitle title="اضافة موصل طلبات" />
            <Box
                width="100%"
                maxWidth="800px"
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                gap="20px"
                flexWrap="wrap"
                component="form"
                onSubmit={handleSubmit}
            >
                <Stack spacing={2} flex="1" minWidth="450px">
                    <TextField
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="الاسم"
                    />

                    <TextField
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="البريد الالكترونى"
                    />
                    <TextField
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="الرقم السري"
                    />
                    <TextField
                        type="password"
                        name="password_confirmation"
                        value={values.password_confirmation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="تأكيد الرقم السري "
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values.is_disabled}
                                onChange={handleChange}
                                name="is_disabled"
                            />
                        }
                        label="اخفاء "
                    />
                    <MainButton text="تأكيد" disabled={isSubmitting} />
                    {/* errors section  */}
                    {errors && (
                        <Stack spacing={1}>
                            {errors.name && touched.name && (
                                <ErrorMessage errorMsg={errors.name} />
                            )}
                            {errors.city_id && touched.city_id && (
                                <ErrorMessage errorMsg={errors.city_id} />
                            )}
                            {errors.img && touched.img && (
                                <ErrorMessage errorMsg={errors.img} />
                            )}
                            {errors.email && touched.email && (
                                <ErrorMessage errorMsg={errors.email} />
                            )}
                            {errors.password && touched.password && (
                                <ErrorMessage errorMsg={errors.password} />
                            )}
                            {errors.password_confirmation &&
                                touched.password_confirmation && (
                                    <ErrorMessage
                                        errorMsg={errors.password_confirmation}
                                    />
                                )}
                        </Stack>
                    )}
                </Stack>
            </Box>
        </Stack>
    )
}

export default AddOrderDeliverer
