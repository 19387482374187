import { useState, useEffect } from 'react'
// react redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// mui
import { Stack, Box, Pagination, Typography } from '@mui/material'
// components
import {
    PageTitle,
    RingsLoader,
    SearchBox,
    SortBox,
    OtpMessageCard,
} from '../../../components'
// alerts
import { errorAlert } from '../../../utils/alerts'

const OtpHistoryMessages = () => {
    const [messages, setMessages] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [itemsPerPage, setItemsPerPage] = useState(16)
    const [currentPage, setCurrentPage] = useState(1)
    const [searchValue, setSearchValue] = useState('')
    const [sortValue, setSortValue] = useState('-created_at')
    const [totalItems, setTotalItems] = useState(5)
    const userToken = useSelector((state) => state.userToken)
    // ------------------------------------------- pagination page changed ----------------------------------
    const handlePageChanging = (e, newPage) => {
        setCurrentPage(newPage)
    }
    // -------------------------------------------  get messages ----------------------------------
    useEffect(() => {
        setIsLoading(true)
        enhancedAxios(null, userToken)
            .get(
                `/admin/otp/history?page=${currentPage}&paginate=${itemsPerPage}&q=${searchValue}&sorts=${sortValue}&filters=env:eq:production`
            )
            .then((response) => {
                setMessages(response.data.data)
                setTotalItems(response.data.meta.total)
                setIsLoading(false)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [currentPage, itemsPerPage, sortValue, searchValue])
    return (
        <Stack width="100%" height="100%" spacing={8} alignItems="center">
            <PageTitle title="سجل الرسائل المرسلة" />
            <Stack spacing={4} width="100%">
                {/* --------------------------------- filter section ------------------------------------------------------- */}
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap="20px"
                    flexWrap="wrap"
                    sx={{
                        '& .MuiBox-root': {
                            flex: '1',
                            minWidth: '240px',
                        },
                    }}
                >
                    <SearchBox
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                    <SortBox
                        sortValue={sortValue}
                        setSortValue={setSortValue}
                    />
                </Box>
                {/* --------------------------------- cards of categoris -----------------------  */}
                {isLoading ? (
                    <RingsLoader />
                ) : messages.length === 0 && searchValue === '' ? (
                    <Typography variant="body1" textAlign="center">
                        لا يوجد عناصر لعرضها
                    </Typography>
                ) : messages.length === 0 && searchValue !== '' ? (
                    <Typography variant="body1" textAlign="center">
                        {' '}
                        لا يوجد عناصر لنتيجة البحث{' '}
                    </Typography>
                ) : (
                    <>
                        <Box
                            display="flex"
                            gap="20px"
                            justifyContent="center"
                            alignItems="flex-start"
                            flexWrap="wrap"
                            flexDirection="column"
                        >
                            {messages.map((msg) => {
                                return <OtpMessageCard msg={msg} />
                            })}
                        </Box>
                        <Box
                            width="100%"
                            display="flex"
                            justifyContent="center"
                        >
                            {totalItems > itemsPerPage ? (
                                <Pagination
                                    defaultPage={1}
                                    page={currentPage}
                                    count={Math.ceil(totalItems / itemsPerPage)}
                                    onChange={handlePageChanging}
                                    variant="outlined"
                                    color="primary"
                                />
                            ) : (
                                ''
                            )}
                        </Box>
                    </>
                )}
            </Stack>
        </Stack>
    )
}

export default OtpHistoryMessages
