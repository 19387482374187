import { useState, useEffect } from 'react'
// react router
import { useParams } from 'react-router'
// redux
import { useSelector } from 'react-redux'
// formik
import { useFormik } from 'formik'
// schemas
import { editPaymentMethodSchema } from '../../../schemes/paymentMethods/editPaymentMethodSchema'
// componetns
import {
    PageTitle,
    MainButton,
    ErrorMessage,
    RingsLoader,
} from '../../../components'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// mui
import { Stack, TextField, FormControlLabel, Checkbox } from '@mui/material'
// alerts
import { errorAlert, successAlert } from '../../../utils/alerts'

const EditPaymentMethod = () => {
    const [isLoading, setIsLoading] = useState(true)
    const userToken = useSelector((state) => state.userToken)
    const { id } = useParams()
    //  ------------------------------  form methods and configraton ------------------------------
    // submit
    const onSubmit = (values) => {
        const formData = new FormData()
        for (let i in values) {
            formData.append(i, values[i])
        }
        enhancedAxios('multipart/form-data', userToken)
            .put(`/admin/payment-methods/${id}`, formData)
            .then((response) => {
                successAlert('تم تعديل المدينة')
                setSubmitting(false)
            })
            .catch((error) => errorAlert(error))
    }
    // formik configration
    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            name: '',
            is_disabled: false,
        },
        validationSchema: editPaymentMethodSchema,
        onSubmit, //function we write above
    })
    // ------------------------------ get current method data ------------------------------
    useEffect(() => {
        setIsLoading(true)
        enhancedAxios(null, userToken)
            .get(`/admin/payment-methods/${id}`)
            .then((response) => {
                setValues({
                    ...values,
                    name: response.data.name,
                    is_disabled: response.data.is_disabled,
                })
                setIsLoading(false)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [id])
    // return loader until data returned
    if (isLoading) {
        return <RingsLoader />
    }
    return (
        <Stack width="100%" height="100%" spacing={8} alignItems="center">
            <PageTitle title="تعديل  المدينة" />
            {/* ---------------------------------- form --------------------------------  */}
            <Stack
                component="form"
                spacing={2}
                onSubmit={handleSubmit}
                width="100%"
                maxWidth="600px"
            >
                <TextField
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="الاسم"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={values.is_disabled}
                            onChange={handleChange}
                            name="is_disabled"
                        />
                    }
                    label="اخفاء"
                />
                {/* submit button */}
                <MainButton text="تعديل" disabled={isSubmitting} />
                {errors && (
                    <Stack spacing={1}>
                        {errors.name && touched.name && (
                            <ErrorMessage errorMsg={errors.name} />
                        )}
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}

export default EditPaymentMethod
