import { useState } from 'react'
// react redux
import { useSelector } from 'react-redux'
// mui
import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material'
// axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// alerts
import { errorAlert, successAlert } from '../../utils/alerts'

// filters option
const options = [
    {
        filterTitle: 'لم يتم التوصيل',
        filterKey: 'claimed',
    },
    {
        filterTitle: 'تم التوصيل',
        filterKey: 'delivered',
    },
]

const ChangeOrderDeliveryStatus = ({ id, defaultValue }) => {
    const userToken = useSelector((state) => state.userToken) // user token from redux store
    const [value, setValue] = useState(defaultValue)
    const handleChange = (e) => {
        setValue(e.target.value)
        enhancedAxios(null, userToken)
            .put(`/delivery/delivery-orders/${id}`, { status: e.target.value })
            .then(() => {
                successAlert('تم تعديل حالة الطلب')
            })
            .catch((error) => {
                errorAlert(error)
            })
    }
    return (
        <Box>
            <FormControl fullWidth>
                <Select
                    name="sort_box"
                    value={value}
                    label="ترتيب حسب"
                    onChange={handleChange}
                    sx={{
                        '& .MuiOutlinedInput-notchedOutline ': {
                            border: 'none',
                        },
                    }}
                >
                    {options.map((option) => {
                        return (
                            <MenuItem
                                value={option.filterKey}
                                key={option.filterKey}
                            >
                                {option.filterTitle}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </Box>
    )
}

export default ChangeOrderDeliveryStatus
