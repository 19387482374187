import * as yup from 'yup'
const maxImgSizeMB = 20
const maxImgSizeBytes = maxImgSizeMB * 2 ** 20
// validation
export const addCategorySchema = yup.object().shape({
    img: yup
        .mixed()
        .nullable()
        .test(
            'fileSize',
            `حجم الصورة كبير جدا يجب الا يتعدى حجم الصورة ${maxImgSizeMB}MB`,
            (value) => (value != null ? value.size <= maxImgSizeBytes : 't')
        )
        .test('fileType', 'امتداد الصورة يجب ان يكون jpg , png', (value) =>
            value != null
                ? ['image/jpg', 'image/png', 'image/jpeg'].includes(value.type)
                : 't'
        ),
    name: yup.string().required('من فضلك ادخل اسم الفئة التى تريد اضافتها'),
    sort: yup.number().typeError('الترتيب يجب ان يكون رقم'),
    is_disabled: yup.boolean(),
})
