import { Box } from '@mui/material'
// loader
import { Rings } from 'react-loader-spinner'
const RingsLoader = () => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '80vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                padding: '50px 0',
                boxSizing: 'border-box',
            }}
        >
            <Rings
                height="80"
                width="80"
                color="#f29312"
                radius="6"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="rings-loading"
            />
        </Box>
    )
}

export default RingsLoader
