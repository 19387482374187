import { HideSwitch, EditDetails } from '../../components'
// user table columns
export const orderDelivererCols = [
    {
        field: 'name',
        headerName: 'الاسم',
        width: '200',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.name ?? '!! غير معروف !!',
    },

    {
        field: 'email',
        headerName: 'البريد الالكترونى',
        width: '200',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.email ?? '!! غير معروف !!',
    },
    {
        field: 'edit',
        headerName: 'تعديل',
        width: '160',
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <EditDetails to={`/edit-order-deliverer/${params.row.id}`} />
        ),
    },
    {
        field: 'is_disabled',
        headerName: 'تعطيل',
        width: '160',
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <HideSwitch
                is_disabled={params.row.is_disabled}
                id={params.row.id}
                who="users"
            />
        ),
    },
]
