import * as yup from 'yup'
const maxImgSizeMB = 20
const maxImgSizeBytes = maxImgSizeMB * 2 ** 20

// edit vendor validation
export const editVendorSchema = yup.object().shape({
    name: yup.string().required(' ادخل الاسم'),
    owner: yup.string().required(' ادخل اسم المالك'),
    description: yup.string().required('ادخل وصل للنشاط'),
    address_info: yup.string().required('ادخل العنوان'),
    img: yup
        .mixed()
        .nullable()
        .test(
            'fileSize',
            `حجم الصورة كبير جدا يجب الا يتعدى حجد الصورة ${maxImgSizeMB}MB`,
            (value) => imageEditSizeValidate(value)
        )
        .test('fileType', 'امتداد الصورة يجب ان يكون jpg , png', (value) =>
            imageEditTypeValidate(value)
        ),
    email: yup
        .string()
        .email('ادخل ايميل الصحيح')
        .required('يجب ان يكون هناك ادمن للنشاط'),
    password: yup.string().min(8, ' الرقم السري قصير جدا'),
    password_confirmation: yup
        .string()
        .oneOf([yup.ref('password')], 'تأكد من تطابق الرقم السري'),
})

// function to validate image if it get as url or not uploaded
const imageEditSizeValidate = (value) => {
    if (value != null && typeof value != 'string') {
        return value.size <= maxImgSizeBytes
    }
    return true
}

const imageEditTypeValidate = (value) => {
    if (value != null && typeof value != 'string') {
        return ['image/jpg', 'image/png', 'image/jpeg'].includes(value.type)
    }
    return true
}
