import { useState } from 'react'
// mui components
import { Box, Switch } from '@mui/material'
//axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// redux hooks
import { useSelector } from 'react-redux'
// alerts
import { successAlert, errorAlert, successToastAlert } from '../../utils/alerts'
const HideSwitch = ({ id, is_disabled, who }) => {
    const [value, setValue] = useState(!is_disabled)
    const userToken = useSelector((state) => state.userToken)
    const userData = useSelector((state) => state.userData)
    const handleChange = (e) => {
        setValue((prev) => !prev)
        let formData = new FormData()
        formData.append('is_disabled', !e.target.checked)
        enhancedAxios('multipart/form-data', userToken)
            .put(`/admin/${who}/${id}`, formData)
            .then((response) => {
                const alertMsg =
                    response.data.is_disabled === true
                        ? 'تم التعطيل'
                        : 'تم التفعيل'
                successToastAlert(alertMsg)
                setValue(!response.data.is_disabled)
            })
            .catch((error) => {
                errorAlert(error)
                setValue((prev) => !prev)
            })
    }
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
            }}
        >
            <Switch
                checked={value}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={userData.id === id}
                size="small"
            />
        </Box>
    )
}

export default HideSwitch
