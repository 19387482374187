import { useState } from 'react'
// formik to handle form
import { useFormik } from 'formik'
// react router
import { useNavigate } from 'react-router'
// redux
import { useDispatch, useSelector } from 'react-redux'
// components
import { ErrorMessage } from '../../components'
// mui
import {
    Box,
    Stack,
    TextField,
    Button,
    InputAdornment,
    IconButton,
    FormControl,
    OutlinedInput,
    InputLabel,
} from '@mui/material'
// redux actions
import {
    setUserToken,
    setCurrentUserData,
} from '../../redux/actions/userActions'
// icons
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'
//axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
//validation schema
import { loginSchema } from '../../schemes/auth/loginSchema'
// alerts
import { errorAlert } from '../../utils/alerts/index'
const LoginForm = () => {
    const [showPassword, setShowPassword] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userToken = useSelector((state) => state.userToken)
    const userData = useSelector((state) => state.userData)
    // handle toggle show password fn
    const handleClickShowPassword = () => setShowPassword((show) => !show)
    // submit form
    const onSubmit = (values) => {
        const formData = new FormData()
        for (let i in values) {
            formData.append(i, values[i])
        }
        enhancedAxios('multipart/form-data', null)
            .post('/login', formData)
            .then((response) => {
                dispatch(setUserToken(response.data.token.value))
                enhancedAxios(null, response.data.token.value)
                    .get('/me')
                    .then((res) => {
                        dispatch(setCurrentUserData(res.data))
                        if (res.data.roles.includes('admin')) {
                            navigate('/')
                        } else if (res.data.roles.includes('vendor')) {
                            navigate('/vendor')
                        } else if (res.data.roles.includes('delivery')) {
                            navigate('/deliverer/my-orders')
                        } else {
                            navigate('/login')
                        }
                    })
                    .catch((error) => errorAlert(error))
            })
            .catch((error) => {
                errorAlert(error)
            })
            .finally(() => {
                setSubmitting(false)
            })
    }
    // formik configration
    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
    } = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: loginSchema,
        onSubmit, //function we write above
    })

    return (
        <Stack
            component="form"
            spacing={2}
            width="100%"
            onSubmit={handleSubmit}
        >
            {/* email  */}
            <TextField
                label="البريد الالكترونى"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            {/* password  */}
            <FormControl>
                <InputLabel htmlFor="outlined-adornment-password">
                    الرقم السري
                </InputLabel>
                <OutlinedInput
                    id="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="password"
                    variant="outlined"
                    label="الرقم السري"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                            >
                                {showPassword ? (
                                    <MdVisibilityOff />
                                ) : (
                                    <MdVisibility />
                                )}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            {/* submit button */}
            <Button
                type="submit"
                variant="contained"
                size="large"
                disabled={isSubmitting}
                sx={{ color: 'textColor.main' }}
            >
                تسجيل الدخول
            </Button>
            {errors && (
                <Stack spacing={1}>
                    {errors.email && touched.email && (
                        <ErrorMessage errorMsg={errors.email} />
                    )}
                    {errors.password && touched.password && (
                        <ErrorMessage errorMsg={errors.password} />
                    )}
                </Stack>
            )}
        </Stack>
    )
}

export default LoginForm
