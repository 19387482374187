import imageCompression from 'browser-image-compression'
const maxImageSize = 2 * 1024 * 1024

const options = {
    maxSizeMB: 2,
    useWebWorker: true,
}
export const imageCompressor = async (img) => {
    if (img.size > maxImageSize) {
        const z = await imageCompression(img, options)
            .then((result) => {
                const final = new File([result], result.name, {
                    type: result.type,
                })
                return final
            })
            .catch((error) => {
                return img
            })
        return z
    }
    return img
}
