import React, { useState, useEffect, useRef } from 'react'
// redux
import { useSelector } from 'react-redux'
// router
import { useParams } from 'react-router'
// formik
import { useFormik } from 'formik'
// schemas
import { editCategorySchema } from '../../../schemes/categories/editCategorySchema'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// components
import {
    PageTitle,
    UploadImagePreview,
    RingsLoader,
    MainButton,
    ErrorMessage,
} from '../../../components'
// mui
import {
    Stack,
    Avatar,
    Box,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
} from '@mui/material'
// icons
import { FiUploadCloud } from 'react-icons/fi'
import { errorAlert, successAlert } from '../../../utils/alerts'
// utils
import { imageCompressor } from '../../../utils/comporessImage/imageCompressor'

const EditCategoryActivity = () => {
    const [file, setFile] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const inputFileRef = useRef()
    const { id } = useParams()
    const userToken = useSelector((state) => state.userToken)

    // ------------------------------   handle file changed toget value and for preview --------------------------
    const handleFileChange = async (e) => {
        let x = e.target.files[0]
        const final = await imageCompressor(x)
        setFile(final)
        setValues((prevValues) => ({ ...prevValues, img: final }))
    }

    //---------------------------------------- submit form ------------------------------------------------
    const onSubmit = (values) => {
        const formData = new FormData()
        for (let i in values) {
            formData.append(i, values[i])
        }
        enhancedAxios('multipart/form-data', userToken)
            .put(`/admin/categories/${id}`, formData)
            .then((response) => {
                successAlert('تم تعديل الفئة')
                setSubmitting(false)
            })
            .catch((error) => {
                errorAlert(error)
                setSubmitting(false)
            })
    }
    // ------------------------------------ Form Validation Configraiton ----------------------------------------

    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        errors,
        isSubmitting,
        setSubmitting,
    } = useFormik({
        initialValues: {
            img: null,
            name: '',
            is_disabled: false,
            sort: '',
        },
        validationSchema: editCategorySchema,
        onSubmit, //function we write above
        handleFileChange,
    })

    // ------------------------------------------- get current category data -------------------------
    useEffect(() => {
        enhancedAxios(null, userToken)
            .get(`/admin/categories/${id}`)
            .then((response) => {
                setValues({
                    ...values,
                    img: response.data.img,
                    name: response.data.name,
                    is_disabled: response.data.is_disabled,
                    sort: response.data.sort,
                })
                setIsLoading(false)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [id])

    // if data still loading render loaders
    if (isLoading) {
        return <RingsLoader />
    }
    // ------------------------------------ page -------------------------------------------------------------------
    return (
        <Stack width="100%" height="100%" spacing={8} alignItems="center">
            <PageTitle title="تعديل فئة للمنشورات" />
            {/* ---------------------------------- form --------------------------------  */}
            <Stack
                component="form"
                spacing={2}
                onSubmit={handleSubmit}
                width="100%"
                maxWidth="600px"
            >
                <TextField
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="اسم الفئة"
                />
                <TextField
                    name="sort"
                    value={values.sort}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="الترتيب"
                />
                {/* preview and upload image  */}
                <Stack spacing={1} flex="1">
                    {/* img preview section  */}
                    {typeof values.img != 'string' && (
                        <UploadImagePreview
                            file={file}
                            IPWidth="100%"
                            IPHeight="200px"
                            IPVariant="rounded"
                            IPObjectFit="contain"
                        />
                    )}
                    {typeof values.img == 'string' && (
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            gap="10px"
                        >
                            <Avatar
                                src={values.img}
                                alt="uploaded Image"
                                variant="rounded"
                                sx={{
                                    width: '100%',
                                    height: '200px',
                                    '& .MuiAvatar-img': {
                                        objectFit: 'contain',
                                    },
                                }}
                            />
                        </Box>
                    )}
                    <input
                        type="file"
                        onChange={handleFileChange}
                        name="img"
                        ref={inputFileRef}
                        hidden
                        accept="image/png, image/gif, image/jpeg"
                    ></input>
                    <Box display="flex" justifyContent="center" width="100%">
                        <Button
                            variant="contained"
                            color="success"
                            endIcon={<FiUploadCloud />}
                            onClick={() => inputFileRef.current.click()}
                        >
                            تحميل صورة
                        </Button>
                    </Box>
                </Stack>
                {/* is disabled  */}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={values.is_disabled}
                            onChange={handleChange}
                            name="is_disabled"
                        />
                    }
                    label="اخفاء المقال"
                />
                {/* submit form  */}
                <MainButton text="تعديل الفئة" disabled={isSubmitting} />
                {/* errors section  */}
                {errors && (
                    <Stack spacing={1}>
                        {errors.name && touched.name && (
                            <ErrorMessage errorMsg={errors.name} />
                        )}
                        {errors.img && touched.img && (
                            <ErrorMessage errorMsg={errors.img} />
                        )}
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}

export default EditCategoryActivity
