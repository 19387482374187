import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// mui
import { Stack, Box, Typography, Divider, Switch, Button } from '@mui/material'
// components
import { GoEditButton, DeleteButton } from '../../components'
import { errorAlert, successAlert, successToastAlert } from '../../utils/alerts'

const LabelCard = ({
    label,
    subject,
    id,
    mainPart,
    setCategoriesChanged,
    apiPath,
    editPath,
}) => {
    const [checked, setChecked] = useState(false)
    const userToken = useSelector((state) => state.userToken)
    // get value of disabled from the mainPart = [categoris , cities]
    useEffect(() => {
        enhancedAxios(null, userToken)
            .get(apiPath)
            .then((response) => {
                setChecked(!response.data.is_disabled)
            })
    }, [])
    // change is_disbaled value
    const handleChange = (e) => {
        const formData = new FormData()
        formData.append('is_disabled', !e.target.checked)

        enhancedAxios('multipart/form-data', userToken)
            .put(apiPath, formData)
            .then((response) => {
                const alertMsg =
                    response.data.is_disabled === true
                        ? 'تم التعطيل'
                        : 'تم التفعيل'
                successToastAlert(alertMsg)
                setChecked(!response.data.is_disabled)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }
    return (
        <Stack
            minWidth="240px"
            flex="1"
            p={2}
            spacing={2}
            alignItems="center"
            divider={<Divider flexItem />}
            sx={{
                borderRadius: '10px',
                boxShadow: 2,
                opacity: !checked ? '0.4' : '1.0',
            }}
        >
            <Typography variant="h6">
                {label.length > 16 ? `${label.slice(0, 16)}...` : label}
            </Typography>
            <Box width="100%" display="flex" justifyContent="space-between">
                <Stack spacing={1} direction="row">
                    <GoEditButton to={editPath} />
                    <DeleteButton
                        apiPath={apiPath}
                        id={id}
                        setCategoriesChanged={setCategoriesChanged}
                    />
                </Stack>
                <Switch
                    size="small"
                    checked={checked}
                    onChange={handleChange}
                />
            </Box>
        </Stack>
    )
}

export default LabelCard
