import * as yup from 'yup'

const maxImgSizeMB = 20
const maxImgSizeBytes = maxImgSizeMB * 2 ** 20
const oneDayInMS = 1 * 24 * 60 * 60 * 1000

export const addVendorSchema = yup.object().shape({
    name: yup.string().required('ادخل اسم الخدمة'),
    owner: yup.string().required('ادخل اسم مقدم الخدمة'),
    city_id: yup.string().required('ادخل   المدينة التى ينتمى ليها النشاط'),
    category_id: yup.string().required('ادخل الفئة  التى ينتمى ليها النشاط'),
    description: yup.string().required('ادخل وصف الخدمة'),
    address_info: yup.string().required('ادخل عنوان الخدمة'),
    hot_offers_limit: yup
        .number()
        .typeError('ادخل رقم صحيح اكبر من الصفر')
        .min(0, 'اقصي عدد يجب ان يكون صفر او اكثر'),
    img: yup
        .mixed()
        .nullable()
        .test(
            'fileSize',
            `حجم الصورة كبير جدا يجب الا يتعدى حجم الصورة ${maxImgSizeMB}MB`,
            (value) => (value != null ? value.size <= maxImgSizeBytes : 't')
        )
        .test('fileType', 'امتداد الصورة يجب ان يكون jpg , png', (value) =>
            value != null
                ? ['image/jpg', 'image/png', 'image/jpeg'].includes(value.type)
                : 't'
        ),
    is_disabled: yup.boolean(),
    email: yup.string().email('ادخل الايميل الصحيح'),
    password: yup
        .string()
        .required('ادخل الرقم السري')
        .min(8, ' الرقم السري قصير جدا'),
    password_confirmation: yup
        .string()
        .required('تأكد من تطابق الرقم السري')
        .oneOf([yup.ref('password')], 'تأكد من تطابق الرقم السري'),
    sub_expire_at: yup
        .date()
        .required('ادخل تاريخ انتهاء الاشتراك')
        .min(
            new Date(Date.now() + oneDayInMS),
            ` يجب ان يكون تاريخ الانتهاء بعد تاريخ اليوم `
        ),
})
