import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// components
import { PageTitle, StatsCard, RingsLoader } from '../../components'
// mui
import { Stack, Box } from '@mui/material'
// alerts
import { errorAlert } from '../../utils/alerts'

const OtpInfo = () => {
    const [otpInfo, setOtpInfo] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const userToken = useSelector((state) => state.userToken)
    useEffect(() => {
        setIsLoading(true)
        enhancedAxios(null, userToken)
            .get(`/admin/otp/me`)
            .then((response) => {
                setOtpInfo(response.data)
                setIsLoading(false)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [])
    if (isLoading) {
        return <RingsLoader />
    }
    return (
        <Box
            display="flex"
            gap="20px"
            justifyContent="center"
            alignItems="flex-start"
            flexWrap="wrap"
        >
            <StatsCard
                text="الرصيد المتبقي"
                number={otpInfo?.wallet?.credit}
                moreStyles={{
                    background:
                        Number(otpInfo?.wallet?.credit) < 10 ? 'red' : '',
                }}
            />
            <StatsCard
                text="عدد الرسائل المرسلة"
                number={otpInfo?.prod_calls}
            />
        </Box>
    )
}

export default OtpInfo
