import { Button } from '@mui/material'

const MainButton = ({ text, disabled }) => {
    return (
        <Button
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            disabled={disabled}
            sx={{
                color: 'basicColor.white',
            }}
        >
            {text}
        </Button>
    )
}

export default MainButton
