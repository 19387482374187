import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// mui
import { Box, Autocomplete, TextField } from '@mui/material'
// alerts
import { errorAlert } from '../../utils/alerts'

const SelectCategory = ({
    vendorCategory,
    setVendorCategory,
    setValues,
    parent_id_of_select_categories,
}) => {
    const [allCategories, setAllCategories] = useState([])
    const userToken = useSelector((state) => state.userToken)
    // const parend_id_of_vendors_activiies = "8236cf2c-1eae-4192-90e8-8567dd6fd42a";
    useEffect(() => {
        // get all Citites to make select box with this cities
        enhancedAxios(null, null)
            .get(
                `/categories?filters=is_disabled:eq:false,parent_id:${parent_id_of_select_categories}&page=1&paginate=10000`
            )
            .then((response) => {
                setAllCategories(response.data.data)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [])
    const handleAutoCompleteChange = (e, newValue) => {
        setVendorCategory(newValue)
        setValues((prevValues) => ({
            ...prevValues,
            category_id: newValue ? newValue.id : '',
        }))
    }
    return (
        <Box>
            {allCategories && (
                <Autocomplete
                    disablePortal
                    options={allCategories}
                    name="category_id"
                    getOptionLabel={(option) => option.name ?? ''}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    onChange={handleAutoCompleteChange}
                    defaultValue={vendorCategory ?? null}
                    value={vendorCategory ?? null}
                    filterSelectedOptions
                    sx={{
                        width: '100%',
                    }}
                    renderInput={(params) => {
                        return <TextField {...params} label="الفئة" />
                    }}
                />
            )}
        </Box>
    )
}

export default SelectCategory
