import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
// react router
import { useSearchParams } from 'react-router-dom'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// components
import {
    PageTitle,
    RingsLoader,
    PostCard,
    SearchBox,
    SortBox,
    StatusFilterBox,
    AddButton,
    DealCard,
} from '../../../components'
// mui
import { Stack, Box, Pagination, Typography } from '@mui/material'
// alerts
import { errorAlert } from '../../../utils/alerts'

const HotDeals = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    let pageFromParam =
        searchParams.get('page') != null ? searchParams.get('page') : 1
    const [deals, setDeals] = useState([]) // for all  deals
    const [dataChanged, setDataChanged] = useState(false) // if data changed render deals container
    const [isLoading, setIsLoading] = useState(true) // loadin
    const [itemsPerPage, setItemsPerPage] = useState(16)
    const [currentPage, setCurrentPage] = useState(1)
    const [searchValue, setSearchValue] = useState('')
    const [sortValue, setSortValue] = useState('-created_at')
    const [totalItems, setTotalItems] = useState(5)
    const [filterValues, setFilterValues] = useState({
        statusFilterValue: 'is_disabled:eq:false|true',
    })
    const userToken = useSelector((state) => state.userToken)
    // ------------------------------------------- pagination page changed ----------------------------------
    const handlePageChanging = (e, newPage) => {
        searchParams.set('page', newPage)
        setSearchParams(searchParams)
        setCurrentPage(newPage)
    }
    //  ------------------------------------------- get all deals -------------------------------------------
    useEffect(() => {
        enhancedAxios(null, userToken)
            .get(
                `/admin/hot-offers?page=${currentPage}&paginate=${itemsPerPage}&sorts=${sortValue}&filters=${filterValues.statusFilterValue}&q=${searchValue}`
            )
            .then((response) => {
                setTotalItems(response.data.meta.total)
                setDeals(response.data.data)
                setIsLoading(false)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [
        currentPage,
        itemsPerPage,
        sortValue,
        filterValues,
        searchValue,
        dataChanged,
    ])
    useEffect(() => {
        setCurrentPage(Number(searchParams.get('page')))
    }, [Number(searchParams.get('page'))])
    return (
        <Stack width="100%" height="100%" spacing={4} alignItems="center">
            <PageTitle title="إدارة اقوي العروض" />
            <Stack width="100%" spacing={2}>
                <Box>{/* <AddButton to="/add-hot-deal" /> */}</Box>
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap="20px"
                    flexWrap="wrap"
                    sx={{
                        '& .MuiBox-root': {
                            flex: '1',
                            minWidth: '240px',
                        },
                    }}
                >
                    <SearchBox
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                    <SortBox
                        sortValue={sortValue}
                        setSortValue={setSortValue}
                    />
                    <StatusFilterBox
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                    />
                </Box>
            </Stack>
            {/* filter seach sort seaction  */}
            <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                flexWrap="wrap"
                gap="20px"
                width="100%"
            >
                {isLoading ? (
                    <RingsLoader />
                ) : deals.length === 0 && searchValue === '' ? (
                    <Typography variant="body1">
                        لا يوجد عناصر لعرضها
                    </Typography>
                ) : deals.length === 0 && searchValue !== '' ? (
                    <Typography variant="body1">
                        {' '}
                        لا يوجد عناصر لنتيجة البحث{' '}
                    </Typography>
                ) : (
                    <>
                        {deals.map((deal) => {
                            return (
                                <DealCard
                                    key={deal.id}
                                    deal={deal}
                                    setDataChanged={setDataChanged}
                                />
                            )
                        })}
                        <Box
                            width="100%"
                            display="flex"
                            justifyContent="center"
                        >
                            {totalItems > itemsPerPage ? (
                                <Pagination
                                    defaultPage={1}
                                    page={currentPage}
                                    count={Math.ceil(totalItems / itemsPerPage)}
                                    onChange={handlePageChanging}
                                    variant="outlined"
                                    color="primary"
                                />
                            ) : (
                                ''
                            )}
                        </Box>
                    </>
                )}
            </Box>
        </Stack>
    )
}

export default HotDeals
