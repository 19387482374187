// reac router dom
import { Link } from 'react-router-dom'
// mui
import { Button } from '@mui/material'
const GoEditButton = ({ to }) => {
    return (
        <Button
            component={Link}
            to={to}
            size="small"
            variant="outlined"
            color="primary"
        >
            تعديل
        </Button>
    )
}

export default GoEditButton
