import { useState, useEffect } from 'react'
// router
import { useParams } from 'react-router'
// redux
import { useSelector } from 'react-redux'
// components
import { PageTitle, MainButton, ErrorMessage } from '../../../components'
// mui
import { Stack, Box, TextField, Autocomplete } from '@mui/material'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// formik
import { useFormik } from 'formik'
// shcemas
import { renewSubSchema } from '../../../schemes/manageVendors/renewSubSchema'
import { errorAlert, successAlert } from '../../../utils/alerts'

const subRequestStatus = [
    {
        title: 'قيد الانتظار',
        value: 'pending',
    },
    {
        title: 'مكتملة',
        value: 'completed',
    },
]

const monthsOptions = [
    {
        title: 'شهر',
        value: '1',
    },
    {
        title: 'شهرين',
        value: '2',
    },
    {
        title: '3 شهور',
        value: '3',
    },
    {
        title: '4 شهور',
        value: '4',
    },
    {
        title: '5 شهور',
        value: '5',
    },
    {
        title: '6 شهور',
        value: '6',
    },
    {
        title: '7 شهور',
        value: '7',
    },
    {
        title: '8 شهور',
        value: '8',
    },
    {
        title: '9 شهور',
        value: '9',
    },
    {
        title: '10 شهور',
        value: '10',
    },
    {
        title: '11 شهر',
        value: '11',
    },
    {
        title: '12 شهر',
        value: '12',
    },
]

const VendorAddRequest = () => {
    const [paymentMethods, setPaymentMethods] = useState([])
    const userToken = useSelector((state) => state.userToken)
    const [monthsValue, setMonthsValue] = useState()
    const [monthCost, setMonthCost] = useState(0)
    const [statusValue, setStatusValue] = useState()
    const [paymentMethodValue, setPaymentMethodValue] = useState()
    //  --------------------------------- handle auto change ---------------------------------
    const handleAutoChange = (e, newValue, belongTo, setValue) => {
        if (belongTo === 'payment_method_id') {
            values[belongTo] = newValue.id
        } else {
            values[belongTo] = newValue.value
        }
        setValue(newValue)
    }
    // --------------------------------- submit ---------------------------------------------------------------------
    const onSubmit = (values) => {
        const formData = new FormData()
        for (let item in values) {
            formData.append(item, values[item])
        }
        enhancedAxios('multipart/form-data', userToken)
            .post(`/vendor/subscription-requests?lang=ar`, formData)
            .then((response) => {
                successAlert('تم عمل طلب تجديد ')
                resetForm()
                setMonthsValue('')
                setStatusValue('')
                setPaymentMethodValue('')
                setSubmitting(false)
            })
            .catch((error) => {
                errorAlert(error)
                setSubmitting(false)
            })
    }
    // --------------------------------- formik configration ----------------------------------------------------------
    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            months: '',
            cost: '0',
            status: 'pending',
            payment_method_id: '',
            note: '',
        },
        // validationSchema:renewSubSchema,
        onSubmit, //function we write above
    })
    // get payment methods
    useEffect(() => {
        enhancedAxios(null, null)
            .get(
                `/payment-methods?sorts=-created_at&page=1&paginate=1000&filters=is_disabled:eq:false`
            )
            .then((response) => {
                setPaymentMethods(response.data.data)
            })
            .catch((error) => {
                errorAlert(error)
            })

        // get month cost value
        enhancedAxios(null, null)
            .get(`/settings?q=cost`)
            .then((response) => {
                setMonthCost(response.data.data[0].value)
            })
    }, [])
    // set all cost value
    useEffect(() => {
        setValues((prev) => ({
            ...prev,
            cost: `${(parseFloat(monthCost) * parseInt(values.months)).toFixed(
                2
            )}`,
        }))
    }, [values.months])
    return (
        <Stack spacing={4} alignItems="center">
            <PageTitle title=" طلب تجديد الاشتراك" />
            <Stack
                component="form"
                onSubmit={handleSubmit}
                width="100%"
                maxWidth="700px"
                alignItems="center"
                spacing={2}
            >
                <Autocomplete
                    disablePortal
                    options={monthsOptions}
                    name="months"
                    getOptionLabel={(option) =>
                        option.title ? option.title : ''
                    }
                    isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                    }
                    onChange={(e, newValue) =>
                        handleAutoChange(e, newValue, 'months', setMonthsValue)
                    }
                    // defaultValue={vendorCategory ?? ""}
                    value={monthsValue ?? ''}
                    filterSelectedOptions
                    sx={{
                        width: '100%',
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                label="مدة الاشتراك بالشهر"
                            />
                        )
                    }}
                />
                <TextField
                    label="قيمة الطلب"
                    name="cost"
                    value={values.cost === 'NaN' ? '0' : values.cost}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                        readOnly: true,
                    }}
                    sx={{
                        width: '100%',
                    }}
                />
                <Autocomplete
                    disablePortal
                    options={paymentMethods}
                    name="payment_method_id"
                    getOptionLabel={(option) => option.name ?? ''}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    onChange={(e, newValue) =>
                        handleAutoChange(
                            e,
                            newValue,
                            'payment_method_id',
                            setPaymentMethodValue
                        )
                    }
                    // defaultValue={vendorCategory ?? ""}
                    value={paymentMethodValue ?? ''}
                    filterSelectedOptions
                    sx={{
                        width: '100%',
                    }}
                    renderInput={(params) => {
                        return <TextField {...params} label="طريقة الدفع" />
                    }}
                />
                <TextField
                    label="ملاحظات"
                    name="note"
                    value={values.note}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                        width: '100%',
                    }}
                />
                <Box width="100%" sx={{ '& button': { width: '100%' } }}>
                    <MainButton
                        text="طلب تجديد الاشتراك"
                        disabled={isSubmitting}
                    />
                    {/* errors section  */}
                    {errors && (
                        <Stack spacing={1}>
                            {errors.months && touched.months && (
                                <ErrorMessage errorMsg={errors.months} />
                            )}
                            {errors.cost && touched.cost && (
                                <ErrorMessage errorMsg={errors.cost} />
                            )}
                            {errors.status && touched.status && (
                                <ErrorMessage errorMsg={errors.status} />
                            )}
                            {errors.payment_method_id &&
                                touched.payment_method_id && (
                                    <ErrorMessage
                                        errorMsg={errors.payment_method_id}
                                    />
                                )}
                        </Stack>
                    )}
                </Box>
            </Stack>
        </Stack>
    )
}

export default VendorAddRequest
