// react router
import { useNavigate } from 'react-router'
// redux h
import { useSelector, useDispatch } from 'react-redux'
// axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// mui
import { Button, Box, Typography } from '@mui/material'
// icons
import { MdLogout } from 'react-icons/md'
// alerts
import {
    askForConfirmationAlert,
    errorAlert,
    successAlert,
} from '../../utils/alerts/index'
// redux actions
import { removeTokenFromLocal } from '../../redux/actions/userActions'

const LogoutButton = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const logoutProcess = () => {
        enhancedAxios(null, null)
            .get('/logout')
            .then((response) => {
                // successAlert()
            })
            // .catch(error => errorAlert(error))
            .finally(() => {
                dispatch(removeTokenFromLocal())
                navigate('/login')
            })
    }
    const handleLogout = () => {
        askForConfirmationAlert(
            'هل انت متأكد انك تريد تسجيل الخروج ؟',
            logoutProcess
        )
    }
    return (
        <Box
            component={Button}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gap="10px"
            sx={{
                color: 'inherit',
                padding: '8px',
                borderRadius: '5px',
                '&:hover': {
                    backgroundColor: 'primary.light',
                },
            }}
            onClick={handleLogout}
        >
            <Typography component="span">
                <MdLogout />
            </Typography>
            <Typography component="span">تسجيل الخروج</Typography>
        </Box>
    )
}

export default LogoutButton
