import * as yup from 'yup'
export const addOrderDelivererSchema = yup.object().shape({
    name: yup.string().required('يجب ان يكون هناك اسم شركة التوصيل'),
    email: yup
        .string()
        .email('ادخل الايميل الصحيح')
        .required('ادخل البريد الالكترونى خاصة شركة التوصيل'),
    password: yup
        .string()
        .required('ادخل الرقم السري')
        .min(8, ' الرقم السري قصير جدا'),
    password_confirmation: yup
        .string()
        .required('تأكد من تطابق الرقم السري')
        .oneOf([yup.ref('password')], 'تأكد من تطابق الرقم السري'),
})
