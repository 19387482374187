import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// mui
import { Stack, Box, TextField } from '@mui/material'
// components
import { SideTitle, MainButton, ErrorMessage } from '../../components'
// formik
import { useFormik } from 'formik'
// schema
import { subCostSchema } from '../../schemes/settings/subCostSchema'
// alerts
import { errorAlert, successAlert, successToastAlert } from '../../utils/alerts'

const SubscribtionCost = () => {
    const cost_key_in_settings = 'monthly_sub_cost'
    const [costId, setCostId] = useState(0)
    const userToken = useSelector((state) => state.userToken)

    // submit form
    const onSubmit = (values) => {
        const formData = new FormData()
        formData.append('value', values.monthly_sub_cost)
        enhancedAxios('multipart/form-data', userToken)
            .put(`/admin/settings/${costId}`, formData)
            .then((response) => {
                successToastAlert('تم تعديل التكلفة')
                setSubmitting(false)
            })
            .catch((error) => {
                setSubmitting(false)
                errorAlert(error)
            })
    }

    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
    } = useFormik({
        initialValues: {
            monthly_sub_cost: '0',
        },
        validationSchema: subCostSchema,
        onSubmit, //function we write above
    })
    // get id of cost key in api
    useEffect(() => {
        enhancedAxios(null, userToken)
            .get(`/admin/settings?sorts=updated_at&page=1&paginate=120&q=cost`)
            .then((response) => {
                setCostId(response.data.data[0].id)
                setValues((prev) => ({
                    ...prev,
                    monthly_sub_cost: response.data.data[0].value,
                }))
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [])
    return (
        <Stack spacing={1}>
            <SideTitle title="تكلفة الاشتراك بالشهر" />
            <Box
                width="100%"
                display="flex"
                justifyContent="flex-start"
                flexWrap="wrap"
                gap="20px"
                component="form"
                onSubmit={handleSubmit}
            >
                <TextField
                    type="text"
                    name="monthly_sub_cost"
                    value={values.monthly_sub_cost}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                        flex: '1',
                        minWidth: '300px',
                    }}
                    // label="تكلفة الاشتراك بالشهر"
                />
                <Box maxHeight="56px">
                    <MainButton text="تعديل التكلفة" disabled={isSubmitting} />
                </Box>
            </Box>
            {errors && (
                <Stack spacing={1}>
                    {errors.monthly_sub_cost && touched.monthly_sub_cost && (
                        <ErrorMessage errorMsg={errors.monthly_sub_cost} />
                    )}
                </Stack>
            )}
        </Stack>
    )
}

export default SubscribtionCost
