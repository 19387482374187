import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
// router
import { useParams } from 'react-router'
// formik
import { useFormik } from 'formik'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// components
import {
    PageTitle,
    MainButton,
    RingsLoader,
    ErrorMessage,
} from '../../../components'
//mui
import { Stack, TextField, Autocomplete, Box, Typography } from '@mui/material'
// schema
import { editCustomerRequestSchema } from '../../../schemes/customerRequest/editCustomerRequestSchema'
// alerts
import { errorAlert, successAlert } from '../../../utils/alerts'

const EditCustomerRequest = () => {
    const { id } = useParams()
    const userToken = useSelector((state) => state.userToken)
    const [requestData, setRequestData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [vendorsLoading, setVendorsLoading] = useState(true)
    const [vendors, setVendors] = useState([])
    const [notEqualCategoriesWarning, setNotEqualCategoriesWarning] =
        useState(false)
    // ---------------------------- handle autoocmplete change  ----------------------------
    const handleAutocompleteChange = (e, newValue) => {
        if (newValue.id != requestData.vendor_category.id) {
            setNotEqualCategoriesWarning(true)
        }
        setValues((prev) => ({ vendor_id: newValue ? newValue.id : '' }))
    }
    // submit for m
    const onSubmit = (values) => {
        const formData = new FormData()
        for (let item in values) {
            formData.append(item, values[item])
        }
        enhancedAxios('multipart/form-data', userToken)
            .put(`/admin/customer-requests/${id}`, formData)
            .then((response) => {
                successAlert('تم توجيه الطلب')
                setSubmitting(false)
            })
            .catch((error) => {
                errorAlert(error)
                setSubmitting(false)
            })
    }
    // -----------------------------------------------  formik configraion ----------------------------------------------------------
    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
    } = useFormik({
        initialValues: {
            vendor_id: '',
        },
        validationSchema: editCustomerRequestSchema,
        onSubmit,
        handleAutocompleteChange,
    })

    useEffect(() => {
        setIsLoading(true)
        setVendorsLoading(true)
        enhancedAxios(null, userToken)
            .get(`/admin/customer-requests/${id}`)
            .then((res) => {
                setRequestData(res.data)
                setIsLoading(false)
            })
            .catch((error) => errorAlert(error))

        enhancedAxios(null, userToken)
            .get(`/admin/vendors?sorts=-created_at&page=1&paginate=99999`)
            .then((response) => {
                setVendors(response.data.data)
                setVendorsLoading(false)
            })
    }, [])

    if (isLoading) return <RingsLoader />
    return (
        <Stack width="100%" height="100%" spacing={8} alignItems="center">
            <PageTitle title="توجيه طلب المستخدم " />
            <Stack
                component="form"
                onSubmit={handleSubmit}
                width="100%"
                maxWidth="700px"
                spacing={2}
                alignItems="flex-start"
            >
                <TextField
                    name="username"
                    label="اسم المستخدم"
                    value={requestData.user.name}
                    inputProps={{ readOnly: true }}
                    sx={{
                        width: '100%',
                    }}
                />
                <TextField
                    name="details"
                    label="تفاصيل الطلب"
                    value={requestData.details}
                    inputProps={{ readOnly: true }}
                    sx={{
                        width: '100%',
                    }}
                    multiline
                    minRows={2}
                    maxRows={6}
                />
                <TextField
                    name="vendorCategory"
                    label="فئة النشاط"
                    value={requestData.vendor_category.name}
                    inputProps={{ readOnly: true }}
                    sx={{
                        width: '100%',
                    }}
                />
                <TextField
                    name="status"
                    label="حالة النشاط"
                    value={requestData.status}
                    inputProps={{ readOnly: true }}
                    sx={{
                        width: '100%',
                    }}
                />
                <Autocomplete
                    disablePortal
                    getOptionLabel={(option) => option.name ?? ''}
                    options={vendorsLoading ? [] : vendors}
                    sx={{ width: '100%' }}
                    onChange={(e, newValue) =>
                        handleAutocompleteChange(e, newValue)
                    }
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    renderInput={(params) => (
                        <TextField {...params} label="  توجيه الى" />
                    )}
                    defaultValue={requestData.vendor ?? null}
                />
                {notEqualCategoriesWarning && (
                    <Typography variant="body2" sx={{ color: 'warning.main' }}>
                        ملحوظة : الفئة التى ينتمى اليها هذا النشاط التجارى لا
                        يتطابق مع فئة طلب المستخدم
                    </Typography>
                )}
                <Box width="100%" sx={{ '& button': { width: '100%' } }}>
                    <MainButton text="توجيه " disabled={isSubmitting} />
                </Box>
                {errors && (
                    <Stack spacing={1}>
                        {errors.vendor_id && touched.vendor_id && (
                            <ErrorMessage errorMsg={errors.vendor_id} />
                        )}
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}

export default EditCustomerRequest
