import { EditDetails, DeleteButton } from '../../components'
// user table columns
export const vendorRequestsCols = [
    {
        field: 'name',
        headerName: 'اسم النشاط',
        width: '200',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.vendor.name ?? '!! غير معروف !!',
    },

    {
        field: 'cost',
        headerName: ' التكلفة',
        width: '180',
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.cost ? `${params.row.cost} د.ل` : '!! غير معروف !!',
    },

    {
        field: 'payment_method',
        headerName: 'طريقة الدفع',
        width: '160',
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.payment_method?.name ?? '!! غير معروف !!',
    },

    {
        field: 'months',
        headerName: 'المدة المطلوبة',
        width: '160',
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.months
                ? visibleMonthsTextAR(params.row.months)
                : '!! غير معروف !!',
    },

    {
        field: 'status',
        headerName: 'حالة الطلب',
        width: '160',
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.status
                ? visibleStatusTextAR(params.row.status)
                : '!! غير معروف !!',
    },

    {
        field: 'edit',
        headerName: 'مشاهدة',
        width: '160',
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <EditDetails
                to={`/vendor-edit-subscription-request/${params.row.id}`}
            />
        ),
    },
]

const visibleMonthsTextAR = (val) => {
    if (val == 1 || val == 2 || val == 11 || val == 12) {
        return `${val} شهر`
    }
    return `${val} شهور`
}

const visibleStatusTextAR = (val) => {
    if (val == 'completed') {
        return `مكتملة `
    }
    return `قيد الانتظار`
}
