import { useState, useEffect } from 'react'
// mui
import { Box, Stack, Container, IconButton } from '@mui/material'
// components
import { NavbarLogo, NavbarLinks, LogoutButton } from '../../components'
// icons and images
import { FaAngleDoubleRight } from 'react-icons/fa'
import logoImg from '../../assets/white-logo.jpg'

const Navbar = ({ navbarOpen, setNavbarOpen, links }) => {
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 900) {
                setNavbarOpen(false)
            } else {
                setNavbarOpen(true)
            }
            setWindowSize(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [windowSize, navbarOpen])
    return (
        <Stack
            className="hide-scrollbar"
            sx={{
                height: '100vh',
                width: '270px',
                backgroundColor: 'primary.main',
                padding: '30px 0',
                overflow: 'auto',
                transition: 'transform .3s ease',
                position: { xs: 'fixed', md: 'relative' },
                transform: `scaleX(${navbarOpen ? '1' : '0'})`,
                transformOrigin: 'left',
                zIndex: '99',
            }}
        >
            <Container>
                <Stack
                    width="100%"
                    height="100%"
                    spacing={5}
                    alignItems="center"
                >
                    <Stack spacing={2} width="100%" alignItems="center">
                        <IconButton
                            sx={{
                                marginRight: 'auto',
                                display: { xs: 'flex', md: 'none' },
                            }}
                            onClick={() => setNavbarOpen(false)}
                        >
                            <FaAngleDoubleRight />
                        </IconButton>
                        <NavbarLogo imgSrc={logoImg} />
                    </Stack>
                    <Stack
                        spacing={2}
                        width="100%"
                        sx={{ color: 'basicColor.white' }}
                    >
                        <NavbarLinks links={links} />
                        <LogoutButton />
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    )
}

export default Navbar
