import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// component
import {
    PageTitle,
    RingsLoader,
    LabelCard,
    SearchBox,
    SortBox,
    StatusFilterBox,
    AddButton,
} from '../../../components'
// mui
import { Stack, Box, Pagination, Typography } from '@mui/material'
// alerts
import { errorAlert } from '../../../utils/alerts/index'

const ManagePaymentMethods = () => {
    const userToken = useSelector((state) => state.userToken)
    const [allMethods, setAllMethods] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [itemsPerPage, setItemsPerPage] = useState(15)
    const [currentPage, setCurrentPage] = useState(1)
    const [searchValue, setSearchValue] = useState('')
    const [sortValue, setSortValue] = useState('-created_at')
    const [totalItems, setTotalItems] = useState(5)
    const [categoriesChanged, setCategoriesChanged] = useState(false)
    const [filterValues, setFilterValues] = useState({
        statusFilterValue: 'is_disabled:eq:false|true',
    })
    // ------------------------------------------- pagination page changed ----------------------------------
    const handlePageChanging = (e, newPage) => {
        setCurrentPage(newPage)
    }
    // ------------------------------------------- get all methods -------------------------------------------
    useEffect(() => {
        setIsLoading(true)
        enhancedAxios(null, userToken)
            .get(
                `admin/payment-methods?page=${currentPage}&paginate=${itemsPerPage}&filters=${filterValues.statusFilterValue}&q=${searchValue}&sorts=${sortValue}`
            )
            .then((response) => {
                setAllMethods(response.data.data)
                setTotalItems(response.data.meta.total)
                setIsLoading(false)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [
        currentPage,
        itemsPerPage,
        searchValue,
        sortValue,
        filterValues,
        categoriesChanged,
    ])
    return (
        <Stack width="100%" height="100%" spacing={4} alignItems="center">
            <PageTitle title="ادارة طرق الدفع" />
            {/* add button and filter box  */}
            <Stack spacing={4} width="100%">
                <Box>
                    <AddButton to="/add-payment-method" />
                </Box>
                {/* --------------------------------- filter section ------------------------------------------------------- */}
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap="20px"
                    flexWrap="wrap"
                    sx={{
                        '& .MuiBox-root': {
                            flex: '1',
                            minWidth: '240px',
                            // maxWidth:'480px',
                        },
                    }}
                >
                    <SearchBox
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                    <SortBox
                        sortValue={sortValue}
                        setSortValue={setSortValue}
                    />
                    <StatusFilterBox
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                    />
                </Box>
            </Stack>
            {/* methods cards  */}
            <Box
                display="flex"
                gap="20px"
                justifyContent="center"
                alignItems="flex-start"
                flexWrap="wrap"
                sx={{
                    '& .MuiStack-root': {
                        minWidth: '250px',
                    },
                }}
            >
                {isLoading ? (
                    <RingsLoader />
                ) : allMethods.length === 0 && searchValue === '' ? (
                    <Typography variant="body1">
                        لا يوجد عناصر لعرضها
                    </Typography>
                ) : allMethods.length === 0 && searchValue !== '' ? (
                    <Typography variant="body1">
                        {' '}
                        لا يوجد عناصر لنتيجة البحث{' '}
                    </Typography>
                ) : (
                    <>
                        {allMethods.map((item) => (
                            <LabelCard
                                editPath={`/edit-payment-method/${item.id}`}
                                apiPath={`/admin/payment-methods/${item.id}`}
                                key={item.id}
                                id={item.id}
                                label={item.name}
                                setCategoriesChanged={setCategoriesChanged}
                            />
                        ))}
                    </>
                )}
            </Box>
            {/* pagination  */}
            <Box width="100%" display="flex" justifyContent="center">
                {totalItems > itemsPerPage ? (
                    <Pagination
                        defaultPage={1}
                        page={currentPage}
                        count={Math.ceil(totalItems / itemsPerPage)}
                        onChange={handlePageChanging}
                        variant="outlined"
                        color="primary"
                    />
                ) : (
                    ''
                )}
            </Box>
        </Stack>
    )
}

export default ManagePaymentMethods
