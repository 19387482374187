import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// components
import {
    PageTitle,
    AddButton,
    RingsLoader,
    BannerCard,
    SortBox,
    StatusFilterBox,
} from '../../../components'
// mui
import { Stack, Box, Typography, Pagination } from '@mui/material'

const bannersSortingOptions = [
    {
        sortTitle: 'تاريخ الانشاء من الاحدث الى الاقدم',
        sortKey: '-created_at',
    },
    {
        sortTitle: 'تاريخ الانشاء من الاقدم الى الاحدث',
        sortKey: 'created_at',
    },
    {
        sortTitle: 'تاريخ التحديث من الاحدث الى الاقدم',
        sortKey: '-updated_at',
    },
    {
        sortTitle: 'تاريخ التحديث من الاقدم الى الاحدث',
        sortKey: 'updated_at',
    },
]

const ManageBanners = () => {
    const [banners, setBanners] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [sortValue, setSortValue] = useState('-created_at')
    const [categoriesChanged, setCategoriesChanged] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [totalItems, setTotalItems] = useState()
    const [filterValues, setFilterValues] = useState({
        statusFilterValue: 'is_disabled:eq:false|true',
    })
    const userToken = useSelector((state) => state.userToken)
    // ------------------------------------------- pagination page changed ----------------------------------
    const handlePageChanging = (e, newPage) => {
        setCurrentPage(newPage)
    }
    // ------------------------------------------- get all banners  -------------------------------------------
    useEffect(() => {
        setIsLoading(true)
        enhancedAxios(null, userToken)
            .get(
                `admin/banners?page=${currentPage}&paginate=${itemsPerPage}&sorts=${sortValue}&filters=${filterValues.statusFilterValue}`
            )
            .then((response) => {
                setBanners(response.data.data)
                setTotalItems(response.data.meta.total)
                setIsLoading(false)
            })
    }, [categoriesChanged, currentPage, itemsPerPage, sortValue, filterValues])
    // ------------------------------------------- page -------------------------------------------
    return (
        <Stack width="100%" height="100%" spacing={4} alignItems="center">
            <PageTitle title="إدارة الإعلانات" />
            {/* add button and filter section  */}
            <Stack spacing={4} width="100%">
                <Box>
                    <AddButton to="/add-banner" />
                </Box>
                {/* --------------------------------- filter section ------------------------------------------------------- */}
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap="20px"
                    flexWrap="wrap"
                    sx={{
                        '& .MuiBox-root': {
                            flex: '1',
                            minWidth: '240px',
                            // maxWidth:'480px',
                        },
                    }}
                >
                    <SortBox
                        sortValue={sortValue}
                        setSortValue={setSortValue}
                        sortingOptions={bannersSortingOptions}
                    />
                    <StatusFilterBox
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                    />
                </Box>
            </Stack>
            <Stack width="100%" spacing={2}>
                {isLoading ? (
                    <RingsLoader />
                ) : banners.length === 0 ? (
                    <Typography variant="h5">لا يوجد اعلانات لعرضها</Typography>
                ) : (
                    <>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            gap="20px"
                            flexWrap="wrap"
                        >
                            {banners.map((banner) => (
                                <BannerCard
                                    key={banner.id}
                                    img={banner.img}
                                    editPath={`/edit-banner/${banner.id}`}
                                    apiPath={`/admin/banners/${banner.id}`}
                                    url={banner.url}
                                    setCategoriesChanged={setCategoriesChanged}
                                />
                            ))}
                        </Box>
                        {/* pagination  */}
                        <Box
                            width="100%"
                            display="flex"
                            justifyContent="center"
                        >
                            {totalItems > itemsPerPage ? (
                                <Pagination
                                    defaultPage={1}
                                    page={currentPage}
                                    count={Math.ceil(totalItems / itemsPerPage)}
                                    onChange={handlePageChanging}
                                    variant="outlined"
                                    color="primary"
                                />
                            ) : (
                                ''
                            )}
                        </Box>
                    </>
                )}
            </Stack>
        </Stack>
    )
}

export default ManageBanners
