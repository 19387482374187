import { IoHomeOutline } from 'react-icons/io5'
import { BsPostcard } from 'react-icons/bs'
import { VscGitPullRequestNewChanges } from 'react-icons/vsc'
import { AiOutlineUserSwitch } from 'react-icons/ai'
import { TbTruckDelivery } from 'react-icons/tb'
import { MdOutlineWhatshot } from 'react-icons/md'
import EnhancedAxios from '../axiosUtils/enhancedAxios'

let whatsappLink = ''
const getWhatsAppNumber = () => {
    EnhancedAxios(null, null)
        .get(`/settings?q=app_whatsapp_url`)
        .then((response) => {
            serviceOwnerLinks[serviceOwnerLinks.length - 1].path =
                response.data.data[0].value
            serviceOwnerLinks[serviceOwnerLinks.length - 2].path =
                response.data.data[0].value
        })
        .catch((error) => console.log(error))
}
getWhatsAppNumber()

export const serviceOwnerLinks = [
    {
        title: 'الرئيسية',
        icon: <IoHomeOutline />,
        path: '/vendor',
    },
    {
        title: 'ادارة المنشورات ',
        icon: <BsPostcard />,
        path: '/manage-vendor-posts',
    },
    {
        title: 'ادارة اقوي العروض ',
        icon: <MdOutlineWhatshot />,
        path: '/vendor-hot-deals',
    },
    {
        title: 'ادارة توصيل الطلبات ',
        icon: <TbTruckDelivery />,
        path: '/manage-delivery-orders',
    },
    {
        title: 'تعديل البيانات',
        icon: <AiOutlineUserSwitch />,
        path: '',
    },
    {
        title: 'تجديد الاشتراك',
        icon: <VscGitPullRequestNewChanges />,
        path: '',
    },
]
