import { IoHomeOutline } from 'react-icons/io5'
import { FiUsers } from 'react-icons/fi'
import {
    MdStorefront,
    MdOutlineArticle,
    MdOutlineCategory,
    MdOutlineLocationCity,
    MdPayment,
    MdOutlineNotificationsNone,
    MdOutlineReportGmailerrorred,
    MdOutlineWhatshot,
} from 'react-icons/md'
import { BsPostcard, BsBoxSeam } from 'react-icons/bs'
import { VscGitPullRequestNewChanges } from 'react-icons/vsc'
import { TbSettings, TbTruckDelivery } from 'react-icons/tb'
import { BiUserPin } from 'react-icons/bi'
import { TfiLayoutSliderAlt } from 'react-icons/tfi'

export const adminLinks = [
    {
        title: 'الرئيسية',
        icon: <IoHomeOutline />,
        path: '/',
    },
    {
        title: 'ادارة المستخدمين',
        icon: <FiUsers />,
        path: '/manage-users?page=1',
    },
    {
        title: 'ادارة موصلى الطلبات',
        icon: <TbTruckDelivery />,
        path: '/manage-order-deliverers?page=1',
    },
    {
        title: 'ادارة فئات النشاطات',
        icon: <MdOutlineArticle />,
        path: '/manage-category-activities',
    },
    {
        title: 'ادارة فئات المنشورات',
        icon: <MdOutlineCategory />,
        path: '/manage-category-posts',
    },
    {
        title: 'ادارة المدن',
        icon: <MdOutlineLocationCity />,
        path: '/manage-cities',
    },
    {
        title: 'ادارة النشاطات التجارية',
        icon: <MdStorefront />,
        path: '/manage-vendors-activities?page=1',
    },
    {
        title: 'ادارة المنشورات ',
        icon: <BsPostcard />,
        path: '/manage-posts',
    },
    {
        title: 'ادارة اقوي العروض ',
        icon: <MdOutlineWhatshot />,
        path: '/hot-deals',
    },
    {
        title: 'ادارة طلبات الاشتراك ',
        icon: <VscGitPullRequestNewChanges />,
        path: '/manage-subscriptions-request',
    },
    {
        title: 'ادارة توصيل الطلبات  ',
        icon: <BsBoxSeam />,
        path: '/manage-order-delivery-info?page=1',
    },
    {
        title: 'ادارة طرق الدفع ',
        icon: <MdPayment />,
        path: '/manage-payment-methods',
    },
    {
        title: 'ادارة الاعلانات ',
        icon: <TfiLayoutSliderAlt />,
        path: '/manage-banners',
    },
    {
        title: 'ادارة الاشعارات ',
        icon: <MdOutlineNotificationsNone />,
        path: '/push-notification',
    },
    {
        title: 'ادارة البلاغات ',
        icon: <MdOutlineReportGmailerrorred />,
        path: '/manage-reports',
    },
    {
        title: 'ادارة طلبات المستخدمين ',
        icon: <BiUserPin />,
        path: '/manage-customer-requests',
    },
    {
        title: 'الضبط',
        icon: <TbSettings />,
        path: '/settings',
    },
]
