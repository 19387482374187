import * as yup from 'yup'
const phoneRegex = new RegExp(/[+]?[0-9]?\s?[0-9]{10,14}/)

export const addOrderToDeliverySchema = yup.object().shape({
    name: yup.string().required('من فضلك ادخل الاسم  '),
    phone: yup
        .string()
        .nullable()
        .matches(phoneRegex, 'كتابة رقم الهاتف مسبوق بكود الدولة'),
    address: yup.string().required('من فضلك ادخل عنوان التوصيل  '),
    details: yup.string(),
    cost: yup.string().required('من فضلك ادخل القيمة المراد استلامها  '),
})
