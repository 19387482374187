import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// mui
import { Box, Autocomplete, TextField } from '@mui/material'
import { errorAlert } from '../../utils/alerts'

const CitiesFilterBox = ({ filterValues, setFilterValues }) => {
    const [value, setValue] = useState('')
    const [allCities, setAllCities] = useState([])
    const userToken = useSelector((state) => state.userToken)
    useEffect(() => {
        // get all Citites to make select box with this cities
        enhancedAxios(null, userToken)
            .get(
                '/admin/cities?sorts=-created_at&filters=is_disabled:eq:false&page=1&paginate=10000'
            )
            .then((response) => {
                setAllCities(response.data.data)
            })
            .catch((error) => errorAlert(error))
    }, [])
    // -------------------------------- handleAutoCompleteChange ------------------------------------------
    const handleAutoCompleteChange = (e, newValue) => {
        if (newValue.length > 0) {
            let selectedCitiesId = []
            newValue.forEach((value) => {
                selectedCitiesId.push(value.id)
            })
            let selectedCitiesIdCombined = selectedCitiesId.join('|')
            setFilterValues({
                ...filterValues,
                citiesFilterValue: `city_id:${selectedCitiesIdCombined}`,
            })
        } else {
            setFilterValues({
                ...filterValues,
                citiesFilterValue: ``,
            })
        }
    }
    return (
        <Box>
            <Autocomplete
                multiple
                disablePortal
                options={allCities}
                getOptionLabel={(option) => option.name ?? ''}
                onChange={handleAutoCompleteChange}
                // defaultValue={value}
                filterSelectedOptions
                sx={{
                    width: '100%',
                }}
                renderInput={(params) => {
                    return <TextField {...params} label="المدينة" />
                }}
            />
        </Box>
    )
}

export default CitiesFilterBox
