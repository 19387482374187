// redux
import { useSelector } from 'react-redux'
// router
import { useNavigate } from 'react-router'
// axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// alerts
import {
    errorAlert,
    successAlert,
    askForConfirmationAlert,
} from '../../utils/alerts'
// mui
import { Button } from '@mui/material'

const DeleteButton = ({ apiPath, setCategoriesChanged }) => {
    const userToken = useSelector((state) => state.userToken)
    const navigate = useNavigate()
    const deleteProcess = () => {
        enhancedAxios(null, userToken)
            .delete(apiPath)
            .then((response) => {
                successAlert('تم الحذف')
                setCategoriesChanged((prev) => !prev)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }
    const handleDelete = () => {
        askForConfirmationAlert('هل انت متاكد انك تريد حذف؟؟', deleteProcess)
    }
    return (
        <Button size="small" color="error" onClick={handleDelete}>
            حذف
        </Button>
    )
}

export default DeleteButton
