// react redux
import { useSelector } from 'react-redux'
// formik
import { useFormik } from 'formik'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// components
import { PageTitle, MainButton, ErrorMessage } from '../../../components'
// mui
import { Stack, TextField, FormControlLabel, Checkbox } from '@mui/material'
// alerts
import { successAlert, errorAlert } from '../../../utils/alerts'
import { addOrderToDeliverySchema } from '../../../schemes/orderDelivery/addOrderToDeliverySchema'

const AddOrderToDelivery = () => {
    const userToken = useSelector((state) => state.userToken)
    const onSubmit = (values) => {
        enhancedAxios(null, userToken)
            .post(`vendor/delivery-orders`, values)
            .then((response) => {
                successAlert('تم اضافة طلب للتوصيل')
                resetForm()
                setSubmitting(false)
            })
            .catch((error) => {
                errorAlert(error)
                setSubmitting(false)
            })
    }
    // formik configration
    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            phone: '',
            address: '',
            name: '',
            details: '',
            cost: '',
        },
        validationSchema: addOrderToDeliverySchema,
        onSubmit, //function we write above
    })
    return (
        <Stack width="100%" height="100%" spacing={8} alignItems="center">
            <PageTitle title="إضافة طلب للتوصيل" />
            <Stack
                component="form"
                spacing={2}
                onSubmit={handleSubmit}
                width="100%"
                maxWidth="600px"
            >
                <TextField
                    name="name"
                    value={values.name}
                    label="الاسم"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                        width: '100%',
                    }}
                />
                <TextField
                    name="phone"
                    value={values.phone}
                    label="رقم الهاتف"
                    type="tel"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                        width: '100%',
                    }}
                />
                <TextField
                    name="address"
                    value={values.address}
                    label="عنوان التوصيل"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                        width: '100%',
                    }}
                />
                <TextField
                    name="details"
                    value={values.details}
                    label="تفاصيل الطلب"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                        width: '100%',
                    }}
                />
                <TextField
                    name="cost"
                    value={values.cost}
                    label="القيمة الواجب استلامها"
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                        width: '100%',
                    }}
                />

                {/* submit button */}
                <MainButton text="إنشاء" disabled={isSubmitting} />
                {errors && (
                    <Stack spacing={1}>
                        {errors.name && touched.name && (
                            <ErrorMessage errorMsg={errors.name} />
                        )}
                        {errors.phone && touched.phone && (
                            <ErrorMessage errorMsg={errors.phone} />
                        )}
                        {errors.address && touched.address && (
                            <ErrorMessage errorMsg={errors.address} />
                        )}
                        {errors.cost && touched.cost && (
                            <ErrorMessage errorMsg={errors.cost} />
                        )}
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}

export default AddOrderToDelivery
