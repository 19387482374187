import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material'
const options = [
    {
        sortTitle: 'الكل',
        sortKey: 'status:claimed|delivered|requested',
    },
    {
        sortTitle: 'قيد الانتظار',
        sortKey: 'status:requested',
    },
    {
        sortTitle: 'تم القبول',
        sortKey: 'status:claimed',
    },
    {
        sortTitle: 'تم التوصيل',
        sortKey: 'status:delivered',
    },
]

const OrderStatusFilterBox = ({
    filterValues,
    setFilterValues,
    filterOptions = options,
}) => {
    const handleChange = (e) => {
        setFilterValues((prev) => ({ ...prev, orderStatus: e.target.value }))
    }
    return (
        <Box>
            <FormControl fullWidth>
                <InputLabel>حالة الطلب</InputLabel>
                <Select
                    name="sort_box"
                    value={filterValues.orderStatus}
                    label="حالة الطلب"
                    onChange={handleChange}
                >
                    {filterOptions.map((option) => {
                        return (
                            <MenuItem
                                value={option.sortKey}
                                key={option.sortKey}
                            >
                                {option.sortTitle}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </Box>
    )
}

export default OrderStatusFilterBox
