import * as yup from 'yup'
// (http(s)?:\/\/)
const maxImgSizeMB = 20
const maxImgSizeBytes = maxImgSizeMB * 2 ** 20
const oneDayInMS = 1 * 24 * 60 * 60 * 1000
const urlValueRegex = new RegExp(/(http(s)?)/)

export const editDealSchema = yup.object().shape({
    img: yup
        .mixed()
        .nullable()
        .test(
            'fileSize',
            `حجم الصورة كبير جدا يجب الا يتعدى حجد الصورة ${maxImgSizeMB}MB`,
            (value) => imageEditSizeValidate(value)
        )
        .test('fileType', 'امتداد الصورة يجب ان يكون jpg , png', (value) =>
            imageEditTypeValidate(value)
        ),
    title: yup.string().required('عنوان العرض مطلوب'),
    price: yup
        .number()
        .typeError('السعر يجب ان يكون رقم حقيقي')
        .when('is_general', {
            is: false,
            then: (schema) => schema.required('السعر مطلوب'),
            otherwise: (schema) => schema,
        }),
    final_price: yup
        .number()
        .typeError('السعر يجب ان يكون رقم حقيقي')
        .when('is_general', {
            is: false,
            then: (schema) =>
                schema
                    .lessThan(
                        yup.ref('price'),
                        'السعر النهائي يجب ان يكون اثل من السعر الاصلي'
                    )
                    .required('السعر النهائي مطلوب'),
            otherwise: (schema) => schema,
        }),

    general_discount_price: yup
        .number()
        .typeError('السعر يجب ان يكون رقم حقيقي')
        .when('is_general', {
            is: true,
            then: (schema) => schema.required('سعر التخفيض العام مطلوب'),
            otherwise: (schema) => schema,
        }),
    is_general: yup.boolean(),
    description: yup.string().required(' التفاصيل مطلوبة'),
    is_disabled: yup.boolean(),
})

const imageEditSizeValidate = (value) => {
    if (value != null && typeof value != 'string') {
        return value.size <= maxImgSizeBytes
    }
    return true
}

const imageEditTypeValidate = (value) => {
    if (value != null && typeof value != 'string') {
        return ['image/jpg', 'image/png', 'image/jpeg'].includes(value.type)
    }
    return true
}
