import React, { useEffect, useState } from 'react'
// qrcode lib 
import QRCode from 'qrcode';
// mui 
import {Stack, Typography} from "@mui/material";
import { MdQrCode2 } from 'react-icons/md';

const DownloadVendorQrCode = ({link}) => {
    const [ResultQr, setResultQr] = useState("")
    useEffect(()=>{
        QRCode.toDataURL(
            link, 
            {width:800, margin:2, color:{dark:"#111", light:"#fff"}},
            (err, url) =>{
                if(err) return console.error(err);
                setResultQr(url)
            }
        )
    },[link])
  return (
    <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        border="1px solid #ddd"
        gap="5px"
        width="100%"
        component="a"
        href={ResultQr}
        target="_blank"
        download
        sx={{
            borderRadius: '5px',
            padding: '15px',
            textAlign: 'center',
        }}
    >
        <Typography variant="body2">تحميل QR code</Typography>
        <Typography variant="body2" fontSize="24px">
            <MdQrCode2 />
        </Typography>
    </Stack>
  )
}

export default DownloadVendorQrCode