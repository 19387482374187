import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// components
import { PageTitle, StatsCard, RingsLoader } from '../../../components'
import { OtpInfo } from '../../../Sections'
// mui
import { Stack, Box, Divider, Button } from '@mui/material'
// alerts
import { errorAlert } from '../../../utils/alerts'
import { Link } from 'react-router-dom'
// -------------------------------------------- page ----------------------------------------
const Home = () => {
    const [stats, setStats] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const userToken = useSelector((state) => state.userToken)
    useEffect(() => {
        setIsLoading(true)
        enhancedAxios(null, userToken)
            .get(`/admin/statistics`)
            .then((response) => {
                setStats(response.data)
                setIsLoading(false)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [])
    if (isLoading) {
        return <RingsLoader />
    }
    return (
        <Stack width="100%" height="100%" spacing={4} alignItems="center">
            <PageTitle title="الصفحة الرئيسية" />
            <Box
                display="flex"
                gap="20px"
                justifyContent="center"
                alignItems="flex-start"
                flexWrap="wrap"
            >
                <StatsCard text="عدد المستخدمين" number={stats.total_users} />
                <StatsCard text="عدد النشاطات" number={stats.total_vendors} />
                <StatsCard text="عدد المنشورات" number={stats.total_posts} />
            </Box>
            <Divider flexItem />
            <Stack gap="12px">
                <OtpInfo />
                <Button
                    component={Link}
                    to="/otp-history-messages"
                    variant="outlined"
                    sx={{ width: 'fit-content', margin: 'auto' }}
                >
                    سجل الرسائل المرسلة
                </Button>
            </Stack>
        </Stack>
    )
}

export default Home
