import { useState, useEffect } from 'react'
// react router
import { useParams, useNavigate } from 'react-router'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// formik
import { useFormik } from 'formik'
// schema
import { editRenewRequestSchema } from '../../../schemes/manageVendors/editRenewRequestSchema'
// components
import {
    PageTitle,
    MainButton,
    ErrorMessage,
    RingsLoader,
    DeleteButton,
} from '../../../components'
// mui
import { Stack, Autocomplete, TextField, Box, Button } from '@mui/material'
// alerts
import {
    errorAlert,
    successAlert,
    askForConfirmationAlert,
} from '../../../utils/alerts'

const subRequestStatus = [
    {
        title: 'قيد الانتظار',
        value: 'pending',
    },
    {
        title: 'مكتملة',
        value: 'completed',
    },
]
const monthsOptions = [
    {
        title: 'شهر',
        value: '1',
    },
    {
        title: 'شهرين',
        value: '2',
    },
    {
        title: '3 شهور',
        value: '3',
    },
    {
        title: '4 شهور',
        value: '4',
    },
    {
        title: '5 شهور',
        value: '5',
    },
    {
        title: '6 شهور',
        value: '6',
    },
    {
        title: '7 شهور',
        value: '7',
    },
    {
        title: '8 شهور',
        value: '8',
    },
    {
        title: '9 شهور',
        value: '9',
    },
    {
        title: '10 شهور',
        value: '10',
    },
    {
        title: '11 شهر',
        value: '11',
    },
    {
        title: '12 شهر',
        value: '12',
    },
]

const EditSubscriptionRequest = () => {
    const { id } = useParams()
    const [paymentMethods, setPaymentMethods] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const userToken = useSelector((state) => state.userToken)
    const navigate = useNavigate()
    // --------------------------------- delete request ------------------------------------------------------------------
    const deleteProcess = () => {
        enhancedAxios(null, userToken)
            .delete(`/admin/subscription-requests/${id}`)
            .then((response) => {
                successAlert('تم حذف الطلب')
                navigate('/manage-subscriptions-request')
            })
            .catch((error) => {
                errorAlert(error)
            })
    }
    const deleteRequest = () => {
        askForConfirmationAlert(
            'هل انت متاكد انك تريد حذف الطلب',
            deleteProcess
        )
    }
    // --------------------------------- submit ---------------------------------------------------------------------
    const onSubmit = (values) => {
        const formData = new FormData()
        for (let item in values) {
            formData.append(item, values[item])
        }
        enhancedAxios('multipart/form-data', userToken)
            .put(`/admin/subscription-requests/${id}`, formData)
            .then((response) => {
                successAlert('تم تعديل الطلب')
                setSubmitting(false)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }
    // --------------------------------- formik configration ----------------------------------------------------------
    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            months: '',
            cost: '',
            status: '',
            payment_method_id: '',
            note: '',
            vendor_id: '',
        },
        validationSchema: editRenewRequestSchema,
        onSubmit, //function we write above
    })
    // get payment methods from api
    useEffect(() => {
        enhancedAxios(null, userToken)
            .get(
                `/payment-methods?sorts=-created_at&page=1&paginate=1000%filters=is_disabled:eq:false`
            )
            .then((response) => {
                setPaymentMethods(response.data.data)
            })
            .catch((error) => {
                errorAlert(error)
            })
        // get request data
        enhancedAxios(null, userToken)
            .get(`/admin/subscription-requests/${id}`)
            .then((response) => {
                setValues((prevValues) => ({
                    ...prevValues,
                    months: response.data.months,
                    cost: response.data.cost,
                    status: response.data.status,
                    payment_method_id: response.data.payment_method.id,
                    note: response.data.note,
                    vendor_id: response.data.vendor.id,
                }))
                setIsLoading(false)
            })
    }, [])

    if (isLoading) {
        return <RingsLoader />
    }
    return (
        <Stack spacing={4} alignItems="center">
            <PageTitle title=" طلب تجديد الاشتراك" />
            <Stack
                component="form"
                onSubmit={handleSubmit}
                width="100%"
                maxWidth="700px"
                alignItems="center"
                spacing={2}
            >
                {/* months  */}
                <Autocomplete
                    disablePortal
                    options={monthsOptions}
                    name="months"
                    getOptionLabel={(option) => option.title ?? null}
                    isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                    }
                    onChange={(e, newValue) =>
                        setValues((prevValues) => ({
                            ...prevValues,
                            months: newValue.value,
                        }))
                    }
                    defaultValue={monthsOptions.find(
                        (opt) => opt.value == values.months
                    )}
                    value={monthsOptions.find(
                        (opt) => opt.value == values.months
                    )}
                    filterSelectedOptions
                    sx={{
                        width: '100%',
                    }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                label="مدة الاشتراك بالشهر"
                            />
                        )
                    }}
                />
                {/* cost  */}
                <TextField
                    label="قيمة الطلب"
                    name="cost"
                    value={values.cost}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                        width: '100%',
                    }}
                />
                {/* status  */}
                <Autocomplete
                    disablePortal
                    options={subRequestStatus}
                    name="status"
                    getOptionLabel={(option) => option.title ?? null}
                    isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                    }
                    onChange={(e, newValue) =>
                        setValues((prevValues) => ({
                            ...prevValues,
                            status: newValue.value,
                        }))
                    }
                    defaultValue={subRequestStatus.find(
                        (opt) => opt.value == values.status
                    )}
                    value={subRequestStatus.find(
                        (opt) => opt.value == values.status
                    )}
                    filterSelectedOptions
                    sx={{
                        width: '100%',
                    }}
                    renderInput={(params) => {
                        return <TextField {...params} label="حالة الطلب" />
                    }}
                />
                {/* payment method  */}
                <Autocomplete
                    disablePortal
                    options={paymentMethods}
                    name="payment_method_id"
                    getOptionLabel={(option) => option.name ?? ''}
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    onChange={(e, newValue) =>
                        setValues((prevValues) => ({
                            ...prevValues,
                            payment_method_id: newValue.id,
                        }))
                    }
                    defaultValue={paymentMethods.find(
                        (opt) => opt.id == values.payment_method_id
                    )}
                    value={paymentMethods.find(
                        (opt) => opt.id == values.payment_method_id
                    )}
                    filterSelectedOptions
                    sx={{
                        width: '100%',
                    }}
                    renderInput={(params) => {
                        return <TextField {...params} label="طريقة الدفع" />
                    }}
                />
                {/* note  */}
                <TextField
                    label="ملاحظات"
                    name="note"
                    value={values.note}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                        width: '100%',
                    }}
                />
                {/* submit and error section  */}
                <Box width="100%" sx={{ '& button': { width: '100%' } }}>
                    <MainButton text="تعديل الطلب" disabled={isSubmitting} />
                    {/* errors section  */}
                    {errors && (
                        <Stack spacing={1}>
                            {errors.months && touched.months && (
                                <ErrorMessage errorMsg={errors.months} />
                            )}
                            {errors.cost && touched.cost && (
                                <ErrorMessage errorMsg={errors.cost} />
                            )}
                            {errors.status && touched.status && (
                                <ErrorMessage errorMsg={errors.status} />
                            )}
                            {errors.payment_method_id &&
                                touched.payment_method_id && (
                                    <ErrorMessage
                                        errorMsg={errors.payment_method_id}
                                    />
                                )}
                        </Stack>
                    )}
                </Box>
                <Box display="flex" justifyContent="flex-end" width="100%">
                    <Button
                        size="large"
                        color="error"
                        variant="outlined"
                        onClick={deleteRequest}
                    >
                        {' '}
                        حذف الطلب{' '}
                    </Button>
                </Box>
            </Stack>
        </Stack>
    )
}

export default EditSubscriptionRequest
