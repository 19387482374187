import { useState } from 'react'
// router
import { useNavigate, useParams } from 'react-router'
// redux
import { useSelector } from 'react-redux'
// components
import {
    PageTitle,
    SelectCategory,
    MainButton,
    ErrorMessage,
} from '../../../components'
import { PreviewCustomButton } from '../../../Sections'
// mui
import {
    Stack,
    TextField,
    Box,
    FormControlLabel,
    Checkbox,
} from '@mui/material'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// formik
import { useFormik } from 'formik'
// shcema
import { addPostSchema } from '../../../schemes/manageVendors/addPostSchema'
// alerts
import { errorAlert, successAlert } from '../../../utils/alerts/index'
// utils
import { imageCompressor } from '../../../utils/comporessImage/imageCompressor'
const AddVendorPost = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const userToken = useSelector((state) => state.userToken)
    const [file, setFile] = useState(null)
    const [vendorCategory, setVendorCategory] = useState()
    const [resetPreview, setresetPreview] = useState()
    const [postUrl, setPostUrl] = useState('')
    const parent_id_of_select_categories =
        'cdecb40b-4cea-4347-a943-919c8ee95ebf'

    // ------------------------------   handle url changeing  to split it to [url_type, url_value] --------------------------
    const handleChangeUrl = (e) => {
        const urlValue = e.target.value
        setPostUrl(urlValue)
        const splittedUrlToTypeValue = urlValue.split('://')
        if (splittedUrlToTypeValue.length >= 2) {
            setValues((prevValues) => ({
                ...prevValues,
                url_type: splittedUrlToTypeValue[0],
                url_value: urlValue,
            }))
        }
    }
    // ------------------------------   handle file changed toget value and for preview --------------------------
    const handleFileChange = async (e) => {
        let x = e.target.files[0]
        const final = await imageCompressor(x)
        setFile(final)
        setValues((prevValues) => ({ ...prevValues, img: final }))
    }
    // ----------------------------------------------- submit form -----------------------------------------------
    const onSubmit = (values) => {
        const formData = new FormData()
        formData.append('vendor_id', id)
        for (let item in values) {
            formData.append(item, values[item])
        }

        enhancedAxios('multipart/form-data', userToken)
            .post(`admin/posts`, formData)
            .then((response) => {
                successAlert('تم اضافة المنشور')
                resetForm()
                setresetPreview(true)
                setSubmitting(false)
                setPostUrl('')
                navigate(`/edit-post/${response.data.id}`)
            })
            .catch((error) => {
                errorAlert(error)
                setSubmitting(false)
            })
    }
    // -----------------------------------------------  formik configraion ----------------------------------------------------------
    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            description: '',
            url_type: '',
            url_value: '',
            category_id: '',
            price: 0,
            img: null,
            is_disabled: false,
        },
        validationSchema: addPostSchema,
        onSubmit,
        handleFileChange,
        handleChangeUrl,
    })
    return (
        <Stack spacing={4} alignItems="center" width="100%">
            <PageTitle title="اضافة منشور" />
            <Stack
                component="form"
                onSubmit={handleSubmit}
                width="100%"
                maxWidth="700px"
                spacing={2}
                alignItems="flex-start"
            >
                <TextField
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="نص الاعلان"
                    multiline
                    minRows={1}
                    maxRows={5}
                    sx={{
                        width: '100%',
                    }}
                />
                <TextField
                    name="url"
                    type="url"
                    value={postUrl}
                    onChange={handleChangeUrl}
                    onBlur={handleBlur}
                    label="الرابط"
                    sx={{
                        width: '100%',
                    }}
                />
                <TextField
                    name="price"
                    type="number"
                    value={values.price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="السعر"
                    inputProps={{
                        step: 0.1,
                    }}
                    sx={{
                        width: '100%',
                    }}
                />
                <Box width="100%">
                    <SelectCategory
                        vendorCategory={vendorCategory}
                        setVendorCategory={setVendorCategory}
                        setValues={setValues}
                        parent_id_of_select_categories={
                            parent_id_of_select_categories
                        }
                    />
                </Box>
                <Box width="100%">
                    <PreviewCustomButton
                        file={file}
                        handleFileChange={handleFileChange}
                        resetPreview={resetPreview}
                        IPVariant="rounded"
                        IPObjectFit="contain"
                        IPWidth="100%"
                    />
                </Box>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={values.is_disabled}
                            onChange={handleChange}
                            name="is_disabled"
                        />
                    }
                    label="اخفاء "
                />
                <Box width="100%" sx={{ '& button': { width: '100%' } }}>
                    <MainButton text="انشاء الاعلان" disabled={isSubmitting} />
                    {/* errors section  */}
                    {errors && (
                        <Stack spacing={1}>
                            {errors.category_id && touched.category_id && (
                                <ErrorMessage errorMsg={errors.category_id} />
                            )}
                            {errors.description && touched.description && (
                                <ErrorMessage errorMsg={errors.description} />
                            )}
                            {errors.url_type && touched.url_type && (
                                <ErrorMessage errorMsg={errors.url_type} />
                            )}
                            {errors.url_value && touched.url_value && (
                                <ErrorMessage errorMsg={errors.url_value} />
                            )}
                            {errors.img && touched.img && (
                                <ErrorMessage errorMsg={errors.img} />
                            )}
                            {errors.price && touched.price && (
                                <ErrorMessage errorMsg={errors.price} />
                            )}
                        </Stack>
                    )}
                </Box>
            </Stack>
        </Stack>
    )
}

export default AddVendorPost
