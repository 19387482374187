import { TbTruckDelivery } from 'react-icons/tb'
import { BsBoxSeam } from 'react-icons/bs'
export const delivererServiceLinks = [
    {
        title: 'طلباتى',
        icon: <TbTruckDelivery />,
        path: '/deliverer/my-orders',
    },
    {
        title: 'طلبات متاحة',
        icon: <BsBoxSeam />,
        path: '/deliverer/available-orders',
    },
]
