import { useState, useRef } from 'react'
// mui
import { Stack, TextField, Box, Button } from '@mui/material'
import { UploadImagePreview } from '../../components'
// icons
import { FiUploadCloud } from 'react-icons/fi'
const PreviewCustomButton = ({
    file,
    handleFileChange,
    IPWidth,
    IPHeight,
    IPVariant,
    IPObjectFit,
    resetPreview,
}) => {
    const inputFileRef = useRef()
    return (
        <Stack spacing={2}>
            <UploadImagePreview
                file={file}
                IPWidth={IPWidth}
                IPVariant={IPVariant}
                IPObjectFit={IPObjectFit}
                IPHeight={IPHeight}
                resetPreview={resetPreview}
            />
            {/* default button we hidden  */}
            <input
                type="file"
                onChange={handleFileChange}
                name="img"
                ref={inputFileRef}
                hidden
                accept="image/png, image/gif, image/jpeg"
            />
            {/* custom file explore we create  */}
            <Box display="flex" justifyContent="center" width="100%">
                <Button
                    variant="contained"
                    color="success"
                    endIcon={<FiUploadCloud />}
                    onClick={() => inputFileRef.current.click()}
                >
                    تحميل صورة
                </Button>
            </Box>
        </Stack>
    )
}

export default PreviewCustomButton
