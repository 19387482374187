import { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
// mui component
import { Box, Stack, IconButton, Container } from '@mui/material'
// components  and seciton
import { Navbar } from '../../Sections'
// icons
import { FaAngleDoubleLeft } from 'react-icons/fa'
// links from navbar utils
import { adminLinks } from '../../utils/navbarUtils/adminLinks'

const AdminLayout = () => {
    const [navbarOpen, setNavbarOpen] = useState(false)
    useEffect(() => {
        if (window.innerWidth <= 900) {
            setNavbarOpen(false)
        } else {
            setNavbarOpen(true)
        }
    }, [])
    return (
        <Box display="flex" flexWrap="wrap">
            <Navbar
                navbarOpen={navbarOpen}
                setNavbarOpen={setNavbarOpen}
                links={adminLinks}
            />
            <Box
                sx={{ flex: '1', height: '100vh', overflow: 'auto' }}
                className="hide-scrollbar"
            >
                <Container>
                    <Stack spacing={2} py={4}>
                        <IconButton
                            onClick={() => setNavbarOpen(true)}
                            sx={{
                                width: '50px',
                                height: '50px',
                                display: { xs: 'flex', md: 'none' },
                            }}
                        >
                            <FaAngleDoubleLeft />
                        </IconButton>
                        <Outlet />
                    </Stack>
                </Container>
            </Box>
        </Box>
    )
}

export default AdminLayout
