import { useState, useEffect, useRef } from 'react'
// redux
import { useSelector } from 'react-redux'
// router
import { useParams } from 'react-router'
// formik
import { useFormik } from 'formik'
// schema
import { editPostSchema } from '../../../schemes/posts/editPostSchema'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// components
import {
    PageTitle,
    ErrorMessage,
    SelectCategory,
    MainButton,
    UploadImagePreview,
    SideTitle,
} from '../../../components'
import { PostGallery } from '../../../Sections'
//mui
import {
    Stack,
    TextField,
    Box,
    FormControlLabel,
    Checkbox,
    Avatar,
    Button,
    Divider,
} from '@mui/material'
// icons
import { FiUploadCloud } from 'react-icons/fi'
// alerts
import { errorAlert, successAlert } from '../../../utils/alerts'
// utils
import { imageCompressor } from '../../../utils/comporessImage/imageCompressor'
const EditPost = () => {
    const { id } = useParams()
    const userToken = useSelector((state) => state.userToken)
    const inputFileRef = useRef()
    const [file, setFile] = useState(null)
    const [vendorCategory, setVendorCategory] = useState()
    const [resetPreview, setresetPreview] = useState()
    const [postUrl, setPostUrl] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const parent_id_of_select_categories =
        'cdecb40b-4cea-4347-a943-919c8ee95ebf'

    // ------------------------------   handle file changed toget value and for preview --------------------------
    const handleFileChange = async (e) => {
        let x = e.target.files[0]
        const final = await imageCompressor(x)
        setFile(final)
        setValues((prevValues) => ({ ...prevValues, img: final }))
    }
    // ------------------------------   handle url changeing  to split it to [url_type, url_value] --------------------------
    const handleChangeUrl = (e) => {
        const urlValue = e.target.value
        setPostUrl(urlValue)
        const splittedUrlToTypeValue = urlValue.split('://')
        if (splittedUrlToTypeValue.length >= 2) {
            setValues((prevValues) => ({
                ...prevValues,
                url_type: splittedUrlToTypeValue[0],
                url_value: urlValue,
            }))
        }
    }
    // ----------------------------------------------- submit form -----------------------------------------------
    const onSubmit = (values) => {
        const formData = new FormData()
        for (let item in values) {
            formData.append(item, values[item])
        }
        enhancedAxios('multipart/form-data', userToken)
            .put(`/admin/posts/${id}`, formData)
            .then((response) => {
                successAlert('تم تعديل المنشور')
                setSubmitting(false)
            })
            .catch((error) => {
                errorAlert(error)
                setSubmitting(false)
            })
    }
    // -----------------------------------------------  formik configraion ----------------------------------------------------------
    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
    } = useFormik({
        initialValues: {
            description: '',
            url_type: '',
            url_value: '',
            category_id: '',
            img: null,
            is_disabled: false,
            price: 0,
        },
        validationSchema: editPostSchema,
        onSubmit,
        handleFileChange,
        handleChangeUrl,
    })
    // ------------------------------------------- get current category data -------------------------
    useEffect(() => {
        enhancedAxios(null, userToken)
            .get(`/admin/posts/${id}`)
            .then((response) => {
                setValues({
                    ...values,
                    description: response.data.description,
                    url_type: response.data.url_type,
                    url_value: response.data.url_value,
                    category_id: response.data.category.id,
                    img: response.data.img,
                    is_disabled: response.data.is_disabled,
                    price: response.data.price,
                })
                setPostUrl(response.data.url_value)
                setVendorCategory(response.data.category)
                setIsLoading(false)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [id])

    // ------------------------------------------- page -------------------------------------------
    return (
        <Stack width="100%" height="100%" spacing={8} alignItems="center">
            <PageTitle title="تعديل المنشور" />
            <Stack
                component="form"
                onSubmit={handleSubmit}
                width="100%"
                maxWidth="700px"
                spacing={2}
                alignItems="flex-start"
            >
                <TextField
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="نص الاعلان"
                    multiline
                    minRows={1}
                    maxRows={5}
                    sx={{
                        width: '100%',
                    }}
                />
                <TextField
                    name="url"
                    type="url"
                    value={postUrl}
                    onChange={handleChangeUrl}
                    onBlur={handleBlur}
                    label="الرابط"
                    sx={{
                        width: '100%',
                    }}
                />
                <TextField
                    name="price"
                    type="number"
                    value={values.price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="السعر"
                    inputProps={{
                        step: 0.1,
                    }}
                    sx={{
                        width: '100%',
                    }}
                />
                <Box width="100%">
                    <SelectCategory
                        vendorCategory={vendorCategory}
                        setVendorCategory={setVendorCategory}
                        setValues={setValues}
                        parent_id_of_select_categories={
                            parent_id_of_select_categories
                        }
                    />
                </Box>
                {/* preview and upload image  */}
                <Stack spacing={1} flex="1" width="100%" alignItems="center">
                    {/* img preview section  */}
                    {typeof values.img != 'string' && (
                        <UploadImagePreview
                            file={file}
                            IPWidth="100%"
                            IPHeight="200px"
                            IPVariant="rounded"
                            IPObjectFit="contain"
                        />
                    )}
                    {typeof values.img == 'string' && (
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            gap="10px"
                        >
                            <Avatar
                                src={values.img}
                                alt="uploaded Image"
                                variant="rounded"
                                sx={{
                                    width: '100%',
                                    height: '200px',
                                    '& .MuiAvatar-img': {
                                        objectFit: 'contain',
                                    },
                                }}
                            />
                        </Box>
                    )}
                    <input
                        type="file"
                        onChange={handleFileChange}
                        name="img"
                        ref={inputFileRef}
                        hidden
                        accept="image/png, image/gif, image/jpeg"
                    ></input>
                    <Box display="flex" justifyContent="center" width="100%">
                        <Button
                            variant="contained"
                            color="success"
                            endIcon={<FiUploadCloud />}
                            onClick={() => inputFileRef.current.click()}
                        >
                            تحميل صورة
                        </Button>
                    </Box>
                </Stack>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={values.is_disabled}
                            onChange={handleChange}
                            name="is_disabled"
                        />
                    }
                    label="اخفاء "
                />
                <Box width="100%" sx={{ '& button': { width: '100%' } }}>
                    <MainButton text="تعديل المنشور" disabled={isSubmitting} />
                    {/* errors section  */}
                    {errors && (
                        <Stack spacing={1}>
                            {errors.category_id && touched.category_id && (
                                <ErrorMessage errorMsg={errors.category_id} />
                            )}
                            {errors.description && touched.description && (
                                <ErrorMessage errorMsg={errors.description} />
                            )}
                            {errors.url_type && touched.url_type && (
                                <ErrorMessage errorMsg={errors.url_type} />
                            )}
                            {errors.url_value && touched.url_value && (
                                <ErrorMessage errorMsg={errors.url_value} />
                            )}
                            {errors.img && touched.img && (
                                <ErrorMessage errorMsg={errors.img} />
                            )}
                        </Stack>
                    )}
                </Box>
            </Stack>
            <Divider flexItem />
            <Stack width="100%" maxWidth="700px" alignItems="flex-start">
                <SideTitle title="صور معرض المنشور" />
                <PostGallery postId={id} apiUrl={`/admin/post-images/${id}`} />
            </Stack>
        </Stack>
    )
}

export default EditPost
