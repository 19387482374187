import { useState, useEffect, useRef } from 'react'
// redux
import { useSelector } from 'react-redux'
// router
import { useParams } from 'react-router'
// formik
import { useFormik } from 'formik'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// schema
import { addVendorSchema } from '../../../schemes/manageVendors/addVendorSchema'
// components
import {
    PageTitle,
    SelectCity,
    SelectCategory,
    UploadImagePreview,
    SideTitle,
    RingsLoader,
    MainButton,
    ErrorMessage,
} from '../../../components'
// mui
import {
    TextField,
    Stack,
    Box,
    Avatar,
    Button,
    FormControlLabel,
    Checkbox,
} from '@mui/material'
// icons
import { FiUploadCloud } from 'react-icons/fi'
// error
import { errorAlert, successAlert } from '../../../utils/alerts'
// utils
import { imageCompressor } from '../../../utils/comporessImage/imageCompressor'
const AddVendorActivity = () => {
    const { id } = useParams()
    const inputFileRef = useRef()
    const [vendorCity, setVendorCity] = useState(null)
    const [vendorCategory, setVendorCategory] = useState(null)
    const [file, setFile] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [resetPreview, setResetPreview] = useState(false)
    const userToken = useSelector((state) => state.userToken)
    const parent_id_of_select_categories =
        '8236cf2c-1eae-4192-90e8-8567dd6fd42a'
    // ------------------------------   handle file changed toget value and for preview --------------------------
    const handleFileChange = async (e) => {
        let x = e.target.files[0]
        const final = await imageCompressor(x)
        setFile(final)
        setValues((prevValues) => ({ ...prevValues, img: final }))
    }
    // --------------------------------- submit form  ----------------------------------------------------------
    const onSubmit = (values) => {
        const formData = new FormData()
        for (let item in values) {
            formData.append(item, values[item])
        }
        enhancedAxios('multi-part/form-data', userToken)
            .post(`/admin/vendors`, formData)
            .then((response) => {
                successAlert('تم اضافة النشاط')
                resetForm()
                setSubmitting(false)
                setResetPreview(true)
            })
            .catch((error) => {
                errorAlert(error)
                setSubmitting(false)
            })
    }
    // --------------------------------- formik configration ----------------------------------------------------------
    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            name: '',
            owner: '',
            city_id: '',
            category_id: '',
            description: '',
            address_info: '',
            hot_offers_limit: 0,
            img: null,
            email: '',
            password: '',
            password_confirmation: '',
            sub_expire_at: new Date(),
            is_disabled: false,
            is_featured: false,
            sort: 0,
        },
        validationSchema: addVendorSchema,
        onSubmit, //function we write above
        handleFileChange,
    })
    return (
        <Stack spacing={4} alignItems="center">
            <PageTitle title="اضافة مقدم نشاط" />
            <Box
                width="100%"
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                gap="20px"
                flexWrap="wrap"
                component="form"
                onSubmit={handleSubmit}
            >
                <Stack spacing={2} flex="1" minWidth="450px">
                    <TextField
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="اسم النشاط"
                    />
                    <TextField
                        name="owner"
                        value={values.owner}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="اسم صاحب النشاط"
                    />
                    <SelectCity
                        vendorCity={vendorCity}
                        setVendorCity={setVendorCity}
                        setValues={setValues}
                    />
                    <SelectCategory
                        vendorCategory={vendorCategory}
                        setVendorCategory={setVendorCategory}
                        setValues={setValues}
                        parent_id_of_select_categories={
                            parent_id_of_select_categories
                        }
                    />
                    <TextField
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="عن النشاط"
                        multiline
                        minRows={1}
                        maxRows={5}
                    />
                    <TextField
                        name="address_info"
                        value={values.address_info}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="عنوان المحل"
                        multiline
                        minRows={1}
                        maxRows={5}
                    />
                    <TextField
                        name="sub_expire_at"
                        type="date"
                        value={values.sub_expire_at}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="تاريخ انتهاء الاشتراك"
                    />
                    <TextField
                        name="hot_offers_limit"
                        type="number"
                        value={values.hot_offers_limit}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="اقصي عدد من العروض الساخنة"
                        sx={{
                            width: '100%',
                        }}
                    />
                    {/* is disabled  */}
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.is_disabled}
                                    onChange={handleChange}
                                    name="is_disabled"
                                />
                            }
                            label="اخفاء "
                        />
                    </Box>
                </Stack>
                <Stack spacing={2} flex="1" minWidth="450px">
                    {/* logo  */}
                    <Stack spacing={1} flex="1">
                        {/* img preview section  */}
                        {typeof values.img != 'string' && (
                            <UploadImagePreview
                                file={file}
                                IPWidth="100%"
                                IPHeight="195px"
                                IPVariant="rounded"
                                IPObjectFit="contain"
                                resetPreview={resetPreview}
                            />
                        )}
                        <input
                            type="file"
                            onChange={handleFileChange}
                            name="img"
                            ref={inputFileRef}
                            hidden
                            accept="image/png, image/gif, image/jpeg"
                        ></input>
                        <Box
                            display="flex"
                            justifyContent="center"
                            width="100%"
                        >
                            <Button
                                variant="contained"
                                color="success"
                                endIcon={<FiUploadCloud />}
                                onClick={() => inputFileRef.current.click()}
                            >
                                تحميل صورة
                            </Button>
                        </Box>
                    </Stack>
                    {/* admin data  */}
                    <Stack spacing={1} flex="1">
                        <SideTitle title="بيانات الحساب" />
                        <TextField
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="البريد الالكترونى"
                        />
                        <TextField
                            type="password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="الرقم السري"
                        />
                        <TextField
                            type="password"
                            name="password_confirmation"
                            value={values.password_confirmation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="تأكيد الرقم السري "
                        />
                        <MainButton text="تأكيد" disabled={isSubmitting} />
                        {/* errors section  */}
                        {errors && (
                            <Stack spacing={1}>
                                {errors.name && touched.name && (
                                    <ErrorMessage errorMsg={errors.name} />
                                )}
                                {errors.owner && touched.owner && (
                                    <ErrorMessage errorMsg={errors.owner} />
                                )}
                                {errors.city_id && touched.city_id && (
                                    <ErrorMessage errorMsg={errors.city_id} />
                                )}
                                {errors.category_id && touched.category_id && (
                                    <ErrorMessage
                                        errorMsg={errors.category_id}
                                    />
                                )}
                                {errors.description && touched.description && (
                                    <ErrorMessage
                                        errorMsg={errors.description}
                                    />
                                )}
                                {errors.address_info &&
                                    touched.address_info && (
                                        <ErrorMessage
                                            errorMsg={errors.address_info}
                                        />
                                    )}
                                {errors.hot_offers_limit &&
                                    touched.hot_offers_limit && (
                                        <ErrorMessage
                                            errorMsg={errors.hot_offers_limit}
                                        />
                                    )}
                                {errors.img && touched.img && (
                                    <ErrorMessage errorMsg={errors.img} />
                                )}
                                {errors.email && touched.email && (
                                    <ErrorMessage errorMsg={errors.email} />
                                )}
                                {errors.password && touched.password && (
                                    <ErrorMessage errorMsg={errors.password} />
                                )}
                                {errors.password_confirmation &&
                                    touched.password_confirmation && (
                                        <ErrorMessage
                                            errorMsg={
                                                errors.password_confirmation
                                            }
                                        />
                                    )}
                                {errors.sub_expire_at &&
                                    touched.sub_expire_at && (
                                        <ErrorMessage
                                            errorMsg={errors.sub_expire_at}
                                        />
                                    )}
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Box>
        </Stack>
    )
}

export default AddVendorActivity
