import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
// mui components
import { Box, Pagination } from '@mui/material'
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid'

const TableGrid = ({
    rows,
    cols,
    visibleRowsPerPage,
    totalRows,
    currentPage,
    setCurrentPage,
}) => {
    const [searchParams, setSearchParams] = useSearchParams()
    function CustomPagination() {
        const apiRef = useGridApiContext()
        const page = useGridSelector(apiRef, gridPageSelector)
        const pageCount = useGridSelector(apiRef, gridPageCountSelector)
        return (
            <Pagination
                color="primary"
                count={Math.ceil(totalRows / visibleRowsPerPage)}
                defaultPage={1}
                page={currentPage}
                value={currentPage}
                onChange={(event, value) => {
                    apiRef.current.setPage(value)
                    setCurrentPage(value)
                    searchParams.set('page', value)
                    setSearchParams(searchParams)
                }}
            />
        )
    }
    return (
        <Box className="datagrid" width="100%" sx={{ overflow: 'auto' }}>
            <DataGrid
                pagination
                slots={{
                    pagination: CustomPagination,
                }}
                autoHeight
                pageSize={5}
                rowsPerPageOptions={[5]}
                getRowId={(row) => row.id} // id for each row
                columns={cols} // columns fields
                rows={rows} //no of rows in data grid
                rowCount={totalRows} // how any items in api to calculate pages
                paginationMode="server"
                pageSizeOptions={[visibleRowsPerPage]}
                localeText={{
                    MuiTablePagination: {
                        labelDisplayedRows: ({ from, to, count }) =>
                            `${from} : ${to} من ${count}`,
                    },
                    columnMenuSortAsc: 'ترتيب تصاعدى',
                    columnMenuSortDesc: ' ترتيب تنازلى ',
                    columnMenuFilter: 'تصفية',
                    columnMenuHideColumn: 'اخفاء العمود ',
                    columnMenuShowColumns: 'اظهار الاعمدة',
                    columnMenuUnsort: 'كما هو',
                    columnMenuLabel: 'القائمة',
                }}
            />
        </Box>
    )
}

export default TableGrid
