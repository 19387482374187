import React from 'react'
import { Outlet } from 'react-router-dom'
// mui component
import { Box, Container } from '@mui/material'

const AuthLayout = () => {
    return (
        <Box sx={{ flex: '1' }}>
            <Container>
                <Box sx={{ flex: '1' }}>
                    <Outlet />
                </Box>
            </Container>
        </Box>
    )
}

export default AuthLayout
