import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// components
import { SideTitle, ErrorMessage, MainButton } from '../../components'
// mui
import { Stack, Box, TextField } from '@mui/material'
// formik
import { useFormik } from 'formik'
// schema
import { aboutAppSchema } from '../../schemes/settings/aboutAppSchema'
// alerts
import {
    errorAlert,
    successAlert,
    successToastAlert,
} from '../../utils/alerts/index'

const AboutApp = () => {
    const userToken = useSelector((state) => state.userToken)
    const [aboutDefault, setAboutDefault] = useState(null)
    // submit form
    const onSubmit = (values) => {
        const formData = new FormData()
        formData.append('value', values.about)
        enhancedAxios('multipart/form-data', userToken)
            .put(`admin/settings/${aboutDefault.id}`, formData)
            .then((response) => {
                successToastAlert('تم تعديل وصف التطبيق')
            })
            .catch((error) => errorAlert(error))
    }

    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
    } = useFormik({
        initialValues: {
            about: '',
        },
        validationSchema: aboutAppSchema,
        onSubmit, //function we write above
    })
    // get about field id and value
    useEffect(() => {
        enhancedAxios(null, userToken)
            .get(
                `/admin/settings?q=about&sorts=updated_at&page=1&paginate=9999`
            )
            .then((response) => {
                setAboutDefault(response.data.data[0])
                setValues((prevValues) => ({
                    ...prevValues,
                    about: response.data.data[0].value,
                }))
            })
    }, [])
    return (
        <Stack spacing={1}>
            <SideTitle title="حول التطبيق" />
            <Box
                width="100%"
                display="flex"
                justifyContent="flex-start"
                flexWrap="wrap"
                gap="20px"
                component="form"
                onSubmit={handleSubmit}
            >
                <TextField
                    type="text"
                    name="about"
                    value={values.about}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // label="تكلفة الاشتراك بالشهر"
                    multiline
                    minRows="1"
                    maxRows="10"
                    sx={{
                        flex: '1',
                        minWidth: '300px',
                    }}
                />
                <Box maxHeight="56px">
                    <MainButton text="تعديل الوصف" />
                </Box>
            </Box>
            {errors && (
                <Stack spacing={1}>
                    {errors.about && touched.about && (
                        <ErrorMessage errorMsg={errors.about} />
                    )}
                </Stack>
            )}
        </Stack>
    )
}

export default AboutApp
