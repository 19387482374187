import { HideSwitch, EditDetails } from '../../components'
// user table columns
export const manageVendorsCols = [
    {
        field: 'name',
        headerName: 'اسم النشاط',
        width: '200',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.name ?? '!! غير معروف !!',
    },

    {
        field: 'owner',
        headerName: 'صاحب النشاط',
        width: '180',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.owner ?? '!! غير معروف !!',
    },

    // { field: 'phome',        headerName: 'الهاتف',   width: '160',   sortable:false,  filterable:false,
    //     valueGetter: (params) => params.row?.admin?.phone ?? '!! غير معروف !!'},

    {
        field: 'category',
        headerName: 'الفئة',
        width: '160',
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.category?.name ?? '!! غير معروف !!',
    },

    {
        field: 'city',
        headerName: 'المدينة',
        width: '160',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.city?.name ?? '!! غير معروف !!',
    },

    {
        field: 'show',
        headerName: 'مشاهدة',
        width: '160',
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <EditDetails to={`/edit-vendor-activity/${params.row.id}`} />
        ),
    },

    {
        field: 'is_disabled',
        headerName: 'تعطيل',
        width: '160',
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <HideSwitch
                is_disabled={params.row.is_disabled}
                id={params.row.id}
                who="vendors"
            />
        ),
    },
]
