import React, { useState } from 'react'
// redux
import { useSelector } from 'react-redux'
// axios
import EnhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// mui
import {
    Stack,
    TextField,
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material'
// components
import { MainButton } from '../../components'
// alerts
import {
    errorAlert,
    successAlert,
    askForConfirmationAlert,
} from '../../utils/alerts'

const VendorSocialLink = ({
    linkId,
    linkKey,
    linkValue,
    possibleLinksOptions,
    setDataChanged,
}) => {
    const userToken = useSelector((state) => state.userToken)
    const userData = useSelector((state) => state.userData)
    const userRole = userData.roles.includes('admin')
        ? 'admin'
        : userData.roles[0]
    const [newKey, setNewKey] = useState(linkKey)
    const [newValue, setNewValue] = useState(linkValue)
    const deletingProcess = () => {
        EnhancedAxios(null, userToken)
            .delete(`/${userRole}/contactable/${linkId}`)
            .then((response) => {
                successAlert('تم حذف الرابط بنجاح')
                setDataChanged((prev) => !prev)
            })
            .catch((error) => errorAlert(error))
    }
    const deleteLink = () => {
        askForConfirmationAlert(
            'هل انت متأكد انك تريد حذف الرابط?',
            deletingProcess
        )
    }
    const editLink = () => {
        if (newValue === '') {
            errorAlert('يجب اضافة رابط')
        } else {
            const formData = new FormData()
            formData.append('key', newKey)
            formData.append('value', newValue)
            EnhancedAxios('multipart/form-data', userToken)
                .put(`/${userRole}/contactable/${linkId}`, formData)
                .then((response) => {
                    successAlert('تم تعديل الرابط بنجاح.')
                })
                .catch((error) => errorAlert(error))
        }
    }
    return (
        <Box
            display="flex"
            justifyContent="flex-start"
            gap="20px"
            flexWrap="wrap"
        >
            <Box minWidth="90px" height="100%">
                <FormControl fullWidth>
                    <InputLabel id="select-label">خاصة</InputLabel>
                    <Select
                        labelId="select-label"
                        id="select"
                        value={newKey}
                        label="خاصة"
                        onChange={(e) => setNewKey(e.target.value)}
                        sx={{
                            '& .MuiSelect-select': {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            },
                        }}
                    >
                        {possibleLinksOptions.map((opt) => (
                            <MenuItem
                                key={opt.key}
                                value={opt.key}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {opt.icon}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <TextField
                name="value"
                value={newValue}
                onChange={(e) => setNewValue(e.target.value)}
                sx={{ flex: '1', minWidth: '270px' }}
                required
            />
            <Button
                variant="contained"
                sx={{ color: 'white' }}
                onClick={editLink}
            >
                تعديل
            </Button>
            <Button variant="outlined" color="error" onClick={deleteLink}>
                حذف
            </Button>
        </Box>
    )
}

export default VendorSocialLink
