import { useState, useEffect } from 'react'
// axios
import EnhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// mui
import { Stack, Box, Button, Typography } from '@mui/material'
// component  and sections
import { PageTitle } from '../../components'
import { LoginForm } from '../../Sections'
// icons
import { BsWhatsapp } from 'react-icons/bs'
const Login = () => {
    const [whatsApp, setWhatsApp] = useState('')
    useEffect(() => {
        EnhancedAxios(null, null)
            .get(`/settings?q=app_whatsapp_url`)
            .then((response) => {
                setWhatsApp(response.data.data[0].value)
            })
    }, [])
    return (
        <Stack
            width="100%"
            maxWidth="800px"
            height="100vh"
            className="hide-scrollbar"
            spacing={8}
            alignItems="center"
            m="auto"
            py={8}
        >
            <PageTitle title="تسجيل الدخول" />
            <LoginForm />
            <Box width="100%" display="flex" justifyContent="flex-end">
                <Button
                    component="a"
                    href={whatsApp}
                    variant="outlined"
                    color="success"
                    sx={{
                        padding: '8px 20px',
                        height: '50px',
                        borderRadius: '25px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    <Typography varaint="h5" lineHeight="1">
                        <BsWhatsapp />
                    </Typography>
                    <Typography varaint="body1">
                        لفتح محل جديد تواصل واتساب
                    </Typography>
                </Button>
            </Box>
        </Stack>
    )
}

export default Login
