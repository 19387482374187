import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// mui
import { Stack, Box, Typography } from '@mui/material'
// components
import { PageTitle, RingsLoader, SearchBox, SortBox } from '../../../components'
import { TableGrid } from '../../../Sections'
// grid cols
import { requestsCols } from '../../../utils/tableGridUtils/requestsCols'
// alerts
import { errorAlert, successAlert } from '../../../utils/alerts'
// sortin request options
const requestsSortingOptions = [
    {
        sortTitle: 'تاريخ الانشاء من الاحدث الى الاقدم',
        sortKey: '-created_at',
    },
    {
        sortTitle: 'تاريخ الانشاء من الاقدم الى الاحدث',
        sortKey: 'created_at',
    },
    {
        sortTitle: 'تاريخ التحديث من الاحدث الى الاقدم',
        sortKey: '-updated_at',
    },
    {
        sortTitle: 'تاريخ التحديث من الاقدم الى الاحدث',
        sortKey: 'updated_at',
    },
    {
        sortTitle: ' التكلفة من الاعلى الى الاقل',
        sortKey: '-cost',
    },
    {
        sortTitle: ' التكلفة من الاقل الى الاعلى',
        sortKey: 'cost',
    },
    {
        sortTitle: 'طريقة الدفع',
        sortKey: 'payment_method',
    },
    {
        sortTitle: 'اسم النشاط',
        sortKey: 'vendor_id',
    },
]
const ManageSubscriptionsRequests = () => {
    // ------------------------------------ states ------------------------------------
    const [requests, setRequests] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [visibleRowsPerPage, setVisibleRowsPerPage] = useState(16)
    const [currentPage, setCurrentPage] = useState(1)
    const [searchValue, setSearchValue] = useState('')
    const [sortValue, setSortValue] = useState('-created_at')
    const [totalRows, setTotalRows] = useState(5)
    const userToken = useSelector((state) => state.userToken)
    // ------------------------------------ get requests ------------------------------------
    useEffect(() => {
        setIsLoading(true)
        enhancedAxios(null, userToken)
            .get(
                `/admin/subscription-requests?q=${searchValue}&sorts=${sortValue}&page=${currentPage}&paginate=${visibleRowsPerPage}`
            )
            .then((response) => {
                setRequests(response.data.data)
                setTotalRows(response.data.meta.total)
                setIsLoading(false)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [searchValue, sortValue, currentPage, visibleRowsPerPage])
    return (
        <Stack width="100%" height="100%" spacing={4} alignItems="center">
            {/* page title  */}
            <PageTitle title="طلبات الاشتراك" />
            {/* sorting filter sections  */}
            <Box
                width="100%"
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
                sx={{
                    '& .MuiBox-root': {
                        flex: '1',
                        minWidth: '240px',
                        // maxWidth:'480px',
                    },
                }}
            >
                <SearchBox
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
                <SortBox
                    sortValue={sortValue}
                    setSortValue={setSortValue}
                    sortingOptions={requestsSortingOptions}
                />
            </Box>
            {/* table data grid  */}
            <>
                {isLoading ? (
                    <RingsLoader />
                ) : requests.length === 0 && searchValue === '' ? (
                    <Typography variant="body1">
                        لا يوجد عناصر لعرضها
                    </Typography>
                ) : requests.length === 0 && searchValue !== '' ? (
                    <Typography variant="body1">
                        {' '}
                        لا يوجد عناصر لنتيجة البحث{' '}
                    </Typography>
                ) : (
                    <TableGrid
                        rows={requests}
                        cols={requestsCols}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        totalRows={totalRows}
                        visibleRowsPerPage={visibleRowsPerPage}
                    />
                )}
            </>
        </Stack>
    )
}

export default ManageSubscriptionsRequests
