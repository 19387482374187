import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// react router
import { Routes, Route, useNavigate, useLocation } from 'react-router'
// jwt decoder library
import jwt_decode from 'jwt-decode'
// redux actions
import { removeTokenFromLocal } from './redux/actions/userActions'
// mui
import { Box } from '@mui/material'
// alerts
import { endSessionAlert, errorAlert, warningAlert } from './utils/alerts'
// components
import {
    AdminLayout,
    AuthLayout,
    ServiceOwnerLayout,
    ProtectedRoutes,
    RoleBasedAccess,
    DelivererServiceLayout,
} from './components'
//pages
import {
    Login,
    // admin pages
    Home,
    ManageUsers,
    AddUser,
    EditUserDetails,
    ManageCategoriesActivities,
    AddCategoryActivity,
    EditCategoryActivity,
    ManageCategoriesPosts,
    AddCategoryPost,
    EditCategoryPost,
    ManageCities,
    AddCity,
    EditCity,
    ManageVendorActivities,
    EditVendorActivity,
    AddVendorActivity,
    AddVendorPost,
    RenewVendorSub,
    ManagePosts,
    EditPost,
    ManageSubscriptionsRequests,
    EditSubscriptionRequest,
    ManagePaymentMethods,
    AddPaymentMethod,
    EditPaymentMethod,
    ManageBanners,
    AddBanner,
    EditBanner,
    PushNotification,
    ViewAllNotification,
    ManageReports,
    ManageCustomerRequests,
    EditCustomerRequest,
    Settings,
    OtpHistoryMessages,
    // vendor pages
    VendorHome,
    VendorData,
    VendorPosts,
    VendorAddPost,
    VendorEditPost,
    VendorRequests,
    VendorEditRequest,
    VendorAddRequest,
    OrderDeliverers,
    AddOrderDeliverer,
    EditOrderDeliverer,
    ManageOrderDeliveryInfo,
    DeliveryOrders,
    AddOrderToDelivery,
    EditOrderToDelivery,
    DelivererOrders,
    AvailableOrders,
    VendorHotDeals,
    VendorAddHotDeal,
    VendorEditHotDeal,
    HotDeals,
    AddHotDeal,
    EditHotDeal,
} from './pages'

function App() {
    const userToken = useSelector((state) => state.userToken)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    useEffect(() => {
        const interval = setInterval(() => {
            if (userToken !== null) {
                const decodedToken = jwt_decode(userToken)
                if (decodedToken.exp * 1000 <= Date.now()) {
                    dispatch(removeTokenFromLocal())
                    navigate('/login')
                }
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [userToken])

    return (
        <Box>
            <Routes>
                <Route element={<ProtectedRoutes />}>
                    <Route element={<AdminLayout />}>
                        <Route
                            element={
                                <RoleBasedAccess allowedRoles={['admin']} />
                            }
                        >
                            {/* home  */}
                            <Route path="/" element={<Home />} exact />
                            {/* users pages  */}
                            <Route
                                path="/manage-users"
                                element={<ManageUsers />}
                            />
                            <Route
                                path="/edit-user/:id"
                                element={<EditUserDetails />}
                            />
                            <Route
                                path="/edit-order-deliverer/:id"
                                element={<EditOrderDeliverer />}
                            />
                            {/* deliverers pages  */}
                            <Route
                                path="/manage-order-deliverers"
                                element={<OrderDeliverers />}
                            />
                            <Route
                                path="/add-order-deliverer"
                                element={<AddOrderDeliverer />}
                            />
                            {/* categories  */}
                            <Route
                                path="/manage-category-activities"
                                element={<ManageCategoriesActivities />}
                            />
                            <Route
                                path="/add-category-activity"
                                element={<AddCategoryActivity />}
                            />
                            <Route
                                path="/edit-category-activity/:id"
                                element={<EditCategoryActivity />}
                            />
                            <Route
                                path="/manage-category-posts"
                                element={<ManageCategoriesPosts />}
                            />
                            <Route
                                path="/add-category-post"
                                element={<AddCategoryPost />}
                            />
                            <Route
                                path="/edit-category-post/:id"
                                element={<EditCategoryPost />}
                            />
                            {/* cities  */}
                            <Route
                                path="/manage-cities"
                                element={<ManageCities />}
                            />
                            <Route path="/add-city" element={<AddCity />} />
                            <Route
                                path="/edit-city/:id"
                                element={<EditCity />}
                            />
                            {/* vendors activities */}
                            <Route
                                path="/manage-vendors-activities"
                                element={<ManageVendorActivities />}
                            />
                            <Route
                                path="/edit-vendor-activity/:id"
                                element={<EditVendorActivity />}
                            />
                            <Route
                                path="/add-vendor-activity"
                                element={<AddVendorActivity />}
                            />
                            <Route
                                path="/add-vendor-post/:id"
                                element={<AddVendorPost />}
                            />
                            <Route
                                path="/renew-vendor-sub/:id"
                                element={<RenewVendorSub />}
                            />
                            {/* posts */}
                            <Route
                                path="/manage-posts"
                                element={<ManagePosts />}
                            />
                            <Route
                                path="/edit-post/:id"
                                element={<EditPost />}
                            />
                            {/* sub requests  */}
                            <Route
                                path="/manage-subscriptions-request"
                                element={<ManageSubscriptionsRequests />}
                            />
                            <Route
                                path="/edit-subscribtion-request/:id"
                                element={<EditSubscriptionRequest />}
                            />
                            {/* payment methods */}
                            <Route
                                path="/manage-payment-methods"
                                element={<ManagePaymentMethods />}
                            />
                            <Route
                                path="/add-payment-method"
                                element={<AddPaymentMethod />}
                            />
                            <Route
                                path="/edit-payment-method/:id"
                                element={<EditPaymentMethod />}
                            />
                            {/* manage banners */}
                            <Route
                                path="/manage-banners"
                                element={<ManageBanners />}
                            />
                            <Route path="/add-banner" element={<AddBanner />} />
                            <Route
                                path="/edit-banner/:id"
                                element={<EditBanner />}
                            />
                            {/* notification */}
                            <Route
                                path="/push-notification"
                                element={<PushNotification />}
                            />
                            <Route
                                path="/view-notifications"
                                element={<ViewAllNotification />}
                            />
                            {/* reports */}
                            <Route
                                path="/manage-reports"
                                element={<ManageReports />}
                            />
                            {/* custeomer requests       */}
                            <Route
                                path="/manage-customer-requests"
                                element={<ManageCustomerRequests />}
                            />
                            <Route
                                path="/edit-customer-request/:id"
                                element={<EditCustomerRequest />}
                            />
                            {/* order dlievery info  */}
                            <Route
                                path="/manage-order-delivery-info"
                                element={<ManageOrderDeliveryInfo />}
                            />
                            {/* settings  */}
                            <Route path="/settings" element={<Settings />} />
                            {/* otp  */}
                            <Route
                                path="/otp-history-messages"
                                element={<OtpHistoryMessages />}
                            />
                            {/* hot deals  */}
                            <Route path="/hot-deals" element={<HotDeals />} />
                            <Route
                                path="/add-hot-deal/:vendor_id"
                                element={<AddHotDeal />}
                            />
                            <Route
                                path="/edit-hot-deal/:id"
                                element={<EditHotDeal />}
                            />
                        </Route>
                    </Route>
                    <Route element={<ServiceOwnerLayout />}>
                        <Route
                            element={
                                <RoleBasedAccess allowedRoles={['vendor']} />
                            }
                        >
                            <Route path="/vendor" element={<VendorHome />} />
                            {/* <Route
                                path="/vendor-profile"
                                element={<VendorData />}
                            /> */}
                            {/* posts  */}
                            <Route
                                path="/manage-vendor-posts"
                                element={<VendorPosts />}
                            />
                            <Route
                                path="/vendor-add-post"
                                element={<VendorAddPost />}
                            />
                            <Route
                                path="/vendor-edit-post/:id"
                                element={<VendorEditPost />}
                            />
                            {/* subscription requests  */}
                            <Route
                                path="/vendor-subscription-requests"
                                element={<VendorRequests />}
                            />
                            <Route
                                path="/vendor-edit-subscription-request/:id"
                                element={<VendorEditRequest />}
                            />
                            <Route
                                path="/vendor-add-request"
                                element={<VendorAddRequest />}
                            />
                            {/* delivery orders  */}
                            <Route
                                path="/manage-delivery-orders"
                                element={<DeliveryOrders />}
                            />
                            <Route
                                path="/add-order-to-delivery"
                                element={<AddOrderToDelivery />}
                            />
                            <Route
                                path="/edit-order-to-delivery/:id"
                                element={<EditOrderToDelivery />}
                            />
                            {/* hot deals  */}
                            <Route
                                path="/vendor-hot-deals"
                                element={<VendorHotDeals />}
                            />
                            <Route
                                path="/vendor-add-hot-deal"
                                element={<VendorAddHotDeal />}
                            />
                            <Route
                                path="/vendor-edit-hot-deal/:id"
                                element={<VendorEditHotDeal />}
                            />
                        </Route>
                    </Route>
                    <Route element={<DelivererServiceLayout />}>
                        <Route
                            element={
                                <RoleBasedAccess allowedRoles={['delivery']} />
                            }
                        >
                            <Route
                                path="/deliverer/my-orders"
                                element={<DelivererOrders />}
                            />
                            <Route
                                path="/deliverer/available-orders"
                                element={<AvailableOrders />}
                            />
                        </Route>
                    </Route>
                </Route>
                <Route element={<AuthLayout />}>
                    <Route path="/login" element={<Login />} />
                </Route>
            </Routes>
        </Box>
    )
}

export default App
