import { Link } from 'react-router-dom'
import { Button } from '@mui/material'

const LinkButton = ({ to, text, size = 'large' }) => {
    return (
        <Button
            size={size}
            variant="outlined"
            color="primary"
            component={Link}
            to={to}
            sx={{ width: 'fit-content', boxShadow: '0' }}
        >
            {text}
        </Button>
    )
}

export default LinkButton
