import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
//axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// mui
import { Stack, Box, Avatar, Typography, Switch, Button } from '@mui/material'
// moment
import Moment from 'react-moment'
// components
import { LinkButton } from '../../components'
// alerts
import {
    errorAlert,
    successAlert,
    successToastAlert,
    askForConfirmationAlert,
} from '../../utils/alerts'

const PostCard = ({
    id,
    img,
    categoryName,
    createdAt,
    description,
    vendorName,
    url,
    price,
    setDataChanged,
    adminOrVendor = 'admin',
    editPagePath,
}) => {
    const [checked, setChecked] = useState(false)
    const userToken = useSelector((state) => state.userToken)
    // get value of disabled from the mainPart = [categoris , cities]
    useEffect(() => {
        enhancedAxios(null, userToken)
            .get(`/${adminOrVendor}/posts/${id}`)
            .then((response) => {
                setChecked(!response.data.is_disabled)
            })
    }, [])
    // change is_disbaled value
    const handleChange = (e) => {
        const formData = new FormData()
        formData.append('is_disabled', !e.target.checked)

        enhancedAxios('multipart/form-data', userToken)
            .put(`/${adminOrVendor}/posts/${id}`, formData)
            .then((response) => {
                const alertMsg =
                    response.data.is_disabled === true
                        ? 'تم التعطيل'
                        : 'تم التفعيل'
                successToastAlert(alertMsg)
                setChecked(!response.data.is_disabled)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }
    // handle Delete
    const deleteProcess = () => {
        enhancedAxios(null, userToken)
            .delete(`/${adminOrVendor}/posts/${id}`)
            .then((response) => {
                successToastAlert('تم حذف المنشور')
                setDataChanged((prev) => !prev)
            })
            .catch((error) => errorAlert(error))
    }
    const handleDelete = () => {
        askForConfirmationAlert(
            'هل انت متاكد انك تريد حذف المنشور',
            deleteProcess
        )
    }
    return (
        <Box
            display="flex"
            justifyContent="flex-start"
            gap="20px"
            flexWrap="wrap"
            sx={{
                boxShadow: 1,
                padding: '10px',
                minWidth: { xs: '280px', sm: '400px' },
                // minWidth:'400px',
                flex: '1',
                opacity: !checked ? '.6' : '1.0',
            }}
        >
            <Stack spacing={1}>
                <Avatar
                    src={img}
                    variant="rounded"
                    sx={{
                        width: '150px',
                        height: '100%',
                        minHeight: '209px',
                        boxShadow: 1,
                        '& img': { objectFit: 'cover' },
                    }}
                />
            </Stack>
            <Stack spacing={1} minWidth="220px" flex="1">
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="wrap"
                >
                    <Typography variant="subtitle1" color="primary">
                        <a href={url} target="_blank">
                            الرابط
                        </a>
                    </Typography>
                    <Typography variant="subtitle1" color="textColor.secColor">
                        <Moment format="YYYY/MM/DD">{createdAt}</Moment>
                    </Typography>
                </Box>

                <Typography variant="subtitle1">
                    {description.length > 80
                        ? `${description.slice(0, 80)}...`
                        : `${description}`}
                </Typography>
                <Typography variant="subtitle1">
                    الفئة : {categoryName}{' '}
                </Typography>
                <Typography variant="subtitle1">
                    السعر : {price}
                    {' د.ل'}
                </Typography>
                <Typography variant="subtitle1">
                    صاحب النشاط :{' '}
                    {vendorName.length > 25
                        ? `${vendorName.slice(0, 25)}...`
                        : `${vendorName}`}
                </Typography>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="wrap"
                >
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        flexWrap="wrap"
                        gap="10px"
                    >
                        <LinkButton
                            to={editPagePath}
                            text="تعديل المنشور"
                            size="small"
                        />
                        <Button color="error" onClick={handleDelete}>
                            حذف
                        </Button>
                    </Box>
                    <Switch
                        size="small"
                        checked={checked}
                        onChange={handleChange}
                    />
                </Box>
            </Stack>
        </Box>
    )
}

export default PostCard
