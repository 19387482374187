import React from 'react'
import { Typography } from '@mui/material'

const ErrorMessage = ({ errorMsg }) => {
    return (
        <Typography variant="body2" color="error">
            - {errorMsg}
        </Typography>
    )
}

export default ErrorMessage
