import { Navigate, Outlet } from 'react-router-dom'
// redux
import { useSelector } from 'react-redux'

const RoleBasedAccess = ({ allowedRoles }) => {
    const userData = useSelector((state) => state.userData)
    const meRoles = allowedRoles.find((role) => userData.roles.includes(role))
    return userData && meRoles ? (
        <Outlet />
    ) : (
        <Navigate
            to={
                userData.roles.includes('admin')
                    ? '/'
                    : userData.roles.includes('vendor')
                    ? '/vendor'
                    : '/login'
            }
        />
    )
}

export default RoleBasedAccess
