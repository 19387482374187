import { useParams } from 'react-router'
// components
import { PageTitle, LinkButton, SideTitle } from '../../../components'
import {
    EditVendorForm,
    VendorStats,
    VendorSocialLinks,
    VendorSlides,
} from '../../../Sections'
// mui
import { Stack, Box, Divider } from '@mui/material'

const EditVendorActivity = () => {
    const { id } = useParams()
    return (
        <Stack spacing={4} alignItems="center">
            <PageTitle title="تعديل تفاصيل مقدم النشاط" />
            <VendorStats />
            <EditVendorForm ApiUrl={`/admin/vendors/${id}`} />
            <Divider component="div" flexItem />
            <VendorSocialLinks ApiUrl={`/admin/vendors/${id}`} />
            <Divider component="div" flexItem />
            {/* <VendorSlides ApiUrl={`/admin/vendors/${id}`} />
            <Divider component="div" flexItem /> */}
            <Box
                width="100%"
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                gap="20px"
            >
                <LinkButton to={`/add-vendor-post/${id}`} text="اضافة منشور" />
                <LinkButton to={`/add-hot-deal/${id}`} text="اضافة عرض ساخن" />
                <LinkButton
                    to={`/renew-vendor-sub/${id}`}
                    text="تجديد الاشتراك"
                />
            </Box>
        </Stack>
    )
}

export default EditVendorActivity
