import { Stack, Typography } from '@mui/material'
import React from 'react'
import { IoPhonePortraitSharp } from 'react-icons/io5'
import { MdOutlineMessage } from 'react-icons/md'

const OtpMessageCard = ({ msg }) => {
    return (
        <Stack boxShadow={1} width="100%" p={2} borderRadius={1}>
            <Typography
                variant="subtitle2"
                display="flex"
                alignItems="center"
                gap="2px"
            >
                <IoPhonePortraitSharp />
                {msg?.body?.phone}
            </Typography>
            <Typography
                variant="subtitle1"
                display="flex"
                alignItems="center"
                gap="2px"
            >
                <MdOutlineMessage />
                {msg?.body?.content}
            </Typography>
        </Stack>
    )
}

export default OtpMessageCard
