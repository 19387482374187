import { useState, useEffect } from 'react'
// react redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// mui
import { Stack, Box, Typography } from '@mui/material'
// components
import { PageTitle, RingsLoader, SearchBox, SortBox } from '../../../components'
import { TableGrid } from '../../../Sections'
// cols
import { customerRequestsCols } from '../../../utils/tableGridUtils/customerRequestsCols'
// alerts
import { errorAlert } from '../../../utils/alerts'

const ManageCustomerRequests = () => {
    const [requests, setRequests] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [itemsPerPage, setItemsPerPage] = useState(16)
    const [currentPage, setCurrentPage] = useState(1)
    const [searchValue, setSearchValue] = useState('')
    const [sortValue, setSortValue] = useState('-created_at')
    const [totalItems, setTotalItems] = useState(5)
    const userToken = useSelector((state) => state.userToken)
    // get all customer requests
    useEffect(() => {
        setIsLoading(true)
        enhancedAxios(null, userToken)
            .get(
                `/admin/customer-requests?q=${searchValue}&sorts=${sortValue}&page=${currentPage}&paginate=${itemsPerPage}`
            )
            .then((response) => {
                setRequests(response.data.data)
                setTotalItems(response.data.meta.total)
                setIsLoading(false)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [sortValue, currentPage, searchValue])
    return (
        <Stack width="100%" height="100%" spacing={4} alignItems="center">
            <PageTitle title="إدارة  طلبات المستخدمين" />
            <Box
                width="100%"
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
                sx={{
                    '& .MuiBox-root': {
                        flex: '1',
                        minWidth: '240px',
                    },
                }}
            >
                <SearchBox
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
                <SortBox sortValue={sortValue} setSortValue={setSortValue} />
            </Box>
            {isLoading ? (
                <RingsLoader />
            ) : requests.length === 0 && searchValue === '' ? (
                <Typography variant="body1">لا يوجد عناصر لعرضها</Typography>
            ) : requests.length === 0 && searchValue !== '' ? (
                <Typography variant="body1">
                    {' '}
                    لا يوجد عناصر لنتيجة البحث{' '}
                </Typography>
            ) : (
                <TableGrid
                    rows={requests}
                    cols={customerRequestsCols}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    visibleRowsPerPage={itemsPerPage}
                    totalRows={totalItems}
                />
            )}
        </Stack>
    )
}

export default ManageCustomerRequests
