import { useState } from 'react'
// react redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// mui
import { Stack, TextField, FormControlLabel, Checkbox } from '@mui/material'
// components
import { PageTitle, MainButton, ErrorMessage } from '../../../components'
import { PreviewCustomButton } from '../../../Sections'
// formik
import { useFormik } from 'formik'
// schema
import { addCategorySchema } from '../../../schemes/categories/addCategorySchema'
// alerts
import { errorAlert, successAlert } from '../../../utils/alerts/index'
// utils
import { imageCompressor } from '../../../utils/comporessImage/imageCompressor'
// --------------------------------------------- page -----------------------------------
const AddCategoryActivity = () => {
    const [file, setFile] = useState()
    const [resetPreview, setResetPreview] = useState(false)
    const userToken = useSelector((state) => state.userToken)
    // --------------------------------------- handle file change to pass file to preview component ------------------------------
    const handleFileChange = async (e) => {
        let x = e.target.files[0]
        const final = await imageCompressor(x)
        setFile(final)
        setValues((prevValues) => ({ ...prevValues, img: final }))
    }
    // ----------------------------------------- submit funciton ----------------------------------------------
    const onSubmit = async (values) => {
        const formData = new FormData()
        for (let i in values) {
            formData.append(i, values[i])
        }
        setSubmitting(true)
        enhancedAxios('multipart/form-data', userToken)
            .post(`/admin/categories`, formData)
            .then((response) => {
                successAlert('تم اضافة الفئة بنجاح')
                resetForm()
                setSubmitting(false)
            })
            .catch((error) => {
                errorAlert(error.response.data.message)
                setSubmitting(false)
            })
    }
    // formik configration
    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            img: null,
            name: '',
            is_disabled: false,
            parent_id: '8236cf2c-1eae-4192-90e8-8567dd6fd42a',
            is_featured: false,
        },
        validationSchema: addCategorySchema,
        onSubmit, //function we write above
        handleFileChange,
    })

    return (
        <Stack width="100%" height="100%" spacing={8} alignItems="center">
            <PageTitle title="إضافة فئة للنشاطات" />
            <Stack
                component="form"
                spacing={2}
                onSubmit={handleSubmit}
                width="100%"
                maxWidth="600px"
            >
                <TextField
                    label="اسم الفئة"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{ width: '100%' }}
                />
                <PreviewCustomButton
                    file={file}
                    handleFileChange={handleFileChange}
                    IPWidth="100%"
                    IPVariant="rounded"
                    IPObjectFit="contain"
                    resetPreview={resetPreview}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={values.is_disabled}
                            onChange={handleChange}
                            name="is_disabled"
                        />
                    }
                    label="اخفاء "
                />

                {/* submit button */}
                <MainButton text="إنشاء" disabled={isSubmitting} />
                {errors && (
                    <Stack spacing={1}>
                        {errors.name && touched.name && (
                            <ErrorMessage errorMsg={errors.name} />
                        )}
                        {errors.sort && touched.sort && (
                            <ErrorMessage errorMsg={errors.sort} />
                        )}
                        {errors.img && touched.img && (
                            <ErrorMessage errorMsg={errors.img} />
                        )}
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}

export default AddCategoryActivity
