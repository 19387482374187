import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
// router
import { useParams } from 'react-router'

// axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// mui
import { Stack, Box, Typography } from '@mui/material'
import { errorAlert } from '../../utils/alerts'

const VendorStats = () => {
    const { id } = useParams()
    const userToken = useSelector((state) => state.userToken)
    const [statsData, setStatsData] = useState({
        expireDate: '',
        favouriteCount: '',
        viewCount: '',
    })

    // --------------------------------- get vendor activity details -------------------------------------------------
    useEffect(() => {
        enhancedAxios(null, userToken)
            .get(`/admin/vendors/${id}`)
            .then((res) => {
                setStatsData({
                    ...statsData,
                    expireDate: res.data.sub_expire_at,
                    favouriteCount: res.data.favorite_count,
                    viewCount: res.data.view_count,
                })
            })
            .catch((error) => errorAlert(error))
    }, [id])
    return (
        <Stack
            direction="row"
            gap="20px"
            alignItems="flex-start"
            flexWrap="wrap"
            width="100%"
        >
            <Box
                display="flex"
                gap="10px"
                justifyContent="center"
                alignItems="center"
            >
                <Typography variant="body2">تاريخ انتهاء الاشتراك</Typography>
                <Typography
                    variant="subtitle1"
                    textAlign="center"
                    sx={{
                        backgroundColor: 'primary.main',
                        color: 'basicColor.white',
                        padding: '5px',
                        minWidth: '50px',
                    }}
                >
                    {statsData.expireDate}
                </Typography>
            </Box>
            <Box
                display="flex"
                gap="10px"
                justifyContent="center"
                alignItems="center"
            >
                <Typography variant="body2"> عدد التفضيلات</Typography>
                <Typography
                    variant="subtitle1"
                    textAlign="center"
                    sx={{
                        backgroundColor: 'primary.main',
                        color: 'basicColor.white',
                        padding: '5px',
                        minWidth: '50px',
                    }}
                >
                    {statsData.favouriteCount}
                </Typography>
            </Box>
            <Box
                display="flex"
                gap="10px"
                justifyContent="center"
                alignItems="center"
            >
                <Typography variant="body2"> عدد الزيارات</Typography>
                <Typography
                    variant="subtitle1"
                    textAlign="center"
                    sx={{
                        backgroundColor: 'primary.main',
                        color: 'basicColor.white',
                        padding: '5px',
                        minWidth: '50px',
                    }}
                >
                    {statsData.viewCount}
                </Typography>
            </Box>
        </Stack>
    )
}

export default VendorStats
