import { useState, useEffect } from 'react'
// react router
import { useSearchParams } from 'react-router-dom'
// redux
import { useSelector } from 'react-redux'
// axios
import EnhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// mui
import { Stack, Box, Typography } from '@mui/material'
// sections and components
import {
    RingsLoader,
    PageTitle,
    SearchBox,
    SortBox,
    StatusFilterBox,
    CitiesFilterBox,
    AddButton,
    CategoryFilterBox,
} from '../../../components'
import { TableGrid } from '../../../Sections'
// user cols config
import { manageVendorsCols } from '../../../utils/tableGridUtils/manageVendorsCols'
// alerts
import { errorAlert } from '../../../utils/alerts/index'

const sortingOptions = [
    {
        sortTitle: 'الاسم تنازلياً',
        sortKey: 'name',
    },
    {
        sortTitle: 'الاسم تصاعدياً',
        sortKey: '-name',
    },
    {
        sortTitle: 'تاريخ الانشاء من الاحدث الى الاقدم',
        sortKey: '-created_at',
    },
    {
        sortTitle: 'تاريخ الانشاء من الاقدم الى الاحدث',
        sortKey: 'created_at',
    },
    {
        sortTitle: 'تاريخ التحديث من الاحدث الى الاقدم',
        sortKey: '-updated_at',
    },
    {
        sortTitle: 'تاريخ التحديث من الاقدم الى الاحدث',
        sortKey: 'updated_at',
    },
    {
        sortTitle: 'تاريخ انتهاء الاشتراك',
        sortKey: 'sub_expire_at',
    },
]

const ManageVendorActivities = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    let pageFromParam =
        searchParams.get('page') != null ? searchParams.get('page') : 1
    const [vendorsRows, setVendorsRows] = useState([])
    const [visibleRowsPerPage, setVisibleRowsPerPage] = useState(20)
    const [currentPage, setCurrentPage] = useState(pageFromParam)
    const [isLoading, setIsLoading] = useState(true)
    const [totalRows, setTotalRows] = useState(10)
    const [sortValue, setSortValue] = useState('-created_at')
    const [searchValue, setSearchValue] = useState('')
    const [filterValues, setFilterValues] = useState({
        statusFilterValue: 'is_disabled:eq:false|true',
        citiesFilterValue: '',
        categoryFilterValue: '',
    })
    const [paginationModel, setPaginationModel] = useState({
        pageSize: visibleRowsPerPage,
        page: currentPage - 1,
    })
    const userToken = useSelector((state) => state.userToken)
    //   get all vendor activities
    useEffect(() => {
        setIsLoading(true)
        EnhancedAxios(null, userToken)
            .get(
                `/admin/vendors?q=${searchValue}&sorts=${sortValue}&filters=${filterValues.statusFilterValue},${filterValues.citiesFilterValue},${filterValues.categoryFilterValue}&page=${currentPage}&paginate=${visibleRowsPerPage}`
            )
            .then((response) => {
                setVendorsRows(response.data.data)
                setTotalRows(response.data.meta.total)
                setIsLoading(false)
            })
            .catch((error) => errorAlert(error))
    }, [
        searchValue,
        sortValue,
        filterValues,
        currentPage,
        visibleRowsPerPage,
        searchParams,
    ])
    useEffect(() => {
        setCurrentPage(Number(searchParams.get('page')))
    }, [Number(searchParams.get('page'))])
    return (
        <Stack spacing={4} alignItems="center">
            <PageTitle title="ادارة النشاطات التجارية" />
            {/* add and filters sections  */}
            <Stack spacing={4} width="100%">
                <Box>
                    <AddButton to="/add-vendor-activity" />
                </Box>
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap="10px"
                    flexWrap="wrap"
                    sx={{
                        '& .MuiBox-root': {
                            flex: '1',
                            minWidth: '200px',
                            // maxWidth:'480px',
                        },
                    }}
                >
                    <SearchBox
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                    <SortBox
                        sortValue={sortValue}
                        setSortValue={setSortValue}
                        sortingOptions={sortingOptions}
                    />
                    <StatusFilterBox
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                    />
                    <CitiesFilterBox
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                    />
                    <CategoryFilterBox
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                    />
                </Box>
            </Stack>
            {isLoading ? (
                <RingsLoader />
            ) : vendorsRows.length === 0 && searchValue === '' ? (
                <Typography variant="body1">لا يوجد عناصر لعرضها</Typography>
            ) : vendorsRows.length === 0 && searchValue !== '' ? (
                <Typography variant="body1">
                    {' '}
                    لا يوجد عناصر لنتيجة البحث{' '}
                </Typography>
            ) : (
                <TableGrid
                    rows={vendorsRows}
                    cols={manageVendorsCols}
                    currentPage={parseInt(currentPage)}
                    setCurrentPage={setCurrentPage}
                    visibleRowsPerPage={visibleRowsPerPage}
                    isLoading={isLoading}
                    totalRows={totalRows}
                />
            )}
        </Stack>
    )
}

export default ManageVendorActivities
