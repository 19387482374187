import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
// redux staet
import { useSelector } from 'react-redux'
const ProtectedRoutes = () => {
    // const tokenFound = true;
    const tokenFound = useSelector((state) => state.userToken)
    return tokenFound ? <Outlet /> : <Navigate to="/login" />
}

export default ProtectedRoutes
