import * as yup from 'yup'
// (http(s)?:\/\/)
const maxImgSizeMB = 20
const maxImgSizeBytes = maxImgSizeMB * 2 ** 20
const oneDayInMS = 1 * 24 * 60 * 60 * 1000
const urlValueRegex = new RegExp(/(http(s)?)/)

export const addPostSchema = yup.object().shape({
    category_id: yup.string().required('ادخل الفئة  التى ينتمى ليها النشاط'),
    description: yup.string().required('ادخل وصف الخدمة'),
    img: yup
        .mixed()
        .nullable()
        .test(
            'fileSize',
            `حجم الصورة كبير جدا يجب الا يتعدى حجم الصورة ${maxImgSizeMB}MB`,
            (value) => (value != null ? value.size <= maxImgSizeBytes : 't')
        )
        .test('fileType', 'امتداد الصورة يجب ان يكون jpg , png', (value) =>
            value != null
                ? ['image/jpg', 'image/png', 'image/jpeg'].includes(value.type)
                : 't'
        ),
    is_disabled: yup.boolean(),
    price: yup
        .number()
        .typeError('السعر يجب ان لا يحتوي علي حروف')
        .min(0, 'السعر يجب ان يكون اكبر من او يساوي الصفر')
        .required('الحقل مطلوب'),
    url_type: yup
        .string()
        .matches(urlValueRegex, 'كتابة الرابط مسبوق ب https:// او https://'),
    url_value: yup.string().min(2, 'ادخل الرابط الصحيح'),
})
