import { useState, useEffect, useRef } from 'react'
// router
import { useParams } from 'react-router'
// redux
import { useSelector } from 'react-redux'
// components
import {
    PageTitle,
    MainButton,
    ErrorMessage,
    SelectHotDealPeriod,
    UploadImagePreview,
} from '../../../components'
// mui
import {
    Stack,
    TextField,
    Box,
    FormControlLabel,
    Checkbox,
    Avatar,
    Button,
    ToggleButtonGroup,
    ToggleButton,
} from '@mui/material'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// formik
import { useFormik } from 'formik'
// shcema
import { editDealSchema } from '../../../schemes/deals/editDealSchema'
// alerts
import { errorAlert, successAlert } from '../../../utils/alerts/index'
// utils
import { imageCompressor } from '../../../utils/comporessImage/imageCompressor'
// icons
import { FiUploadCloud } from 'react-icons/fi'
// utils
import { addDaysToDate } from '../../../utils/addDaysToDate'
const expandPeriodOptions = [
    {
        value: '0',
        label: 'دون تمديد',
    },
    {
        value: '1',
        label: '1 يوم',
    },
    {
        value: '2',
        label: ' يومين',
    },
    {
        value: '5',
        label: '5 ايام ',
    },
]
const EditHotDeal = () => {
    const userToken = useSelector((state) => state.userToken)
    const { id } = useParams()
    const inputFileRef = useRef()
    const [file, setFile] = useState(null)
    const [resetPreview, setresetPreview] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [expiresAT, setExpiresAt] = useState()
    // ------------------------------   handle file changed toget value and for preview --------------------------
    const handleFileChange = async (e) => {
        let x = e.target.files[0]
        const final = await imageCompressor(x)
        setFile(final)
        setValues((prevValues) => ({ ...prevValues, img: final }))
    }
    // ------------------------------   handle is global change --------------------------
    const handleIsGlobalChange = (e, value) => {
        setValues((prev) => ({ ...prev, is_general: Boolean(value) }))
    }
    // ----------------------------------------------- submit form -----------------------------------------------
    const onSubmit = (values) => {
        values = {
            ...values,
            expires_at: addDaysToDate(values.expires_at, expiresAT),
        }
        if (values.is_general) {
            delete values['price']
            delete values['final_price']
        } else {
            delete values['general_discount_price']
        }
        const formData = new FormData()
        for (let item in values) {
            formData.append(item, values[item])
        }
        enhancedAxios('multipart/form-data', userToken)
            .put(`admin/hot-offers/${id}`, formData)
            .then((response) => {
                successAlert('تم تعديل العرض')
                setSubmitting(false)
            })
            .catch((error) => {
                errorAlert(error)
                setSubmitting(false)
            })
    }
    // -----------------------------------------------  formik configraion ----------------------------------------------------------
    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            title: '',
            price: '',
            final_price: '',
            general_discount_price: '',
            expires_at: '0',
            description: '',
            img: null,
            is_disabled: false,
            is_general: false,
        },
        validationSchema: editDealSchema,
        onSubmit,
        handleFileChange,
        handleIsGlobalChange,
    })
    // ------------------------------------------- get current deal data -------------------------
    useEffect(() => {
        enhancedAxios(null, userToken)
            .get(`/admin/hot-offers/${id}`)
            .then((response) => {
                console.log(response.data.is_general)
                setValues({
                    ...values,
                    description: response.data.description,
                    title: response.data.title,
                    price: response.data.price,
                    final_price: response.data.final_price,
                    img: response.data.img,
                    is_disabled: response.data.is_disabled,
                    is_general: Boolean(response.data.is_general),
                    general_discount_price:
                        response.data.general_discount_price,
                })
                setExpiresAt(response.data.expires_at)
                setIsLoading(false)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [id])

    return (
        <Stack spacing={4} alignItems="center" width="100%">
            <PageTitle title="تعديل عرض ساخن" />
            <Stack
                component="form"
                onSubmit={handleSubmit}
                width="100%"
                maxWidth="700px"
                spacing={2}
                alignItems="flex-start"
            >
                <ToggleButtonGroup
                    color="primary"
                    value={values.is_general}
                    exclusive
                    onChange={handleIsGlobalChange}
                    aria-label="Platform"
                >
                    <ToggleButton value={true}>عرض عام</ToggleButton>
                    <ToggleButton value={false}>عرض خاص</ToggleButton>
                </ToggleButtonGroup>
                {/* preview and upload image  */}
                <Stack spacing={1} flex="1" width="100%" alignItems="center">
                    {/* img preview section  */}
                    {typeof values.img != 'string' && (
                        <UploadImagePreview
                            file={file}
                            IPWidth="300px"
                            IPHeight="200px"
                            IPVariant="rounded"
                            IPObjectFit="contain"
                        />
                    )}
                    {typeof values.img == 'string' && (
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            gap="10px"
                        >
                            <Avatar
                                src={values.img}
                                alt="uploaded Image"
                                variant="rounded"
                                sx={{
                                    width: '100%',
                                    height: '200px',
                                    '& .MuiAvatar-img': {
                                        objectFit: 'contain',
                                    },
                                }}
                            />
                        </Box>
                    )}
                    <input
                        type="file"
                        onChange={handleFileChange}
                        name="img"
                        ref={inputFileRef}
                        hidden
                        accept="image/png, image/gif, image/jpeg"
                    ></input>
                    <Box display="flex" justifyContent="center" width="100%">
                        <Button
                            variant="contained"
                            color="success"
                            endIcon={<FiUploadCloud />}
                            onClick={() => inputFileRef.current.click()}
                        >
                            تحميل صورة
                        </Button>
                    </Box>
                </Stack>
                <TextField
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="العنوان"
                    multiline
                    minRows={1}
                    maxRows={5}
                    sx={{
                        width: '100%',
                    }}
                />
                <TextField
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="التفاصيل"
                    multiline
                    minRows={2}
                    maxRows={5}
                    sx={{
                        width: '100%',
                    }}
                />
                {values.is_general ? (
                    <TextField
                        name="general_discount_price"
                        value={values.general_discount_price}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="سعر التخفيض العام"
                        type="number"
                        sx={{
                            width: '100%',
                        }}
                    />
                ) : (
                    <>
                        <TextField
                            name="price"
                            value={values.price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="السعر"
                            type="number"
                            sx={{
                                width: '100%',
                            }}
                        />
                        <TextField
                            name="final_price"
                            value={values.final_price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="السعر النهائي"
                            type="number"
                            sx={{
                                width: '100%',
                            }}
                        />
                    </>
                )}
                <SelectHotDealPeriod
                    setValues={setValues}
                    setValuesKey="expires_at"
                    title="تمديد فترة الانتهاء"
                    options={expandPeriodOptions}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={values.is_disabled}
                            onChange={handleChange}
                            name="is_disabled"
                        />
                    }
                    label="اخفاء "
                />
                <Box width="100%" sx={{ '& button': { width: '100%' } }}>
                    <MainButton text="تعديل العرض" disabled={isSubmitting} />
                    {/* errors section  */}
                    {errors && (
                        <Stack spacing={1}>
                            {errors.img && touched.img && (
                                <ErrorMessage errorMsg={errors.img} />
                            )}
                            {errors.title && touched.title && (
                                <ErrorMessage errorMsg={errors.title} />
                            )}
                            {errors.price && touched.price && (
                                <ErrorMessage errorMsg={errors.price} />
                            )}
                            {errors.final_price && touched.final_price && (
                                <ErrorMessage errorMsg={errors.final_price} />
                            )}
                            {errors.general_discount_price &&
                                touched.general_discount_price && (
                                    <ErrorMessage
                                        errorMsg={errors.general_discount_price}
                                    />
                                )}
                            {errors.expires_at && touched.expires_at && (
                                <ErrorMessage errorMsg={errors.expires_at} />
                            )}
                            {errors.description && touched.description && (
                                <ErrorMessage errorMsg={errors.description} />
                            )}
                        </Stack>
                    )}
                </Box>
            </Stack>
        </Stack>
    )
}

export default EditHotDeal
