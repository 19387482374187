import { ChangeOrderDeliveryStatus, EditDetails } from '../../components'

export const ordersToDeliveryWithChangeStatus = [
    {
        field: 'name',
        headerName: 'الاسم',
        width: '140',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.name ?? '!! لايوجد  !!',
    },
    {
        field: 'phone',
        headerName: 'رقم الهاتف',
        width: '120',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.phone ?? '!! لاتوجد  !!',
    },
    {
        field: 'address',
        headerName: 'العنوان',
        width: '150',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.address ?? '!! لاتوجد  !!',
    },
    {
        field: 'cost',
        headerName: 'قيمة الطلب',
        width: '60',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.cost ?? '!! لاتوجد  !!',
    },
    {
        field: 'details',
        headerName: 'التفاصيل',
        width: '240',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.details ?? '!! لاتوجد  !!',
    },
    {
        field: 'vendor_name',
        headerName: 'صاحب النشاط',
        width: '90',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.vendor?.name ?? '!! لاتوجد  !!',
    },
    {
        field: 'vendor_city',
        headerName: 'مدينة صاحب النشاط ',
        width: '80',
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.vendor?.city?.name ?? '!! لاتوجد  !!',
    },
    {
        field: 'vendor_address_info',
        headerName: 'عنوان صاحب النشاط ',
        width: '120',
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.vendor?.address_info ?? '!! لاتوجد  !!',
    },
    {
        field: 'status',
        headerName: 'حالة الطلب',
        width: '150',
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <ChangeOrderDeliveryStatus
                id={params.row.id}
                defaultValue={params.row.status}
            />
        ),
    },
]
