import { useState, useEffect, useRef } from 'react'
// redux
import { useSelector } from 'react-redux'
// fomik
import { setNestedObjectValues, useFormik } from 'formik'
// axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// mui
import { Stack, Box, TextField, Typography } from '@mui/material'
// components
import { SideTitle, MainButton } from '../../components'
import SocialLinkForm from './SocialLinkForm'
import { errorAlert, successAlert, successToastAlert } from '../../utils/alerts'
// utils
const socialLinksWeNeed = [
    {
        key: 'app_twitter_url',
        title: 'تويتر',
        fieldType: 'url',
        id: '',
        value: '',
    },
    {
        key: 'app_facebook_url',
        title: 'فيسبوك',
        fieldType: 'url',
        id: '',
        value: '',
    },
    {
        key: 'app_instagram_url',
        title: 'انستغرام',
        fieldType: 'url',
        id: '',
        value: '',
    },
    {
        key: 'app_whatsapp_url',
        title: 'واتساب',
        fieldType: 'url',
        id: '',
        value: '',
        helperText: 'https://wa.me/+09XXXXXXXXXX',
    },
    {
        key: 'app_website_url',
        title: 'الموفع الالكترونى',
        fieldType: 'url',
        id: '',
        value: '',
    },
    {
        key: 'app_email_url',
        title: 'البريد الالكترونى',
        fieldType: 'email',
        id: '',
        value: '',
    },
]

const SocialLinks = () => {
    const [isLoading, setIsLoading] = useState(true)
    const userToken = useSelector((state) => state.userToken)
    // ------------------------------------------ submit form ------------------------------------------
    // get settings data
    useEffect(() => {
        setIsLoading(true)
        enhancedAxios(null, userToken)
            .get(`/admin/settings?page=1&paginate=9999`)
            .then((response) => {
                const returnedData = response.data.data
                returnedData.forEach((el) => {
                    socialLinksWeNeed.forEach((item) => {
                        if (el.key === item.key) {
                            item.value = el.value
                            item.id = el.id
                        }
                    })
                })
                setIsLoading(false)
            })
            .catch((error) => errorAlert(error))
    }, [])

    return (
        <Stack spacing={1}>
            <SideTitle title="بيانات التواصل" />
            {isLoading ? (
                <Typography varaint="body2">تحميل...</Typography>
            ) : (
                <Stack spacing={2} width="100%">
                    {socialLinksWeNeed.map((item) => (
                        <SocialLinkForm
                            key={item.id}
                            itemKey={item.key}
                            value={item.value}
                            id={item.id}
                            title={item.title}
                            fieldType={item.fieldType}
                            helperText={
                                item.helperText ? item.helperText : null
                            }
                        />
                    ))}
                </Stack>
            )}
        </Stack>
    )
}

export default SocialLinks
