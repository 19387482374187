// mui
import { Stack, Box, Typography } from '@mui/material'
// react router
import { NavLink } from 'react-router-dom'

const NavbarLinks = ({ links }) => {
    return (
        <Stack width="100%">
            {links.map((link, index) => {
                const { title, icon, path } = link
                return (
                    <Box
                        component={NavLink}
                        to={path}
                        key={index}
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        gap="10px"
                        sx={{
                            color: 'inherit',
                            padding: '8px',
                            borderRadius: '5px',
                            '&:hover': {
                                backgroundColor: 'primary.light',
                            },
                            '&.active': {
                                backgroundColor: 'primary.light',
                            },
                        }}
                    >
                        <Typography component="span" fontSize="18px">
                            {icon}
                        </Typography>
                        <Typography component="span">{title}</Typography>
                    </Box>
                )
            })}
        </Stack>
    )
}

export default NavbarLinks
