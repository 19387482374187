// components
import { PageTitle } from '../../../components'
import { SubscribtionCost, AboutApp, SocialLinks } from '../../../Sections'
// mui
import { Stack, Box } from '@mui/material'

const Settings = () => {
    return (
        <Stack width="100%" height="100%" spacing={8} alignItems="center">
            <PageTitle title="الضبط" />
            <Stack width="100%" spacing={2}>
                <SubscribtionCost />
                <SocialLinks />
                <AboutApp />
            </Stack>
        </Stack>
    )
}

export default Settings
