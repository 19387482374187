export const lightPalette = {
    primary: {
        main: '#f29312',
        light: '#ffb249;',
    },
    secondary: {
        main: '#eb455f',
    },
    textColor: {
        // prColor:'#2f2f2f',
        main: '#000',
        secColor: '#999',
    },
    basicColor: {
        white: '#fff',
        black: '#000',
        fifthGray: '#f9f9f9',
    },
}
