import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import { AiOutlinePlus } from 'react-icons/ai'

const AddButton = ({ to }) => {
    return (
        <Button
            size="large"
            variant="contained"
            color="primary"
            component={Link}
            to={to}
            endIcon={<AiOutlinePlus />}
            sx={{ width: 'fit-content', boxShadow: '0' }}
        >
            اضافة
        </Button>
    )
}

export default AddButton
