import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// components
import {
    PageTitle,
    RingsLoader,
    PostCard,
    SearchBox,
    SortBox,
    StatusFilterBox,
    AddButton,
} from '../../../components'
// mui
import { Stack, Box, Pagination, Typography } from '@mui/material'
// alerts
import { errorAlert } from '../../../utils/alerts'

const VendorPosts = () => {
    const [posts, setPosts] = useState([]) // for all vendor posts
    const [dataChanged, setDataChanged] = useState(false) // if data changed render posts container
    const [isLoading, setIsLoading] = useState(true) // loadin
    const [itemsPerPage, setItemsPerPage] = useState(16)
    const [currentPage, setCurrentPage] = useState(1)
    const [searchValue, setSearchValue] = useState('')
    const [sortValue, setSortValue] = useState('-created_at')
    const [totalItems, setTotalItems] = useState(5)
    const [filterValues, setFilterValues] = useState({
        statusFilterValue: 'is_disabled:eq:false|true',
    })
    const userToken = useSelector((state) => state.userToken)
    // ------------------------------------------- pagination page changed ----------------------------------
    const handlePageChanging = (e, newPage) => {
        setCurrentPage(newPage)
    }
    //  ------------------------------------------- get all posts -------------------------------------------
    useEffect(() => {
        enhancedAxios(null, userToken)
            .get(
                `/vendor/posts?page=${currentPage}&paginate=${itemsPerPage}&sorts=${sortValue}&filters=${filterValues.statusFilterValue}&q=${searchValue}`
            )
            .then((response) => {
                setPosts(response.data.data)
                setIsLoading(false)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [
        currentPage,
        itemsPerPage,
        sortValue,
        filterValues,
        searchValue,
        dataChanged,
    ])
    return (
        <Stack width="100%" height="100%" spacing={4} alignItems="center">
            <PageTitle title="إدارة المنشورات" />
            <Stack width="100%" spacing={2}>
                <Box>
                    <AddButton to="/vendor-add-post" />
                </Box>
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap="20px"
                    flexWrap="wrap"
                    sx={{
                        '& .MuiBox-root': {
                            flex: '1',
                            minWidth: '240px',
                        },
                    }}
                >
                    <SearchBox
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                    <SortBox
                        sortValue={sortValue}
                        setSortValue={setSortValue}
                    />
                    <StatusFilterBox
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                    />
                </Box>
            </Stack>
            {/* filter seach sort seaction  */}
            {/* posts section */}
            <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                flexWrap="wrap"
                gap="20px"
                width="100%"
            >
                {isLoading ? (
                    <RingsLoader />
                ) : posts.length === 0 && searchValue === '' ? (
                    <Typography variant="body1">
                        لا يوجد عناصر لعرضها
                    </Typography>
                ) : posts.length === 0 && searchValue !== '' ? (
                    <Typography variant="body1">
                        {' '}
                        لا يوجد عناصر لنتيجة البحث{' '}
                    </Typography>
                ) : (
                    <>
                        {posts.map((post) => {
                            return (
                                <PostCard
                                    key={post.id}
                                    id={post.id}
                                    img={post.img}
                                    categoryName={post.category.name}
                                    createdAt={post.created_at}
                                    description={post.description}
                                    vendorName={post.vendor.name}
                                    url={post.url_value}
                                    price={post.price}
                                    setDataChanged={setDataChanged}
                                    adminOrVendor="vendor"
                                    editPagePath={`/vendor-edit-post/${post.id}`}
                                />
                            )
                        })}
                        <Box
                            width="100%"
                            display="flex"
                            justifyContent="center"
                        >
                            {totalItems > itemsPerPage ? (
                                <Pagination
                                    defaultPage={1}
                                    page={currentPage}
                                    count={Math.ceil(totalItems / itemsPerPage)}
                                    onChange={handlePageChanging}
                                    variant="outlined"
                                    color="primary"
                                />
                            ) : (
                                ''
                            )}
                        </Box>
                    </>
                )}
            </Box>
        </Stack>
    )
}

export default VendorPosts
