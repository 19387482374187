import * as yup from 'yup'

const maxImgSizeMB = 20
const maxImgSizeBytes = maxImgSizeMB * 2 ** 20

export const addDealSchema = yup.object().shape({
    img: yup
        .mixed()
        .nullable()
        .test(
            'fileSize',
            `حجم الصورة كبير جدا يجب الا يتعدى حجم الصورة ${maxImgSizeMB}MB`,
            (value) => (value != null ? value.size <= maxImgSizeBytes : 't')
        )
        .test('fileType', 'امتداد الصورة يجب ان يكون jpg , png', (value) =>
            value != null
                ? ['image/jpg', 'image/png', 'image/jpeg'].includes(value.type)
                : 't'
        ),
    title: yup.string().required('عنوان العرض مطلوب'),
    price: yup
        .number()
        .typeError('السعر يجب ان يكون رقم حقيقي')
        .when('is_general', {
            is: false,
            then: (schema) => schema.required('السعر مطلوب'),
            otherwise: (schema) => schema,
        }),
    final_price: yup
        .number()
        .typeError('السعر يجب ان يكون رقم حقيقي')
        .when('is_general', {
            is: false,
            then: (schema) =>
                schema
                    .lessThan(
                        yup.ref('price'),
                        'السعر النهائي يجب ان يكون اثل من السعر الاصلي'
                    )
                    .required('السعر النهائي مطلوب'),
            otherwise: (schema) => schema,
        }),

    general_discount_price: yup
        .number()
        .typeError('السعر يجب ان يكون رقم حقيقي')
        .when('is_general', {
            is: true,
            then: (schema) => schema.required('سعر التخفيض العام مطلوب'),
            otherwise: (schema) => schema,
        }),
    is_general: yup.boolean(),
    description: yup.string().required(' التفاصيل مطلوبة'),
    is_disabled: yup.boolean(),
})
