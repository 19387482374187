// sweet alert
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { removeTokenFromLocal } from '../../redux/actions/userActions'
const MySwal = withReactContent(Swal)

// success oprations
export const successAlert = (text = 'لا يوجد رسالة') => {
    MySwal.fire({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        text: text,
        icon: 'success',
    })
}
// success toast alert
export const successToastAlert = (text = 'لا يوجد رسالة') => {
    MySwal.fire({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        text: text,
        icon: 'success',
    })
}

// warning alert message
export const warningAlert = (text = 'لا يوجد رسالة') => {
    MySwal.fire({
        text: text,
        icon: 'warning',
    })
}
// error alert message
export const errorAlert = (error) => {
    MySwal.fire({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        text:
            typeof error === 'string'
                ? error
                : error.response?.data?.message || error.message || 'حدث خطأ',
        icon: 'error',
    })
}
// ask for confirmation
export const askForConfirmationAlert = (text = 'لا يوجد رسالة.', fn) => {
    MySwal.fire({
        text: text,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3ba3fb',
    }).then((result) => {
        if (result.isConfirmed) {
            fn()
        }
    })
}
