import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material'
const defaultSortingOptions = [
    {
        sortTitle: 'الاسم تنازلياً',
        sortKey: 'name',
    },
    {
        sortTitle: 'الاسم تصاعدياً',
        sortKey: '-name',
    },
    {
        sortTitle: 'تاريخ الانشاء من الاحدث الى الاقدم',
        sortKey: '-created_at',
    },
    {
        sortTitle: 'تاريخ الانشاء من الاقدم الى الاحدث',
        sortKey: 'created_at',
    },
    {
        sortTitle: 'تاريخ التحديث من الاحدث الى الاقدم',
        sortKey: '-updated_at',
    },
    {
        sortTitle: 'تاريخ التحديث من الاقدم الى الاحدث',
        sortKey: 'updated_at',
    },
]
const SortBox = ({
    sortValue,
    setSortValue,
    sortingOptions = defaultSortingOptions,
}) => {
    const handleChange = (e) => {
        setSortValue(e.target.value)
    }
    return (
        <Box>
            <FormControl fullWidth>
                <InputLabel>ترتيب حسب</InputLabel>
                <Select
                    name="sort_box"
                    value={sortValue}
                    label="ترتيب حسب"
                    onChange={handleChange}
                >
                    {sortingOptions.map((option) => {
                        return (
                            <MenuItem
                                value={option.sortKey}
                                key={option.sortKey}
                            >
                                {option.sortTitle}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </Box>
    )
}

export default SortBox
