import { useState, useEffect, useRef } from 'react'
// redux
import { useSelector } from 'react-redux'
// router
import { useParams } from 'react-router'
// formik
import { useFormik } from 'formik'
// schema
import { editVendorSchema } from '../../schemes/manageVendors/editVendorSchema'
// axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// components
import {
    SelectCity,
    SelectCategory,
    UploadImagePreview,
    SideTitle,
    RingsLoader,
    MainButton,
    ErrorMessage,
    DownloadVendorQrCode,
} from '../../components'
// mui
import {
    TextField,
    Stack,
    Box,
    Avatar,
    Button,
    FormControlLabel,
    Checkbox,
    Divider,
    Typography,
} from '@mui/material'
// icons
import { FiUploadCloud } from 'react-icons/fi'
import { MdQrCode2 } from 'react-icons/md'
// alerts
import { errorAlert, successAlert } from '../../utils/alerts'
// utils
import { imageCompressor } from '../../utils/comporessImage/imageCompressor'

const EditVendorForm = ({ ApiUrl }) => {
    const { id } = useParams()
    const inputFileRef = useRef()
    const [file, setFile] = useState(null)
    const [vendorCity, setVendorCity] = useState(null)
    const [vendorCategory, setVendorCategory] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [subExpireDate, setSubExpireDate] = useState()
    const [qrLink, setQrLink] = useState('')
    const userToken = useSelector((state) => state.userToken)
    const parent_id_of_select_categories =
        '8236cf2c-1eae-4192-90e8-8567dd6fd42a'
    // --------------------------------- get vendor activity details -------------------------------------------------
    useEffect(() => {
        setIsLoading(false)
        enhancedAxios(null, userToken)
            .get(ApiUrl)
            .then((res) => {
                setValues({
                    ...values,
                    name: res.data.name,
                    owner: res.data.owner,
                    img: res.data.img,
                    description: res.data.description,
                    address_info: res.data.address_info,
                    city_id: res.data.city.id,
                    category_id: res.data.category.id,
                    email: res.data.admin.email,
                    is_disabled: res.data.is_disabled,
                    is_featured: res.data.is_featured ?? false,
                    sort: res.data.sort ?? 0,
                    hot_offers_limit: res.data.hot_offers_limit ?? 0,
                    sub_expire_at: res.data.sub_expire_at ?? '',
                })
                setQrLink(res.data.qr_link.link)
                setVendorCity(res.data.city)
                setVendorCategory(res.data.category)
                setSubExpireDate(res.data.sub_expire_at)
                setIsLoading(false)
            })
            .catch((err) => false)
    }, [id])
    // --------------------------------- submit form ------------------------------------------------------------------
    const onSubmit = (values) => {
        const formData = new FormData()
        if (values.password === '') {
            delete values['password']
            delete values['password_confirmation']
        }
        for (let item in values) {
            formData.append(item, values[item])
        }
        enhancedAxios('multipart/formData', userToken)
            .put(ApiUrl, formData)
            .then((response) => {
                successAlert('تم تعديل النشاط')
                setSubmitting(false)
            })
            .catch((error) => errorAlert(error))
    }
    // ------------------------------   handle file changed toget value and for preview --------------------------
    const handleFileChange = async (e) => {
        let x = e.target.files[0]
        const final = await imageCompressor(x)
        setFile(final)
        setValues((prevValues) => ({ ...prevValues, img: final }))
    }
    // --------------------------------- formik configration ----------------------------------------------------------
    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            name: '',
            owner: '',
            city_id: '',
            category_id: '',
            description: '',
            address_info: '',
            hot_offers_limit: 0,
            img: null,
            email: '',
            password: '',
            password_confirmation: '',
            sort: 0,
            is_disabled: false,
            is_featured: false,
            sub_expire_at: '',
        },
        validationSchema: editVendorSchema,
        onSubmit, //function we write above
        handleFileChange,
    })

    // ---------------------------------- page -----------------------------------------------------------------------
    if (isLoading) {
        return <RingsLoader />
    }
    return (
        <>
            <DownloadVendorQrCode link={qrLink} />
            <Divider flexItem />
            <Box
                width="100%"
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-start"
                gap="20px"
                flexWrap="wrap"
                component="form"
                onSubmit={handleSubmit}
            >
                <Stack
                    spacing={2}
                    flex="1"
                    sx={{ minWidth: { xs: '270px', sm: '450px' } }}
                >
                    <TextField
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="اسم النشاط"
                    />
                    <TextField
                        name="owner"
                        value={values.owner}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="اسم صاحب النشاط"
                    />
                    <SelectCity
                        vendorCity={vendorCity}
                        setVendorCity={setVendorCity}
                        setValues={setValues}
                    />
                    <SelectCategory
                        vendorCategory={vendorCategory}
                        setVendorCategory={setVendorCategory}
                        setValues={setValues}
                        parent_id_of_select_categories={
                            parent_id_of_select_categories
                        }
                    />
                    <TextField
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="عن النشاط"
                        multiline
                        minRows={1}
                        maxRows={5}
                    />
                    <TextField
                        name="address_info"
                        value={values.address_info}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="عنوان المحل"
                        multiline
                        minRows={1}
                        maxRows={5}
                    />
                    {ApiUrl !== '/vendor/' ? (
                        <TextField
                            label="الترتيب"
                            name="sort"
                            type="number"
                            value={values.sort}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    ) : (
                        <></>
                    )}
                    <TextField
                        name="sub_expire_at"
                        type="date"
                        value={values.sub_expire_at}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="تاريخ انتهاء الاشتراك"
                    />
                    <TextField
                        name="hot_offers_limit"
                        type="number"
                        value={values.hot_offers_limit}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="اقصي عدد من العروض الساخنة"
                        sx={{
                            width: '100%',
                        }}
                    />
                    {/* is disabled  */}
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.is_disabled}
                                    onChange={handleChange}
                                    name="is_disabled"
                                />
                            }
                            label="اخفاء "
                        />
                        {ApiUrl !== '/vendor/' ? (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={values.is_featured}
                                        onChange={handleChange}
                                        name="is_featured"
                                    />
                                }
                                label="مميز "
                            />
                        ) : (
                            <></>
                        )}
                    </Box>
                </Stack>
                <Stack
                    spacing={2}
                    flex="1"
                    sx={{ minWidth: { xs: '270px', sm: '450px' } }}
                >
                    {/* logo  */}
                    <Stack spacing={1} flex="1">
                        <SideTitle title="الشعار" />
                        {/* img preview section  */}
                        {typeof values.img != 'string' && (
                            <UploadImagePreview
                                file={file}
                                IPWidth="100%"
                                IPHeight="195px"
                                IPVariant="rounded"
                                IPObjectFit="contain"
                            />
                        )}
                        {typeof values.img == 'string' && (
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                gap="10px"
                            >
                                <Avatar
                                    src={values.img}
                                    alt="uploaded Image"
                                    variant="rounded"
                                    sx={{
                                        width: '100%',
                                        height: '195px',
                                        '& .MuiAvatar-img': {
                                            objectFit: 'contain',
                                        },
                                    }}
                                />
                            </Box>
                        )}
                        <input
                            type="file"
                            onChange={handleFileChange}
                            name="img"
                            ref={inputFileRef}
                            hidden
                            accept="image/png, image/gif, image/jpeg"
                        ></input>
                        <Box
                            display="flex"
                            justifyContent="center"
                            width="100%"
                        >
                            <Button
                                variant="contained"
                                color="success"
                                endIcon={<FiUploadCloud />}
                                onClick={() => inputFileRef.current.click()}
                            >
                                تحميل صورة
                            </Button>
                        </Box>
                    </Stack>
                    {/* admin data  */}
                    <Stack spacing={1} flex="1">
                        <SideTitle title="بيانات الحساب" />
                        <TextField
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="البريد الالكترونى"
                        />
                        <TextField
                            type="password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="الرقم السري"
                        />
                        <TextField
                            type="password"
                            name="password_confirmation"
                            value={values.password_confirmation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="تأكيد الرقم السري "
                        />
                        <MainButton text="تأكيد" disabled={isSubmitting} />
                        {/* errors section  */}
                        {errors && (
                            <Stack spacing={1}>
                                {errors.name && touched.name && (
                                    <ErrorMessage errorMsg={errors.name} />
                                )}
                                {errors.owner && touched.owner && (
                                    <ErrorMessage errorMsg={errors.owner} />
                                )}
                                {errors.description && touched.description && (
                                    <ErrorMessage
                                        errorMsg={errors.description}
                                    />
                                )}
                                {errors.address_info &&
                                    touched.address_info && (
                                        <ErrorMessage
                                            errorMsg={errors.address_info}
                                        />
                                    )}
                                {errors.img && touched.img && (
                                    <ErrorMessage errorMsg={errors.img} />
                                )}
                                {errors.email && touched.email && (
                                    <ErrorMessage errorMsg={errors.email} />
                                )}
                                {errors.password && touched.password && (
                                    <ErrorMessage errorMsg={errors.password} />
                                )}
                                {errors.password_confirmation &&
                                    touched.password_confirmation && (
                                        <ErrorMessage
                                            errorMsg={
                                                errors.password_confirmation
                                            }
                                        />
                                    )}
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Box>
        </>
    )
}

export default EditVendorForm
