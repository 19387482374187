// mui
import { Stack, Typography } from '@mui/material'

const StatsCard = ({ text, number, moreStyles = {} }) => {
    return (
        <Stack
            minWidth="300px"
            flex="1"
            alignItems="center"
            p={4}
            sx={{
                backgroundColor: 'primary.main',
                borderRadius: '10px',
                color: 'basicColor.white',
                ...moreStyles,
            }}
        >
            <Typography variant="h6">{text}</Typography>
            <Typography variant="h3">{number}</Typography>
        </Stack>
    )
}

export default StatsCard
