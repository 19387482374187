import * as yup from 'yup'
const phoneRegex = new RegExp(/[+]?[0-9]?\s?[0-9]{10,14}/)
const minUserAge = 18
const ageInMSecs = minUserAge * 365 * 24 * 60 * 60 * 1000
export const editUserSchema = yup.object().shape({
    name: yup.string().required('يجب ان يكون هناك اسم للمستخدم'),
    phone: yup
        .string()
        .nullable()
        .matches(phoneRegex, 'كتابة رقم الهاتف مسبوق بكود الدولة'),
    birthdate: yup
        .date()
        .nullable()
        .max(
            new Date(Date.now() - ageInMSecs),
            `المستخدم يجب ان يكون اكبر من ${minUserAge} عام`
        ),
})
