import React from 'react'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// mui
import { Box, Stack, Typography, IconButton } from '@mui/material'
// alerts
import {
    errorAlert,
    successAlert,
    askForConfirmationAlert,
} from '../../utils/alerts/index.js'
// icons
import { MdOutlineDelete } from 'react-icons/md'

const ReportCard = ({ id, value, user, vendor, setDataChanged }) => {
    const userToken = useSelector((state) => state.userToken)
    const deleting = () => {
        enhancedAxios(null, userToken)
            .delete(`/admin/reports/${id}`)
            .then((response) => {
                successAlert('تم حذف البلاغ')
                setDataChanged((prev) => !prev)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }
    const deleteReport = () => {
        askForConfirmationAlert(
            'هل انت متأكد انك تريد حذف هذا البلاغ',
            deleting
        )
    }
    return (
        <Box
            sx={{
                boxShadow: '0px 1px 2px 0px #c6c6c6',
                padding: '16px',
                borderRadius: '10px',
            }}
        >
            <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                sx={{ marginBottom: '10px' }}
            >
                <Stack>
                    <Typography variant="body1">
                        <Typography component="span" color="gray">
                            {' '}
                            مقدم البلاغ :{' '}
                        </Typography>
                        <Typography component="span" color="primary">
                            {user}
                        </Typography>
                    </Typography>
                    <Typography variant="body1">
                        <Typography component="span" color="gray">
                            {' '}
                            مقدم في :{' '}
                        </Typography>
                        <Typography component="span" color="primary">
                            {vendor}
                        </Typography>
                    </Typography>
                </Stack>
                <Stack>
                    <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={deleteReport}
                    >
                        <MdOutlineDelete />
                    </IconButton>
                </Stack>
            </Box>
            <Box>
                <Typography variant="body1">
                    <Typography component="span" color="gray">
                        {' '}
                        البلاغ :{' '}
                    </Typography>
                    <Typography component="span">{value}</Typography>
                </Typography>
            </Box>
        </Box>
    )
}

export default ReportCard
