import React, { useState } from 'react'
import {Button} from "@mui/material"
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
import { useSelector } from 'react-redux'
import { errorAlert, successAlert } from '../../utils/alerts'

const DelivererClaimOrder = ({ id }) => {
    const [disabled, setDisabled] = useState(false)
    const userToken = useSelector((state) => state.userToken)
    const claimOrder = () => {
        setDisabled(true)
        enhancedAxios(null, userToken)
            .post(`/delivery/delivery-orders/${id}/claim`)
            .then((res) => {
                successAlert('تمت عملية حصولك على الطلب')
            })
            .catch((error) => {
                errorAlert(error)
                setDisabled(false)
            })
    }
    return (
        <Button
            size="small"
            variant="outlined"
            color="primary"
            disabled={disabled}
            onClick={claimOrder}
        >
            مطالبة
        </Button>
    )
}

export default DelivererClaimOrder
