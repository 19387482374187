import React from 'react'
// react redux
import { useSelector } from 'react-redux'
// mui
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
// icons
import { MdAccessTime, MdOutlineDelete } from 'react-icons/md'
import { HiOutlineUser } from 'react-icons/hi2'
import { MdOutlineAlternateEmail } from 'react-icons/md'
// moment
import moment from 'moment'
// alerts
import {
    askForConfirmationAlert,
    errorAlert,
    successAlert,
} from '../../utils/alerts'
// axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'

const NotificationCard = ({ notificationDetails, setDataChanged }) => {
    const userToken = useSelector((state) => state.userToken)
    const { title, body, created_at, topic, sender } = notificationDetails
    const { name, email } = sender
    const deleting = () => {
        enhancedAxios(null, userToken)
            .delete(`/admin/fcm-notifications/${notificationDetails.id}`)
            .then((response) => {
                successAlert('تم حذف الاشعار')
                setDataChanged((prev) => !prev)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }
    const deleteNotification = () => {
        askForConfirmationAlert(
            `هل انت متأكد انك تريد حذف تحت عنوان "${title}"`,
            deleting
        )
    }
    return (
        <Stack
            sx={{
                boxShadow: 1,
                borderRadius: 1,
                p: 2,
                border: '1px solid #f1f1f1',
                minWidth: { xs: '290px', sm: '350px' },
                flex: 1,
            }}
        >
            <Box>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    width="100%"
                >
                    <Typography variant="h6">{title}</Typography>
                    <IconButton color="error" onClick={deleteNotification}>
                        <MdOutlineDelete />
                    </IconButton>
                </Stack>
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap="10px"
                >
                    <Tooltip title="تارخ انشاء الاشعار" arrow>
                        <Typography
                            sx={{
                                fontSize: '12px',
                                color: '#333',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '5px',
                                marginBottom: 1,
                            }}
                        >
                            <MdAccessTime />{' '}
                            {moment(created_at).format('YYYY/MM/DD hh:mm')}
                        </Typography>
                    </Tooltip>
                    <Tooltip title="اسم مُرسِل الاشعار" arrow>
                        <Typography
                            sx={{
                                fontSize: '12px',
                                color: '#333',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '5px',
                                marginBottom: 1,
                            }}
                        >
                            <HiOutlineUser /> {name ?? '-'}
                        </Typography>
                    </Tooltip>
                    <Tooltip title="بريد مُرسِل الاشعار" arrow>
                        <Typography
                            sx={{
                                fontSize: '12px',
                                color: '#333',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '5px',
                                marginBottom: 1,
                            }}
                        >
                            <MdOutlineAlternateEmail /> {email ?? '-'}
                        </Typography>
                    </Tooltip>
                </Box>
                <Typography variant="body1">{body}</Typography>
            </Box>
        </Stack>
    )
}

export default NotificationCard
