import axios from 'axios'
import { baseUrl } from './baseUrl'

export default function EnhancedAxios(contentType, token) {
    if (token != null && token !== '') {
        token = `Bearer ${token}`
    }

    return axios.create({
        baseURL: baseUrl,
        withCredentials: true,
        headers: {
            'Content-Type': contentType ?? 'application/json',
            Authorization: token ?? undefined,
        },
    })
}
