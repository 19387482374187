import { DelivererClaimOrder } from '../../components'
import moment from 'moment'
export const availableOrdersCols = [
    {
        field: 'name',
        headerName: 'الاسم',
        width: '120',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.name ?? '!! لايوجد  !!',
    },
    {
        field: 'phone',
        headerName: 'رقم الهاتف',
        width: '120',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.phone ?? '!! لاتوجد  !!',
    },
    {
        field: 'address',
        headerName: 'العنوان',
        width: '200',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.address ?? '!! لاتوجد  !!',
    },
    {
        field: 'cost',
        headerName: 'قيمة الطلب',
        width: '80',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.cost ?? '!! لاتوجد  !!',
    },
    {
        field: 'created_at',
        headerName: 'تاريخ انشاء الطلب',
        width: '150',
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.created_at
                ? moment(params.row?.created_at).format('YYYY/MM/DD HH:MM')
                : '!! لاتوجد  !!',
    },
    {
        field: 'vendor_name',
        headerName: 'صاحب النشاط',
        width: '100',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.vendor?.name ?? '!! لاتوجد  !!',
    },
    {
        field: 'vendor_city',
        headerName: 'مدينة صاحب النشاط ',
        width: '80',
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.vendor?.city?.name ?? '!! لاتوجد  !!',
    },
    {
        field: 'vendor_address_info',
        headerName: 'عنوان صاحب النشاط ',
        width: '150',
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.vendor?.address_info ?? '!! لاتوجد  !!',
    },
    {
        field: 'claim',
        headerName: 'مطالبة',
        width: '80',
        sortable: false,
        filterable: false,
        renderCell: (params) => <DelivererClaimOrder id={params.row.id} />,
    },
]
