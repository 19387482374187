import { useState, useEffect } from 'react'
// router
import { useParams } from 'react-router'
// redux
import { useSelector } from 'react-redux'
// axios
import EnhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// mui
import {
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material'
// components
import { MainButton } from '../../components'
// alerts
import { errorAlert, successAlert } from '../../utils/alerts'

const AddSocialLinksForm = ({ setDataChanged, possibleLinksOptions }) => {
    const { id } = useParams()
    const userToken = useSelector((state) => state.userToken)
    const userData = useSelector((state) => state.userData)
    const userRole = userData.roles.includes('admin')
        ? 'admin'
        : userData.roles[0]
    const [linkFor, setLinkFor] = useState('app_facebook_url')
    const [linkValue, setLinkValue] = useState('')
    const [links, setLinks] = useState([])
    const handleSelectChange = (e) => {
        setLinkFor(e.target.value)
    }
    // ---------- form_submittion ------------
    const addLink = (e) => {
        e.preventDefault()
        if (linkValue === '') {
            errorAlert('ادخل الرابط بشكل صحيح')
        } else {
            // put new values admin put in object
            const newLinkData = {
                vendor_id: id,
                key: linkFor,
                value: linkValue,
            }
            // convert ne link data to form Data type
            const formData = new FormData()
            for (let i in newLinkData) {
                formData.append(i, newLinkData[i])
            }
            // post request
            EnhancedAxios('multipart/form-data', userToken)
                .post(`/${userRole}/contactable?lang=ar`, formData)
                .then((response) => {
                    setDataChanged((prev) => !prev)
                    successAlert('تم اضافة الرابط بنجاح')
                    setLinkValue('')
                })
                .catch((error) => errorAlert(error))
        }
    }
    useEffect(() => {
        EnhancedAxios(null, userToken)
            .get(`/${userRole}/vendors/${id}`)
            .then((response) => {
                setLinks(response.data.contacts)
            })
    }, [])
    return (
        <Box
            width="100%"
            display="flex"
            justifyContent="flex-start"
            gap="20px"
            flexWrap="wrap"
            component="form"
            onSubmit={addLink}
        >
            <Box minWidth="90px" height="100%">
                <FormControl fullWidth>
                    <InputLabel id="select-label">خاصة</InputLabel>
                    <Select
                        labelId="select-label"
                        id="select"
                        value={linkFor}
                        label="خاصة"
                        onChange={handleSelectChange}
                        sx={{
                            '& .MuiSelect-select': {
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            },
                        }}
                    >
                        {possibleLinksOptions.map((opt) => (
                            <MenuItem
                                key={opt.key}
                                value={opt.key}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {opt.icon}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <TextField
                type="url"
                name="value"
                label="الرابط"
                value={linkValue}
                onChange={(e) => setLinkValue(e.target.value)}
                helperText={
                    linkFor === 'app_whatsapp_url'
                        ? 'https://wa.me/+09XXXXXXXXX'
                        : null
                }
                sx={{ flex: '1', minWidth: '280px' }}
            />
            <MainButton text="أنشاء" />
        </Box>
    )
}

export default AddSocialLinksForm
