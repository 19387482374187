import React from 'react'
import { Avatar } from '@mui/material'

const NavbarLogo = ({ imgSrc }) => {
    return (
        <Avatar
            src={imgSrc}
            alt="eshaar logo"
            sx={{
                width: '130px',
                height: '130px',
                boxShadow: 2,
            }}
        />
    )
}

export default NavbarLogo
