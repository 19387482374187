import { useState, useEffect } from 'react'
// react redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// mui
import { Stack, Box, Pagination, Typography } from '@mui/material'
// components
import {
    PageTitle,
    AddButton,
    LabelCard,
    RingsLoader,
    SearchBox,
    SortBox,
    StatusFilterBox,
} from '../../../components'
// alerts
import { errorAlert } from '../../../utils/alerts'
// --------------------------------------- page ---------------------------------
const ManageCategoriesActivities = () => {
    const [allCategories, setAllCategories] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [itemsPerPage, setItemsPerPage] = useState(16)
    const [currentPage, setCurrentPage] = useState(1)
    const [searchValue, setSearchValue] = useState('')
    const [sortValue, setSortValue] = useState('-created_at')
    const [totalItems, setTotalItems] = useState(5)
    const [categoriesChanged, setCategoriesChanged] = useState(false)
    const [filterValues, setFilterValues] = useState({
        statusFilterValue: 'is_disabled:eq:false|true',
    })
    const userToken = useSelector((state) => state.userToken)
    const parend_id_of_posts_activiies = 'cdecb40b-4cea-4347-a943-919c8ee95ebf'
    // ------------------------------------------- pagination page changed ----------------------------------
    const handlePageChanging = (e, newPage) => {
        setCurrentPage(newPage)
    }
    // -------------------------------------------  get categoris of vendors ----------------------------------
    useEffect(() => {
        setIsLoading(true)
        enhancedAxios(null, userToken)
            .get(
                `/admin/categories?page=${currentPage}&paginate=${itemsPerPage}&filters=parent_id:${parend_id_of_posts_activiies},${filterValues.statusFilterValue}&q=${searchValue}&sorts=${sortValue}`
            )
            .then((response) => {
                setAllCategories(response.data.data)
                setTotalItems(response.data.meta.total)
                setIsLoading(false)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [
        currentPage,
        itemsPerPage,
        sortValue,
        filterValues,
        categoriesChanged,
        searchValue,
    ])
    // ---------------------------------------------- page components --------------------------------------------------
    return (
        <Stack width="100%" height="100%" spacing={8} alignItems="center">
            <PageTitle title="إدارة فئات المنشورات" />
            <Stack spacing={4} width="100%">
                <Box>
                    <AddButton to="/add-category-post" />
                </Box>
                {/* --------------------------------- filter section ------------------------------------------------------- */}
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap="20px"
                    flexWrap="wrap"
                    sx={{
                        '& .MuiBox-root': {
                            flex: '1',
                            minWidth: '240px',
                            // maxWidth:'480px',
                        },
                    }}
                >
                    <SearchBox
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                    <SortBox
                        sortValue={sortValue}
                        setSortValue={setSortValue}
                    />
                    <StatusFilterBox
                        filterValues={filterValues}
                        setFilterValues={setFilterValues}
                    />
                </Box>
                {/* --------------------------------- cards of categoris -----------------------  */}
                {isLoading ? (
                    <RingsLoader />
                ) : allCategories.length === 0 && searchValue === '' ? (
                    <Typography variant="body1" textAlign="center">
                        لا يوجد عناصر لعرضها
                    </Typography>
                ) : allCategories.length === 0 && searchValue !== '' ? (
                    <Typography variant="body1" textAlign="center">
                        {' '}
                        لا يوجد عناصر لنتيجة البحث
                    </Typography>
                ) : (
                    <>
                        <Box
                            display="flex"
                            gap="20px"
                            justifyContent="center"
                            alignItems="flex-start"
                            flexWrap="wrap"
                            sx={{
                                '& .MuiStack-root': {
                                    maxWidth: '25%',
                                },
                            }}
                        >
                            {allCategories.map((item) => {
                                return (
                                    <LabelCard
                                        editPath={`/edit-category-post/${item.id}`}
                                        apiPath={`/admin/categories/${item.id}`}
                                        key={item.id}
                                        id={item.id}
                                        label={item.name}
                                        setCategoriesChanged={
                                            setCategoriesChanged
                                        }
                                    />
                                )
                            })}
                        </Box>
                        <Box
                            width="100%"
                            display="flex"
                            justifyContent="center"
                        >
                            {totalItems > itemsPerPage ? (
                                <Pagination
                                    defaultPage={1}
                                    page={currentPage}
                                    count={Math.ceil(totalItems / itemsPerPage)}
                                    onChange={handlePageChanging}
                                    variant="outlined"
                                    color="primary"
                                />
                            ) : (
                                ''
                            )}
                        </Box>
                    </>
                )}
            </Stack>
        </Stack>
    )
}

export default ManageCategoriesActivities
