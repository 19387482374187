import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// mui
import {
    Stack,
    TextField,
    Autocomplete,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
} from '@mui/material'
// components
import {
    PageTitle,
    MainButton,
    ErrorMessage,
    LinkButton,
} from '../../../components'
// formik
import { useFormik } from 'formik'
// schema
import { pushNotificationSchema } from '../../../schemes/notifications/pushNotificationSchema'
import {
    errorAlert,
    successAlert,
    successToastAlert,
} from '../../../utils/alerts'

const PushNotification = () => {
    const userToken = useSelector((state) => state.userToken)
    const [vendorsLoading, setVendorsLoading] = useState(true)
    const [vendors, setVendors] = useState([])
    const [directedTo, setDirectedTo] = useState(null)
    // ---------------------------- handle autoocmplete change  ----------------------------
    const handleAutocompleteChange = (e, newValue) => {
        setValues((prev) => ({
            ...prev,
            vendor_id: newValue ? newValue.id : '',
        }))
        setDirectedTo(newValue)
    }
    // ---------------------------- submnit pushing notification ----------------------------
    const onSubmit = (values) => {
        if (values.type === 'general') delete values['url']
        const formData = new FormData()
        for (let item in values) {
            if (values[item] !== '' && item !== 'type') {
                formData.append(item, values[item])
            }
        }

        formData.append('topic', 'all')
        enhancedAxios('multipart/form-data', userToken)
            .post(`/admin/fcm-notifications`, formData)
            .then((response) => {
                successToastAlert('سوف يتم ارسال الاشعار لمستخدمين التتطبيق')
                resetForm()
                setDirectedTo(null)
                setSubmitting(false)
            })
            .catch((error) => errorAlert(error))
    }
    // ---------------------------- form configration and validation ----------------------------
    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        resetForm,
        getValue,
    } = useFormik({
        initialValues: {
            title: '',
            body: '',
            vendor_id: '',
            type: 'general',
            url: '',
        },
        validationSchema: pushNotificationSchema,
        onSubmit,
    })
    //----------------------------  get all vendors ----------------------------
    useEffect(() => {
        enhancedAxios(null, userToken)
            .get(`/admin/vendors?sorts=-created_at&page=1&paginate=99999`)
            .then((response) => {
                setVendors(response.data.data)
                setVendorsLoading(false)
            })
    }, [])
    return (
        <Stack width="100%" height="100%" spacing={8} alignItems="center">
            <PageTitle title="ارسال الاشعارات" />
            <Stack width="100%" maxWidth="700px" spacing={1}>
                <LinkButton
                    to="/view-notifications?page=1"
                    text="مشاهدة الاشعارات المرسلة"
                />
                <Stack component="form" onSubmit={handleSubmit} spacing={2}>
                    <TextField
                        label="العنوان"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <TextField
                        label="محتوى الاشعار"
                        name="body"
                        value={values.body}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        multiline
                        minRows={1}
                        maxRows={4}
                    />
                    <FormControl fullWidth>
                        <InputLabel>نوع الاشعار</InputLabel>
                        <Select
                            name="type"
                            value={values.type ?? ''}
                            label="نوع الاشعار"
                            onChange={handleChange}
                        >
                            <MenuItem value="general">عام</MenuItem>
                            <MenuItem value="url">رابط</MenuItem>
                        </Select>
                    </FormControl>
                    {values.type === 'url' ? (
                        <TextField
                            label="الرابط"
                            name="url"
                            type="url"
                            value={values.url}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    ) : (
                        <></>
                    )}
                    <Autocomplete
                        disablePortal
                        getOptionLabel={(option) => option.name ?? ''}
                        options={vendorsLoading ? [] : vendors}
                        sx={{ width: '100%' }}
                        onChange={(e, newValue) =>
                            handleAutocompleteChange(e, newValue)
                        }
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        renderInput={(params) => (
                            <TextField {...params} label="  توجيه الى" />
                        )}
                        value={directedTo ?? null}
                    />
                    <MainButton text="ارسال الاشعار" disabled={isSubmitting} />
                </Stack>
                {errors && (
                    <Stack
                        component="form"
                        width="100%"
                        maxWidth="700px"
                        spacing={2}
                    >
                        {errors.title && touched.title && (
                            <ErrorMessage errorMsg={errors.title} />
                        )}
                        {errors.body && touched.body && (
                            <ErrorMessage errorMsg={errors.body} />
                        )}
                        {errors.type && touched.type && (
                            <ErrorMessage errorMsg={errors.type} />
                        )}
                        {errors.url && touched.url && (
                            <ErrorMessage errorMsg={errors.url} />
                        )}
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}

export default PushNotification
