import { useState, useEffect } from 'react'
// react redux
import { useSelector } from 'react-redux'
// react router
import { useParams } from 'react-router'
// formik
import { useFormik } from 'formik'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// components
import {
    PageTitle,
    MainButton,
    ErrorMessage,
    RingsLoader,
} from '../../../components'
// mui
import { Stack, TextField, FormControlLabel, Checkbox } from '@mui/material'
// alerts
import { successAlert, errorAlert } from '../../../utils/alerts'
import { addOrderToDeliverySchema } from '../../../schemes/orderDelivery/addOrderToDeliverySchema'

const EditOrderToDelivery = () => {
    const { id } = useParams()
    const userToken = useSelector((state) => state.userToken)
    const [isLoading, setIsLoading] = useState(true)
    const [disableEdit, setDisableEdit] = useState(false)
    const onSubmit = (values) => {
        enhancedAxios(null, userToken)
            .put(`vendor/delivery-orders/${id}`, values)
            .then((response) => {
                successAlert('تم تعديل طلب للتوصيل')
                setSubmitting(false)
            })
            .catch((error) => {
                errorAlert(error)
                setSubmitting(false)
            })
    }
    // formik configration
    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        setValues,
        resetForm,
    } = useFormik({
        initialValues: {
            phone: '',
            address: '',
            name: '',
            cost: '',
            details: '',
        },
        validationSchema: addOrderToDeliverySchema,
        onSubmit, //function we write above
    })
    // ------------------------------------------- get current category data -------------------------
    useEffect(() => {
        enhancedAxios(null, userToken)
            .get(`/vendor/delivery-orders/${id}`)
            .then((response) => {
                setValues({
                    ...values,
                    name: response.data.name,
                    phone: response.data.phone,
                    address: response.data.address,
                    cost: response.data.cost,
                    details: response.data.details,
                })
                setIsLoading(false)
                setDisableEdit(
                    response.data.status != 'requested' ? true : false
                )
            })
            .catch((error) => {
                errorAlert(error)
            })
        setIsLoading(false)
    }, [id])
    if (isLoading) return <RingsLoader />
    return (
        <Stack width="100%" height="100%" spacing={8} alignItems="center">
            <PageTitle title="تعديل طلب للتوصيل" />
            <Stack
                component="form"
                spacing={2}
                onSubmit={handleSubmit}
                width="100%"
                maxWidth="600px"
            >
                <TextField
                    name="name"
                    value={values.name}
                    label="الاسم"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                        width: '100%',
                    }}
                    disabled={disableEdit}
                />
                <TextField
                    name="phone"
                    value={values.phone}
                    label="رقم الهاتف"
                    type="tel"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                        width: '100%',
                    }}
                    disabled={disableEdit}
                />
                <TextField
                    name="address"
                    value={values.address}
                    label="عنوان التوصيل"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                        width: '100%',
                    }}
                    disabled={disableEdit}
                />
                <TextField
                    name="details"
                    value={values.details}
                    label="التفاصيل"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                        width: '100%',
                    }}
                    disabled={disableEdit}
                />
                <TextField
                    name="cost"
                    value={values.cost}
                    label="القيمة الواجب استلامها"
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                        width: '100%',
                    }}
                    disabled={disableEdit}
                />

                {/* submit button */}
                <MainButton
                    text="تعديل"
                    disabled={isSubmitting || disableEdit}
                />
                {errors && (
                    <Stack spacing={1}>
                        {errors.name && touched.name && (
                            <ErrorMessage errorMsg={errors.name} />
                        )}
                        {errors.phone && touched.phone && (
                            <ErrorMessage errorMsg={errors.phone} />
                        )}
                        {errors.address && touched.address && (
                            <ErrorMessage errorMsg={errors.address} />
                        )}
                        {errors.cost && touched.cost && (
                            <ErrorMessage errorMsg={errors.cost} />
                        )}
                        {errors.details && touched.details && (
                            <ErrorMessage errorMsg={errors.details} />
                        )}
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}

export default EditOrderToDelivery
