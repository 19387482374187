import * as yup from 'yup'
// (http(s)?:\/\/)
const maxImgSizeMB = 2
const maxImgSizeBytes = maxImgSizeMB * 2 ** 20
const oneDayInMS = 1 * 24 * 60 * 60 * 1000
const urlValueRegex = new RegExp(/(http(s)?)/)

export const addBannerSchema = yup.object().shape({
    img: yup
        .mixed()
        .required('ادخل صورة الاعلان')
        .test(
            'fileSize',
            `حجم الصورة كبير جدا يجب الا يتعدى حجم الصورة ${maxImgSizeMB}MB`,
            (value) => (value != null ? value.size <= maxImgSizeBytes : 't')
        )
        .test('fileType', 'امتداد الصورة يجب ان يكون jpg , png', (value) =>
            value != null
                ? ['image/jpg', 'image/png', 'image/jpeg'].includes(value.type)
                : 't'
        ),
    url: yup
        .string()
        .matches(urlValueRegex, 'كتابة الرابط مسبوق ب https:// او https://')
        .required('ادخل رابط الاعلان')
        .typeError('ادخل رابط الاعلان'),
    is_disabled: yup.boolean(),
})
