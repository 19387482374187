import { useState, useEffect } from 'react'
// react router
import { useParams } from 'react-router'
// redux
import { useSelector } from 'react-redux'
// axios and axios utils
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// components
import {
    RingsLoader,
    PageTitle,
    MainButton,
    ErrorMessage,
} from '../../../components'
// mui
import {
    Stack,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete,
} from '@mui/material'
// formik  and shema
import { useFormik } from 'formik'
import { editUserSchema } from '../../../schemes/users/editUserSchema'
// alerts
import { successAlert, errorAlert } from '../../../utils/alerts'

const EditUserDetails = () => {
    const { id } = useParams()
    const userToken = useSelector((state) => state.userToken)
    const [isLoading, setIsLoading] = useState(true)
    const [allCities, setAllCities] = useState([])
    const [defaultCity, setDefaultCity] = useState('')
    // --------------------- fetch data to display ------------------------------
    useEffect(() => {
        enhancedAxios(null, userToken)
            .get(`/admin/users/${id}`)
            .then((response) => {
                setIsLoading(false)
                setValues({
                    ...values,
                    ...response.data,
                    city: response.data.city,
                })
                setDefaultCity(response.data.city ?? '')
            })
        // get all Citites to make select box with this cities
        enhancedAxios(null, userToken)
            .get('/admin/cities?sorts=-created_at')
            .then((response) => {
                setAllCities(response.data.data)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [id])
    // ------------------------------------ submit form  -----------------------------
    const onSubmit = (values) => {
        const formData = new FormData()
        for (let i in values) {
            formData.append(i, values[i])
        }
        enhancedAxios('multipart/form-data', userToken)
            .put(`/admin/users/${id}`, formData)
            .then((response) => {
                successAlert('تم تعديل المستخدم ')
                setSubmitting(false)
            })
            .catch((error) => {
                errorAlert(error)
                setSubmitting(false)
            })
    }
    // -------------------------------- handleAutoCompleteChange ------------------------------------------
    const handleAutoCompleteChange = (e, newValue) => {
        if (newValue) {
            setValues((prevValues) => ({ ...prevValues, city: newValue }))
        }
    }
    // -------------------------------- formik configration -------------------------------------
    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
    } = useFormik({
        initialValues: {
            name: '',
            phone: '',
            gender: '',
            birthdate: '',
            city: '',
        },
        validationSchema: editUserSchema,
        onSubmit, //function we write above
        handleAutoCompleteChange,
    })
    // ------------------------------- while page is loading ------------------------------------
    if (isLoading) {
        return <RingsLoader />
    }
    //---------------------------------- page -------------------------------------
    return (
        <Stack spacing={4} alignItems="center">
            <PageTitle title="تعديل المستخدم" />
            {/* ------------------------------------ form --------------------------------------- */}
            <Stack
                component="form"
                onSubmit={handleSubmit}
                spacing={2}
                width="100%"
                maxWidth="700px"
            >
                <TextField
                    label="الاسم"
                    type="text"
                    name="name"
                    value={values.name ?? 'لا يوجد اسم لهذا المستخدم'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <TextField
                    label="رقم الهاتف"
                    type="tel"
                    name="phone"
                    value={values.phone ?? 'لا يوجد  رقم هاتف المستخدم'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <FormControl fullWidth>
                    <InputLabel>النوع</InputLabel>
                    <Select
                        name="gender"
                        value={values.gender ?? ''}
                        label="النوع"
                        onChange={handleChange}
                    >
                        <MenuItem value="male">ذكر</MenuItem>
                        <MenuItem value="female">انثى</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label="تاريخ الميلاد"
                    type="date"
                    name="birthdate"
                    value={values.birthdate ?? 'لا يوجد تاريخ ميلاد المستخدم'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <Autocomplete
                    disablePortal
                    options={allCities}
                    getOptionLabel={(option) => option.name ?? ''}
                    defaultValue={defaultCity ?? ''}
                    onChange={handleAutoCompleteChange}
                    sx={{ width: '100%' }}
                    renderInput={(params) => {
                        return <TextField {...params} label="المدينة" />
                    }}
                />
                <MainButton text="تعديل المستخدم" disabled={isSubmitting} />
                {/* ----------------------------- errors section ------------------------------------------ */}
                {errors && (
                    <Stack spacing={1}>
                        {errors.name && touched.name && (
                            <ErrorMessage errorMsg={errors.name} />
                        )}
                        {errors.phone && touched.phone && (
                            <ErrorMessage errorMsg={errors.phone} />
                        )}
                        {errors.birthdate && touched.birthdate && (
                            <ErrorMessage errorMsg={errors.birthdate} />
                        )}
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}

export default EditUserDetails
