import { useState } from 'react'
// mui components
import { Box, Switch } from '@mui/material'
//axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// redux hooks
import { useSelector } from 'react-redux'
// alerts
import { successAlert, errorAlert, successToastAlert } from '../../utils/alerts'

const VerifySwitch = ({ id, is_verified }) => {
    const [value, setValue] = useState(is_verified)
    const userToken = useSelector((state) => state.userToken)
    const userData = useSelector((state) => state.userData)
    const handleChange = (e) => {
        setValue((prev) => !prev)
        let formData = new FormData()
        formData.append('is_verified', e.target.checked)
        enhancedAxios('multipart/form-data', userToken)
            .put(`/admin/users/${id}`, formData)
            .then((response) => {
                const alertMsg =
                    response.data.is_verified === true
                        ? 'تم التحقق'
                        : 'تم التحقق'
                successToastAlert(alertMsg)
                setValue(response.data.is_verified)
            })
            .catch((error) => {
                errorAlert(error)
                setValue((prev) => prev)
            })
    }
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
                pointerEvents: value ? 'none' : 'auto',
                cursor: value ? 'not-allowed' : 'pointer',
            }}
        >
            <Switch
                color="success"
                checked={value}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={userData.id === id}
                size="small"
            />
        </Box>
    )
}
export default VerifySwitch
