import {
    set_user_token,
    remove_token_from_local,
    set_current_user_data,
} from './actionsType.js'

export const setUserToken = (token) => ({
    type: set_user_token,
    payload: token,
})

export const removeTokenFromLocal = () => ({
    type: remove_token_from_local,
})

export const setCurrentUserData = (data) => ({
    type: set_current_user_data,
    payload: data,
})
