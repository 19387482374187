import { EditDetails } from '../../components'
import moment from 'moment'
import { translateToArabic } from '../localize/translateToArabic'
export const ordersToDeliveryWithEditButton = [
    {
        field: 'name',
        headerName: 'الاسم',
        width: '160',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.name ?? '!! لايوجد  !!',
    },
    {
        field: 'phone',
        headerName: 'رقم الهاتف',
        width: '120',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.phone ?? '!! لاتوجد  !!',
    },
    {
        field: 'address',
        headerName: 'العنوان',
        width: '200',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.address ?? '!! لاتوجد  !!',
    },
    {
        field: 'cost',
        headerName: 'قيمة الطلب',
        width: '80',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.cost ?? '!! لاتوجد  !!',
    },
    {
        field: 'created_at',
        headerName: 'تاريخ انشاء الطلب',
        width: '160',
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.created_at
                ? moment(params.row?.created_at).format('YYYY/MM/DD HH:MM')
                : '!! لاتوجد  !!',
    },
    {
        field: 'deliverer_name',
        headerName: 'شركة التوصيل',
        width: '100',
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.deliverer?.name ||
            params.row?.deliverer?.email ||
            '!! لاتوجد  !!',
    },
    {
        field: 'status',
        headerName: 'حالة الطلب',
        width: '100',
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.status
                ? translateToArabic(params?.row?.status)
                : '!! غير معروفة !!',
    },
    {
        field: 'show',
        headerName: 'تعديل',
        width: '100',
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <EditDetails to={`/edit-order-to-delivery/${params.row.id}`} />
        ),
    },
]
