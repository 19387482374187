import * as yup from 'yup'

// login validation
export const loginSchema = yup.object().shape({
    email: yup
        .string()
        .email('من فضلك ادخل الايميل الصحيح')
        .required('من فضلك ادخل البريد الالكترونى!'),
    password: yup
        .string()
        .min(8, ' الرقم السري قصير جدا')
        .required('من فضلك ادخل الرقم السري!'),
})
