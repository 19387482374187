import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// components
import { PageTitle, StatsCard, RingsLoader } from '../../../components'
// mui
import { Stack, Box } from '@mui/material'
// alerts
import { errorAlert } from '../../../utils/alerts'

const VendorHome = () => {
    const [stats, setStats] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const userToken = useSelector((state) => state.userToken)
    const userData = useSelector((state) => state.userData)
    // console.log(userData)
    useEffect(() => {
        setIsLoading(true)
        enhancedAxios(null, userToken)
            .get(`/vendor/statistics`)
            .then((response) => {
                setStats(response.data)
                setIsLoading(false)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [])
    if (isLoading) {
        return <RingsLoader />
    }
    return (
        <Stack width="100%" height="100%" spacing={8} alignItems="center">
            <PageTitle title="الصفحة الرئيسية" />
            <Box
                display="flex"
                gap="20px"
                justifyContent="center"
                alignItems="flex-start"
                flexWrap="wrap"
            >
                <StatsCard text="عدد الزيارات" number={stats.total_views} />
                <StatsCard
                    text="عدد المتابعين"
                    number={stats.total_favorites}
                />
                <StatsCard
                    text="عدد زيارات المنشورات"
                    number={stats.total_post_views}
                />
            </Box>
        </Stack>
    )
}

export default VendorHome
