import { useState, useEffect } from 'react'
// react router
import { useSearchParams } from 'react-router-dom'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// mui
import { Stack, Box, Typography } from '@mui/material'
// sections and components
import {
    RingsLoader,
    PageTitle,
    SearchBox,
    SortBox,
    StatusFilterBox,
    CitiesFilterBox,
} from '../../../components'
import { TableGrid } from '../../../Sections'
// user cols config
import { usersCols } from '../../../utils/tableGridUtils/usersCols'
// alerts
import { errorAlert } from '../../../utils/alerts/index'

const ManageUsers = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    let pageFromParam =
        searchParams.get('page') != null ? searchParams.get('page') : 1
    const [usersRows, setUsersRows] = useState([])
    const [visibleRowsPerPage, setVisibleRowsPerPage] = useState(20)
    const [currentPage, setCurrentPage] = useState(pageFromParam)
    const [isLoading, setIsLoading] = useState(true)
    const [totalRows, setTotalRows] = useState(10)
    const [sortValue, setSortValue] = useState('-created_at')
    const [searchValue, setSearchValue] = useState('')
    const [filterValues, setFilterValues] = useState({
        statusFilterValue: 'is_disabled:eq:false|true',
        citiesFilterValue: '',
    })

    const userToken = useSelector((state) => state.userToken)
    // ----------------- get all  users ----------------------------
    useEffect(() => {
        setIsLoading(true)
        enhancedAxios(null, userToken)
            .get(
                `/admin/users?page=${currentPage}&paginate=${visibleRowsPerPage}&sorts=${sortValue}&filters=roles:ex:3,${filterValues.statusFilterValue},${filterValues.citiesFilterValue}&q=${searchValue}`
            )
            .then((response) => {
                const notDisplayedUsers = response.data.data.filter(
                    (item) => item.email === 'superadmin@sadeem-tech.com'
                ) // sadeem admin accout
                setUsersRows(
                    response.data.data.filter(
                        (item) => item.email !== 'superadmin@sadeem-tech.com'
                    )
                )
                setTotalRows(
                    response.data.meta.total - notDisplayedUsers.length
                )
                setIsLoading(false)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [
        userToken,
        searchValue,
        sortValue,
        filterValues,
        currentPage,
        visibleRowsPerPage,
    ])
    // when params change change page value
    useEffect(() => {
        setCurrentPage(Number(searchParams.get('page')))
    }, [Number(searchParams.get('page'))])
    return (
        <Stack spacing={4} alignItems="center">
            <PageTitle title="ادارة المستخدمين" />
            <Box
                width="100%"
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
                sx={{
                    '& .MuiBox-root': {
                        flex: '1',
                        minWidth: '240px',
                    },
                }}
            >
                <SearchBox
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
                <SortBox sortValue={sortValue} setSortValue={setSortValue} />
                <StatusFilterBox
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                />
                <CitiesFilterBox
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                />
            </Box>
            {isLoading ? (
                <RingsLoader />
            ) : usersRows.length === 0 && searchValue === '' ? (
                <Typography variant="body1">لا يوجد عناصر لعرضها</Typography>
            ) : usersRows.length === 0 && searchValue !== '' ? (
                <Typography variant="body1">
                    {' '}
                    لا يوجد عناصر لنتيجة البحث{' '}
                </Typography>
            ) : (
                <TableGrid
                    rows={usersRows}
                    cols={usersCols}
                    currentPage={parseInt(currentPage)}
                    setCurrentPage={setCurrentPage}
                    visibleRowsPerPage={visibleRowsPerPage}
                    totalRows={totalRows}
                />
            )}
        </Stack>
    )
}

export default ManageUsers
