import { EditDetails } from '../../components'
// user table columns
export const customerRequestsCols = [
    {
        field: 'username',
        headerName: 'اسم المتسخدم',
        width: '160',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.user.name ?? '!! لايوجد اسم !!',
    },
    {
        field: 'details',
        headerName: 'تفاصيل الطلب',
        width: '250',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.details ?? '!! لاتوجد تفاصيل !!',
    },
    {
        field: 'vendorCategory',
        headerName: 'فئة النشاط',
        width: '120',
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.vendor_category.name ?? '!! لاتوجد فئة !!',
    },
    {
        field: 'status',
        headerName: 'حالة الطلب',
        width: '120',
        sortable: false,
        filterable: false,
        valueGetter: (params) => params.row?.status ?? '!! غير معروفة !!',
    },
    {
        field: 'vendorName',
        headerName: 'تم توجيه الطلب الى',
        width: '200',
        sortable: false,
        filterable: false,
        valueGetter: (params) =>
            params.row?.vendor.name ?? '!! لم يتم توجيه الطلب !!',
    },
    {
        field: 'show',
        headerName: 'مشاهدة',
        width: '180',
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <EditDetails to={`/edit-customer-request/${params.row.id}`} />
        ),
    },
]
