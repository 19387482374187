import moment from 'moment'
import enhancedAxios from './axiosUtils/enhancedAxios'

export const addDaysToDate = (numOfDays, baseDate) => {
    let finalDate = new Date()
    if (baseDate) {
        finalDate = new Date(baseDate)
    } else {
        enhancedAxios(null, null)
            .get('/meta')
            .then((response) => {
                finalDate = new Date(response.data.server_time.go_now)
            })
            .catch((error) => {
                finalDate = new Date()
            })
    }
    finalDate.setDate(finalDate.getDate() + parseInt(numOfDays))
    // return finalDate.toISOString()
    return moment(finalDate).format('YYYY-MM-DD')
}

addDaysToDate(2)
