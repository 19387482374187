import * as yup from 'yup'

// edit vendor validation
export const pushNotificationSchema = yup.object().shape({
    title: yup.string().required('ادخل عنوان الى الاشعار'),
    body: yup
        .string()
        .min(10, 'محتوى الاشعار قصير جدا')
        .max(255, 'محتوى الاشعار كبير جدا')
        .required('ادخل وصف الى الاشعار'),
    type: yup.string().required('enter notification type'),
    url: yup.string().when('type', (type, schema) => {
        if (type[0] === 'url')
            return schema
                .url('ادخل الرابط بشكل صحيح')
                .required('ادخل الرابط خاص الاشعار')
    }),
})
