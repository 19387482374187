import { useState, useEffect } from 'react'
// redux
import { useSelector } from 'react-redux'
// axios
import enhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// mui
import { Box, Autocomplete, TextField } from '@mui/material'
// alerts
import { errorAlert } from '../../utils/alerts'

const SelectCity = ({ vendorCity, setVendorCity, setValues }) => {
    const [allCities, setAllCities] = useState(null)
    const userToken = useSelector((state) => state.userToken)
    useEffect(() => {
        // get all Citites to make select box with this cities
        enhancedAxios(null, userToken)
            .get(
                '/cities?sorts=-created_at&filters=is_disabled:eq:false&page=1&paginate=10000'
            )
            .then((response) => {
                setAllCities(response.data.data)
            })
            .catch((error) => {
                errorAlert(error)
            })
    }, [])
    const handleAutoCompleteChange = (e, newValue) => {
        setVendorCity(newValue)
        setValues((prevValues) => ({
            ...prevValues,
            city_id: newValue.id ?? null,
        }))
    }
    return (
        <Box>
            {allCities !== null && (
                <Autocomplete
                    disablePortal
                    options={allCities}
                    name="city_id"
                    isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                    }
                    getOptionLabel={(option) => option.name ?? ''}
                    onChange={handleAutoCompleteChange}
                    defaultValue={vendorCity ?? null}
                    value={vendorCity ?? null}
                    filterSelectedOptions
                    sx={{
                        width: '100%',
                    }}
                    renderInput={(params) => {
                        return <TextField {...params} label="المدينة" />
                    }}
                />
            )}
        </Box>
    )
}

export default SelectCity
