import * as yup from 'yup'
export const editOrderDelivererSchema = yup.object().shape({
    name: yup.string().required('يجب ان يكون هناك اسم شركة التوصيل'),
    email: yup
        .string()
        .email('ادخل الايميل الصحيح')
        .required('ادخل البريد الالكترونى خاصة شركة التوصيل'),
    password: yup.string().min(8, ' الرقم السري قصير جدا'),
    password_confirmation: yup
        .string()
        .oneOf([yup.ref('password')], 'تأكد من تطابق الرقم السري'),
})
