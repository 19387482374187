import { useState } from 'react'
// router
import { useParams } from 'react-router'
// redux
import { useSelector } from 'react-redux'
// components
import {
    PageTitle,
    MainButton,
    ErrorMessage,
    SelectHotDealPeriod,
    SubExpiredGuard,
} from '../../../components'
import { PreviewCustomButton } from '../../../Sections'
// mui
import {
    Stack,
    TextField,
    Box,
    FormControlLabel,
    Checkbox,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material'
// axios
import enhancedAxios from '../../../utils/axiosUtils/enhancedAxios'
// formik
import { useFormik } from 'formik'
// shcema
import { addDealSchema } from '../../../schemes/deals/addDealSchema'
// alerts
import { errorAlert, successAlert } from '../../../utils/alerts/index'
// utils
import { imageCompressor } from '../../../utils/comporessImage/imageCompressor'
// date
import { addDaysToDate } from '../../../utils/addDaysToDate'
const VendorAddHotDeal = () => {
    const userToken = useSelector((state) => state.userToken)
    const [file, setFile] = useState(null)
    const [resetPreview, setresetPreview] = useState()
    // ------------------------------   handle file changed toget value and for preview --------------------------
    const handleFileChange = async (e) => {
        let x = e.target.files[0]
        const final = await imageCompressor(x)
        setFile(final)
        setValues((prevValues) => ({ ...prevValues, img: final }))
    }
    // ------------------------------   handle is global change --------------------------
    const handleIsGlobalChange = (e, value) => {
        setValues((prev) => ({ ...prev, is_general: Boolean(value) }))
    }
    // ----------------------------------------------- submit form -----------------------------------------------
    const onSubmit = (values) => {
        values = { ...values, expires_at: addDaysToDate(values.expires_at) }
        if (values.is_general) {
            delete values['price']
            delete values['final_price']
        } else {
            delete values['general_discount_price']
        }
        const formData = new FormData()
        for (let item in values) {
            formData.append(item, values[item])
        }
        enhancedAxios('multipart/form-data', userToken)
            .post(`vendor/hot-offers`, formData)
            .then((response) => {
                successAlert('تم اضافة العرض')
                resetForm()
                setresetPreview(true)
                setSubmitting(false)
            })
            .catch((error) => {
                errorAlert(error)
                setSubmitting(false)
            })
        // setSubmitting(false)
    }

    // -----------------------------------------------  formik configraion ----------------------------------------------------------
    const {
        values,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setSubmitting,
        resetForm,
    } = useFormik({
        initialValues: {
            title: '',
            price: '',
            final_price: '',
            general_discount_price: '',
            expires_at: '1',
            description: '',
            img: null,
            is_disabled: false,
            is_general: false,
        },
        validationSchema: addDealSchema,
        onSubmit,
        handleFileChange,
        handleIsGlobalChange,
    })

    return (
        <SubExpiredGuard>
            <Stack spacing={4} alignItems="center" width="100%">
                <PageTitle title="اضافة عرض ساخن" />
                <Stack
                    component="form"
                    onSubmit={handleSubmit}
                    width="100%"
                    maxWidth="700px"
                    spacing={2}
                    alignItems="flex-start"
                >
                    <ToggleButtonGroup
                        color="primary"
                        value={values.is_general}
                        exclusive
                        onChange={handleIsGlobalChange}
                        aria-label="Platform"
                    >
                        <ToggleButton value={true}>عرض عام</ToggleButton>
                        <ToggleButton value={false}>عرض خاص</ToggleButton>
                    </ToggleButtonGroup>
                    <Box width="100%">
                        <PreviewCustomButton
                            file={file}
                            handleFileChange={handleFileChange}
                            resetPreview={resetPreview}
                            IPVariant="rounded"
                            IPObjectFit="contain"
                            IPWidth="100%"
                        />
                    </Box>
                    <TextField
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="العنوان"
                        multiline
                        minRows={1}
                        maxRows={5}
                        sx={{
                            width: '100%',
                        }}
                    />
                    <TextField
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="التفاصيل"
                        multiline
                        minRows={2}
                        maxRows={5}
                        sx={{
                            width: '100%',
                        }}
                    />
                    {values.is_general ? (
                        <TextField
                            name="general_discount_price"
                            value={values.general_discount_price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="سعر التخفيض العام"
                            type="number"
                            sx={{
                                width: '100%',
                            }}
                        />
                    ) : (
                        <>
                            <TextField
                                name="price"
                                value={values.price}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label="السعر"
                                type="number"
                                sx={{
                                    width: '100%',
                                }}
                            />
                            <TextField
                                name="final_price"
                                value={values.final_price}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label="السعر النهائي"
                                type="number"
                                sx={{
                                    width: '100%',
                                }}
                            />
                        </>
                    )}
                    <SelectHotDealPeriod setValues={setValues} />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={values.is_disabled}
                                onChange={handleChange}
                                name="is_disabled"
                            />
                        }
                        label="اخفاء "
                    />
                    <Box width="100%" sx={{ '& button': { width: '100%' } }}>
                        <MainButton
                            text="انشاء العرض"
                            disabled={isSubmitting}
                        />
                        {/* errors section  */}
                        {errors && (
                            <Stack spacing={1}>
                                {errors.img && touched.img && (
                                    <ErrorMessage errorMsg={errors.img} />
                                )}
                                {errors.title && touched.title && (
                                    <ErrorMessage errorMsg={errors.title} />
                                )}
                                {errors.price && touched.price && (
                                    <ErrorMessage errorMsg={errors.price} />
                                )}
                                {errors.final_price && touched.final_price && (
                                    <ErrorMessage
                                        errorMsg={errors.final_price}
                                    />
                                )}
                                {errors.general_discount_price &&
                                    touched.general_discount_price && (
                                        <ErrorMessage
                                            errorMsg={
                                                errors.general_discount_price
                                            }
                                        />
                                    )}
                                {errors.expires_at && touched.expires_at && (
                                    <ErrorMessage
                                        errorMsg={errors.expires_at}
                                    />
                                )}
                                {errors.description && touched.description && (
                                    <ErrorMessage
                                        errorMsg={errors.description}
                                    />
                                )}
                            </Stack>
                        )}
                    </Box>
                </Stack>
            </Stack>
        </SubExpiredGuard>
    )
}

export default VendorAddHotDeal
