import React from 'react'
import { Typography } from '@mui/material'
const PageTitle = ({ title }) => {
    return (
        <Typography variant="h3" fontWeight="500" color="textColor.main">
            {title}
        </Typography>
    )
}

export default PageTitle
