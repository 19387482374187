import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import RingsLoader from '../loaders/RingsLoader'
import { useSelector } from 'react-redux'
import EnhancedAxios from '../../utils/axiosUtils/enhancedAxios'
const dateFromPast = (data) => {
    const dateToCheck = new Date(data)
    const currentDate = new Date()
    return dateToCheck < currentDate ? true : false
}
const SubExpiredGuard = ({ children }) => {
    const userToken = useSelector((state) => state.userToken)
    const [isExpiredSub, setIsExpiredSub] = useState(true)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setLoading(true)
        EnhancedAxios(null, userToken)
            .get(`/vendor/`)
            .then((response) => {
                setLoading(false)
                setIsExpiredSub(response.data.is_expired_sub)
            })
    }, [])
    if (loading) return <RingsLoader />
    return isExpiredSub ? (
        <Typography fontWeight="bold" textAlign="center">
            لا يمكنك اضافة او تعديل منشورات او عروض ساخنة بسبب انتهاء اشتراكك
        </Typography>
    ) : (
        <>{children}</>
    )
}

export default SubExpiredGuard
