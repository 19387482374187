import { useState, useEffect, useRef } from 'react'
// router
import { useParams } from 'react-router'
// redux
import { useSelector } from 'react-redux'
// axios
import EnhancedAxios from '../../utils/axiosUtils/enhancedAxios'
// mui
import { Stack, Typography, Box, Avatar, Button } from '@mui/material'
// components
import {
    SideTitle,
    UploadImagePreview,
    MainButton,
    RingsLoader,
} from '../../components'
// icons
import { FiUploadCloud } from 'react-icons/fi'
import { IoCloseOutline } from 'react-icons/io5'
// alerts
import {
    errorAlert,
    successAlert,
    askForConfirmationAlert,
} from '../../utils/alerts'
const PostGallery = ({ postId, apiUrl }) => {
    // token and role of current user
    const userToken = useSelector((state) => state.userToken)
    const userData = useSelector((state) => state.userData)
    const userRole = userData.roles.includes('admin')
        ? 'admin'
        : userData.roles.includes('vendor')
        ? 'vendor'
        : userData.roles[0]
    // image states to handle ui
    const fileInputRef = useRef()
    const [images, setImages] = useState([])
    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(true)
    const [dataChanged, setDataChanged] = useState(false)
    const addBanner = () => {
        fileInputRef.current.click()
    }
    const handleFileChange = (e) => {
        setFile(e.target.files[0])
    }
    // submit adding image to gallery
    const handleSubmit = (e) => {
        e.preventDefault()
        if (file === null) {
            errorAlert('لا يوجد صورة.')
        } else {
            const formData = new FormData()
            formData.append('post_id', postId)
            formData.append('img', file)
            EnhancedAxios('multipart/form-data', userToken)
                .post(`/${userRole}/post-images`, formData)
                .then((response) => {
                    successAlert('تم اضافة الصورة')
                    setDataChanged((prev) => !prev)
                    setFile(null)
                })
                .catch((error) => errorAlert(error))
        }
    }
    // get images
    useEffect(() => {
        setLoading(true)
        EnhancedAxios(null, userToken)
            .get(`/${userRole}/posts/${postId}`)
            .then((response) => {
                setImages(response.data.images)
                setLoading(false)
            })
    }, [dataChanged])
    const deletingProcess = (imgId) => {
        EnhancedAxios(null, userToken)
            .delete(`/${userRole}/post-images/${imgId}`)
            .then((response) => {
                successAlert('تم حذ الصورة بنجاح')
                setDataChanged((prev) => !prev)
            })
            .catch((error) => errorAlert(error))
    }
    const removeImage = (imgId) => {
        askForConfirmationAlert('هل انت متاكد انك تريد حذف الصورة', () =>
            deletingProcess(imgId)
        )
    }
    return (
        <Stack spacing={2} width="100%">
            <Box
                component="form"
                onSubmit={handleSubmit}
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
            >
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap="20px"
                >
                    {file && (
                        <UploadImagePreview
                            file={file}
                            IPWidth="100px"
                            IPHeight="60px"
                            IPVariant="rounded"
                        />
                    )}
                    <input
                        type="file"
                        ref={fileInputRef}
                        hidden
                        onChange={handleFileChange}
                        accept="image/png, image/gif, image/jpeg"
                    />
                    <Button
                        onClick={addBanner}
                        sx={{
                            width: '60px',
                            height: '60px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid',
                            borderColor: 'secondary.main',
                            fontSize: '24px',
                            color: 'secondary.main',
                        }}
                    >
                        <FiUploadCloud />
                    </Button>
                </Box>
                <MainButton text="تأكيد" />
            </Box>
            <Box
                display="flex"
                justifycontent="center"
                alignItems="center"
                gap="20px"
                flexWrap="wrap"
            >
                {loading ? (
                    <Box height="90px">
                        <RingsLoader />
                    </Box>
                ) : images.length === 0 ? (
                    <Typography variant="subtitle2">
                        لم يتم اضافة صور
                    </Typography>
                ) : (
                    images.map((img) => (
                        <Box sx={{ position: 'relative' }} id={img.id}>
                            <Avatar
                                src={img.img}
                                variant="rounded"
                                sx={{ width: '120px', height: '70px' }}
                                alt="market banner"
                            />
                            <Button
                                size="small"
                                sx={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    backgroundColor: '#f6f6f670',
                                    width: '20px',
                                    height: '20px',
                                    minWidth: '20px',
                                    color: 'basicColor.black',
                                }}
                                onClick={() => removeImage(img.id)}
                            >
                                <IoCloseOutline />
                            </Button>
                        </Box>
                    ))
                )}
            </Box>
        </Stack>
    )
}

export default PostGallery
