import React from 'react'
import ReactDOM from 'react-dom/client'
// react router
import { BrowserRouter } from 'react-router-dom'
// main styles
import './index.css'
// components
import App from './App'
import { CacheThemeProvider } from './components'
// redux
import { Provider } from 'react-redux'
import store from './redux/store.js'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <CacheThemeProvider>
                <Provider store={store}>
                    <App />
                </Provider>
            </CacheThemeProvider>
        </BrowserRouter>
    </React.StrictMode>
)
