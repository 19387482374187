import * as yup from 'yup'
const maxImgSizeMB = 20
const maxImgSizeBytes = maxImgSizeMB * 2 ** 20
const urlValueRegex = new RegExp(/(http(s)?)/)

// edit vendor validation
export const editPostSchema = yup.object().shape({
    category_id: yup.string().required('ادخل الفئة  التى ينتمى ليها النشاط'),
    description: yup
        .string()
        .min(5, 'هذا الوصف قصير جدا')
        .max(255, 'هذا الوصف طويل جدا')
        .required('ادخل وصف الخدمة'),
    img: yup
        .mixed()
        .nullable()
        .test(
            'fileSize',
            `حجم الصورة كبير جدا يجب الا يتعدى حجد الصورة ${maxImgSizeMB}MB`,
            (value) => imageEditSizeValidate(value)
        )
        .test('fileType', 'امتداد الصورة يجب ان يكون jpg , png', (value) =>
            imageEditTypeValidate(value)
        ),
    is_disabled: yup.boolean(),
    price: yup
        .number()
        .typeError('السعر يجب ان لا يحتوي علي حروف')
        .min(0, 'السعر يجب ان يكون اكبر من او يساوي الصفر')
        .required('الحقل مطلوب'),
    url_type: yup
        .string()
        .matches(urlValueRegex, 'كتابة الرابط مسبوق ب https:// او https://'),
    url_value: yup.string().min(2, 'ادخل الرابط الصحيح'),
})

// function to validate image if it get as url or not uploaded

const imageEditSizeValidate = (value) => {
    if (value != null && typeof value != 'string') {
        return value.size <= maxImgSizeBytes
    }
    return true
}

const imageEditTypeValidate = (value) => {
    if (value != null && typeof value != 'string') {
        return ['image/jpg', 'image/png', 'image/jpeg'].includes(value.type)
    }
    return true
}
